import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {
  $applyNodeReplacement,
  DecoratorNode,
  DOMConversionMap,
  type DOMConversionOutput,
  DOMExportOutput,
  type LexicalNode,
  type NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import {ElementType, ReactNode} from 'react';

export const SHOW_PROFILE = false;

export type SerializedSpinnerNode = Spread<
  {
    name: string;
  },
  SerializedLexicalNode
>;

function convertElement(domNode: HTMLElement): DOMConversionOutput | null {
  const name = domNode.getAttribute('data-lexical-beautiful-spinner-name');
  if (name != null) {
    const node = $createSpinnerNode(name);
    return {node};
  }
  return null;
}

/**
 * This node is used to represent a spinner used in the SpinnerPlugin.
 */
export class SpinnerNode extends DecoratorNode<ReactNode> {
  __name: string;

  static getType(): string {
    return 'Spinner';
  }

  static clone(node: SpinnerNode): SpinnerNode {
    return new SpinnerNode(node.__name, node.__key);
  }

  constructor(name: string, key?: NodeKey) {
    super(key);
    this.__name = name;
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): boolean {
    return false;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-beautiful-spinner', 'true');
    element.setAttribute('data-lexical-beautiful-spinner-name', this.__name);
    element.textContent = this.getTextContent();
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-beautiful-spinner')) {
          return null;
        }
        console.log('importing spinner');
        return {
          conversion: convertElement,
          priority: 1,
        };
      },
    };
  }

  static importJSON(serializedNode: SerializedSpinnerNode): SpinnerNode {
    return $createSpinnerNode(serializedNode.name);
  }

  exportJSON(): SerializedSpinnerNode {
    return {
      name: this.__name,
      type: 'Spinner',
      version: 1,
    };
  }

  getTextContent(): string {
    const self = this.getLatest();
    return self.__name;
  }

  getName(): string {
    const self = this.getLatest();
    return self.__name;
  }

  setName(name: string) {
    const self = this.getWritable();
    self.__name = name;
  }

  component(): ElementType<any> | null {
    return null;
  }

  decorate() {
    return (
      <span data-lexical-beautiful-spinner-name={this.__name}>
        <Spinner />
      </span>
    );
  }
}

export function $createSpinnerNode(name: string): SpinnerNode {
  const spinnerNode = new SpinnerNode(name);
  return $applyNodeReplacement(spinnerNode);
}

export function $isSpinnerNode(node: LexicalNode | null | undefined): node is SpinnerNode {
  return node instanceof SpinnerNode;
}
