import {Snackbar} from '@dropbox/dig-components/snackbar';
import {MainContent} from 'components/grammarly/MainContent';
import {KeyResultsComponent, OKRComponent} from 'components/grammarly/OKRComponent';
import {
  KeyResultsCompletedComponent,
  OKRGradingComponent,
} from 'components/grammarly/OKRGradingComponent';
import {RightSideBar} from 'components/grammarly/RightSideBar';
import {INITIAL_OKR_KEY_RESULT_STATE, INITIAL_OKR_OBJECTIVE_STATE} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {formatMarkdownForOKR} from 'helpers/okr/formatter';
import {generate, generateKR} from 'helpers/okr/generator';
import {transformKeyResultToOKREntry, transformObjectiveToOKREntry} from 'helpers/okr/parser';
import {FOCUSED_OKR, OKREntry, OKREntryStatus} from 'helpers/okr/types';
import {useState} from 'react';
import {getSprig} from 'survey/sprig';

const Grammarly = () => {
  const [objectiveInput, setObjectiveInput] = useState('');

  const [objectiveEntry, setObjectiveEntry] = useState<OKREntry>(INITIAL_OKR_OBJECTIVE_STATE);

  const [currentOKRGradingEntry, setCurrentOKRGradingEntry] = useState<OKREntry>(
    INITIAL_OKR_OBJECTIVE_STATE
  );

  const [keyResultEntries, setKeyResultEntries] = useState<
    {id: number; entry: OKREntry; value: string}[]
  >([{id: 1, entry: INITIAL_OKR_KEY_RESULT_STATE, value: ''}]);

  const [currentGradingFocus, setCurrentGradingFocus] = useState<FOCUSED_OKR>(
    FOCUSED_OKR.OBJECTIVE
  );

  const [copied, setCopied] = useState(false);

  const [completed, setCompleted] = useState(false);

  const sprig = getSprig('n_KU5RfA_gSt');

  const handleAddKeyResultInput = () => {
    const newKeyResultEntry = {
      id: keyResultEntries.length + 1,
      entry: INITIAL_OKR_KEY_RESULT_STATE,
      value: '',
    };
    setKeyResultEntries((prev) => [...prev, newKeyResultEntry]);
  };

  const handleKeyResultInputChange = (id: number, value: string) => {
    setCurrentOKRGradingEntry((prev) => {
      return {
        ...prev,
        status: OKREntryStatus.STARTED,
      };
    });

    const updatedKeyResultEntries = keyResultEntries.map((entry) => {
      return entry.id === id
        ? {id, value, entry: {...entry.entry, status: OKREntryStatus.STARTED}}
        : entry;
    });
    setKeyResultEntries(updatedKeyResultEntries);
    setCurrentGradingFocus(FOCUSED_OKR.KEY_RESULT);
    setCurrentOKRGradingEntry(() => {
      return {...keyResultEntries[id - 1].entry};
    });
  };

  const handleCheckKeyResult = () => {
    const id = keyResultEntries[keyResultEntries.length - 1].id;
    setCurrentOKRGradingEntry(() => {
      return {
        ...currentOKRGradingEntry,
        status: OKREntryStatus.IN_PROGRESS,
      };
    });
    const updatedKeyResultEntries = keyResultEntries.map((entry) => {
      return entry.id === id
        ? {
            id,
            entry: {...entry.entry, status: OKREntryStatus.IN_PROGRESS},
            value: entry.value,
          }
        : entry;
    });
    setKeyResultEntries(updatedKeyResultEntries);
    const value = keyResultEntries.find((entry) => entry.id === id)?.value;

    if (!value) {
      return;
    }

    generateKR(value).then((result) => {
      const parsedResult = transformKeyResultToOKREntry(result);
      setCurrentOKRGradingEntry(() => {
        return {...parsedResult};
      });
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updatedKeyResultEntries = keyResultEntries.map((entry) => {
        return entry.id === id ? {entry: {...parsedResult}, id, value} : entry;
      });
      setKeyResultEntries(updatedKeyResultEntries);
      if (parsedResult.status === OKREntryStatus.SUCCESS) {
        handleAddKeyResultInput();
      }
    });
  };

  const handleRemoveInput = (id: number) => {
    const updatedKeyResultEntries = keyResultEntries.filter((entry) => entry.id !== id);
    setKeyResultEntries(updatedKeyResultEntries);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (keyResultEntries.length <= 1) {
      return;
    }
    if (event.key === 'Backspace' && keyResultEntries[index - 1].value === '') {
      handleRemoveInput(index);
    }
  };

  const handleObjectiveInputFocus = () => {
    setCurrentGradingFocus(FOCUSED_OKR.OBJECTIVE);
    setCurrentOKRGradingEntry(() => {
      return {...objectiveEntry};
    });
  };

  const handleFocus = (index: number) => {
    if (keyResultEntries[index].value === '') {
      return;
    }
    setCurrentGradingFocus(FOCUSED_OKR.KEY_RESULT);
    setCurrentOKRGradingEntry(() => {
      return {...keyResultEntries[index].entry};
    });
  };

  const handleObjectiveInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setObjectiveInput(event.target.value);
    setCurrentGradingFocus(FOCUSED_OKR.OBJECTIVE);
    setObjectiveEntry((prev) => {
      return {
        ...prev,
        status: OKREntryStatus.STARTED,
      };
    });
  };

  const handleOnObjectiveClick = () => {
    setObjectiveEntry({
      ...objectiveEntry,
      status: OKREntryStatus.IN_PROGRESS,
    });
    setCurrentOKRGradingEntry({
      ...currentOKRGradingEntry,
      status: OKREntryStatus.IN_PROGRESS,
    });
    generate(objectiveInput)
      .then((result) => {
        const parsedResult = transformObjectiveToOKREntry(result);
        setObjectiveEntry(parsedResult);
        setCurrentOKRGradingEntry(parsedResult);
      })
      .catch((error) => {
        reportAndLogError(error);
        setObjectiveEntry({
          ...objectiveEntry,
          status: OKREntryStatus.FAILED,
        });
        setCurrentOKRGradingEntry({
          ...currentOKRGradingEntry,
          status: OKREntryStatus.FAILED,
        });
      });
  };

  const handleFinishClick = () => {
    const failed = keyResultEntries.some((entry) => entry.entry.status === OKREntryStatus.FAILED);
    if (failed) {
      setCurrentGradingFocus(FOCUSED_OKR.ALL_FAILED);
    } else {
      setCurrentGradingFocus(FOCUSED_OKR.ALL_SUCCESS);
    }
    setCurrentOKRGradingEntry(() => {
      return {...keyResultEntries[keyResultEntries.length - 1].entry};
    });
    if (keyResultEntries[keyResultEntries.length - 1].value === '') {
      handleRemoveInput(keyResultEntries[keyResultEntries.length - 1].id);
    }
    setCompleted(true);

    sprig.track('web_okr_completed');
  };

  // handle copy click
  const handleCopyClick = () => {
    const keyResultsText = keyResultEntries.map((entry) => entry.value);
    const text = formatMarkdownForOKR(objectiveInput, keyResultsText);
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const isKeyResultEntriesCompleted = () => {
    return (
      keyResultEntries[keyResultEntries.length - 1].entry.status !== OKREntryStatus.FAILED &&
      keyResultEntries.length > 1
    );
  };

  return (
    <>
      <MainContent>
        <OKRComponent
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleObjectiveInputChange(e)}
          inputText={objectiveInput}
          handleFocus={handleObjectiveInputFocus}
          handleCheckObjective={handleOnObjectiveClick}
          objectiveEntry={objectiveEntry}
        />
        {objectiveEntry.status === OKREntryStatus.SUCCESS && (
          <KeyResultsComponent
            okrEntries={keyResultEntries}
            handleAddInput={handleAddKeyResultInput}
            handleInputChange={handleKeyResultInputChange}
            handleRemoveInput={handleRemoveInput}
            handleKeyDown={(event, id) => handleKeyDown(event, id)}
            handleFocus={handleFocus}
            handleCheckKeyResult={handleCheckKeyResult}
            completed={completed}
          />
        )}
      </MainContent>
      <RightSideBar>
        <OKRGradingComponent
          isTyping={objectiveInput.length !== 0}
          currentOKREntry={currentOKRGradingEntry}
          focusedOKR={currentGradingFocus}
          handleCopyClick={handleCopyClick}
        />
        {!completed && (
          <KeyResultsCompletedComponent
            isKeyResultEntriesCompleted={isKeyResultEntriesCompleted()}
            handleFinishClick={handleFinishClick}
          />
        )}
      </RightSideBar>

      <Snackbar
        open={copied}
        style={{marginRight: 'auto', marginLeft: 'auto', marginBottom: '20px'}}
        preferComposition
      >
        <Snackbar.Content>
          <Snackbar.Message>Copied!</Snackbar.Message>
        </Snackbar.Content>
      </Snackbar>
    </>
  );
};

export {Grammarly};
