import {Team} from 'components/teams/hooks';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useLoaderData} from 'react-router-dom';
import {TeamsView} from 'views/teams/TeamsView';

import {NotFound} from './NotFound';

const Teams = () => {
  const team = useLoaderData() as Team;
  useDocumentTitle(t('teams'));

  if (!team) {
    return <NotFound />;
  }

  return <TeamsView team={team} />;
};

export {Teams};
