import {Table} from '@dropbox/dig-components/dist/table';
import {Box} from '@dropbox/dig-foundations';
import {GoalStatusButton, StatusButtonProps} from 'components/shared/GoalStatusButton';

export const StatusCell = (props: StatusButtonProps & {onClick?: () => void}) => (
  <Table.Cell
    verticalAlign="top"
    onClick={props.onClick}
    style={props.onClick ? {cursor: 'pointer'} : undefined}
  >
    <Box marginTop="12">
      <GoalStatusButton {...props} isDisabled={!props.canEdit} />
    </Box>
  </Table.Cell>
);
