import {NewsPostUpdate} from 'client';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useParams} from 'react-router-dom';

import {
  useDeleteNewsPost,
  useNewsUpdate,
  usePostSuspense,
  useThumbnailCreate,
  useThumbnailDelete,
} from './hooks';
import {NewsUpdate} from './NewsUpdate';

export const NewsUpdateView = () => {
  useDocumentTitle(t('edit_post'));

  const params = useParams();
  const id = parseInt(params.identifier ?? '', 10);

  const newspost = usePostSuspense(id);

  const {updateNewsPost, isPending} = useNewsUpdate();
  const {deleteNewsPost} = useDeleteNewsPost();
  const {uploadThumbnail} = useThumbnailCreate();
  const {deleteThumbnail} = useThumbnailDelete();

  return (
    <NewsUpdate
      newspost={newspost}
      onSubmit={({data}) =>
        updateNewsPost({newspostId: id, data: data as unknown as NewsPostUpdate})
      }
      onDelete={() => deleteNewsPost({newspostId: id})}
      uploadThumbnail={(postId: number, file: File) => uploadThumbnail({id: postId, file: file})}
      deleteThumbnail={() => deleteThumbnail({id: id})}
      isLoading={isPending}
    />
  );
};
