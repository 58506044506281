import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {PlugMini, PuzzlePiecesMini} from '@dropbox/dig-illustrations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {growthbookCacheAtom} from 'atoms/layout';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {DEFAULT_TIMEFRAME, ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {useAtomValue} from 'jotai';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import styles from './GoalsOnboardingView.module.css';
import {dummyGoalId, dummyKRId} from './utils';

export const GoalsOnboardingView = () => {
  const {t} = useTranslation();
  const location = useLocation();

  useDocumentTitle(`${t('add_goal')}`);

  useEffect(() => {
    if (location.state?.source) {
      analyticsLogger().logEvent('GOAL_ONBOARDING_VIEW', {source: location.state.source});
    }
  }, [location.state?.source]);

  return (
    <Layout.Container size="condensed">
      <Stack gap="16">
        <Title size={24} withAccessoryStart={<UIIcon size="large" src={TargetLine} />}>
          {t('add_goal')}
        </Title>
        <Split gap="16">
          <Split.Item>
            <OnboardingJoinComponent />
          </Split.Item>
          <Split.Item>
            <OnboardingCreateComponent />
          </Split.Item>
        </Split>
      </Stack>
    </Layout.Container>
  );
};

const OnboardingJoinComponent = () => {
  const {t} = useTranslation();
  const location = useLocation();

  const dummyGoal = `/goals/${dummyGoalId}/${dummyKRId}?t=Q3+FY24`;

  const {isGoalsJoinFlowEnabled} = useAtomValue(growthbookCacheAtom);

  return (
    <Box
      borderStyle="Solid"
      borderRadius="Small"
      borderColor="Border Subtle"
      paddingTop="24"
      paddingBottom="24"
      paddingLeft="32"
      paddingRight="32"
      style={{width: '362px'}}
    >
      <Stack gap="12" paddingTop="16">
        <Box className={styles.puzzlePieces}>
          <PuzzlePiecesMini altText="puzzlePiece" width={64} height={64} />
        </Box>
        <Stack gap="8">
          <Stack gap="4">
            <Title size="small">{t('join_an_existing_goal')}</Title>
            <Box as={Text} variant="paragraph" color="Text Subtle" display="block">
              {t('join_an_existing_goal_subtitle')}
            </Box>
          </Stack>
          <Box paddingLeft="20" style={{height: '120px'}}>
            <Box as={Text} color="Text Subtle">
              <Box as="ul" style={{listStyleType: 'disc'}}>
                <Box as="li">{t('quickly_align_with_company_and_manager_goals')}</Box>
                <Box as="li">{t('contribute_to_key_results')}</Box>
                <Box as="li">{t('update_as_contributor')}</Box>
              </Box>
            </Box>
          </Box>
        </Stack>
        {isGoalsJoinFlowEnabled && (
          <ButtonLink
            variant="primary"
            to={ROUTE_PATHS.GOALS_V2_JOIN}
            state={{
              source: 'create-join',
              timeframe: location.state?.timeframe ?? DEFAULT_TIMEFRAME,
            }}
            style={{marginTop: '12px'}}
          >
            {t('join_goal')}
          </ButtonLink>
        )}
        {!isGoalsJoinFlowEnabled && (
          <ButtonLink
            variant="primary"
            to={dummyGoal}
            state={{
              source: 'create-join',
              timeframe: location.state?.timeframe ?? DEFAULT_TIMEFRAME,
            }}
            style={{marginTop: '12px'}}
          >
            {t('join_goal')}
          </ButtonLink>
        )}
      </Stack>
    </Box>
  );
};

const OnboardingCreateComponent = () => {
  const {t} = useTranslation();
  const location = useLocation();

  return (
    <Box
      borderStyle="Solid"
      borderRadius="Small"
      borderColor="Border Subtle"
      paddingTop="24"
      paddingBottom="24"
      paddingLeft="32"
      paddingRight="32"
      width="100%"
      style={{width: '364px'}}
    >
      <Stack gap="12" paddingTop="16">
        <Box className={styles.plugMini}>
          <PlugMini altText="plugMini" width={64} height={64} />
        </Box>
        <Stack gap="8">
          <Stack gap="4">
            <Title size="small">{t('create_a_new_goal')}</Title>
            <Box as={Text} variant="paragraph" color="Text Subtle" display="block" width="100%">
              {t('create_a_new_goal_subtitle')}
            </Box>
          </Stack>
          <Box paddingLeft="20" style={{height: '142px'}}>
            <Box as={Text} color="Text Subtle">
              <Box as="ul" style={{listStyleType: 'disc'}}>
                <Box as="li">{t('add_a_parent_goal')}</Box>
                <Box as="li">{t('assign_key_results_to_others')}</Box>
                <Box as="li">{t('set_personal_and_private_goals')}</Box>
              </Box>
            </Box>
          </Box>
        </Stack>
        <ButtonLink
          variant="opacity"
          to={ROUTE_PATHS.NEW_GOAL}
          state={{
            source: 'onboarding',
            timeframe: location.state?.timeframe ?? DEFAULT_TIMEFRAME,
            delegateId: location.state?.delegateId,
          }}
          style={{marginTop: '12px'}}
        >
          {t('create_goal')}
        </ButtonLink>
      </Stack>
    </Box>
  );
};
