import {Employee, EmployeeFull, ProfilePage, UserService} from 'client';
import {atom} from 'jotai';
import {atomWithImmer} from 'jotai-immer';
import {getService} from 'utilities';

export const loggedInEmployeeAtom = atomWithImmer<Required<ProfilePage>>({
  employee: {} as EmployeeFull,
  reportingLine: [],
  directReports: [],
  teamMembers: [],
  delegates: [],
  delegatedBy: [],
  employeeTeams: [],
  workingWithMe: '',
  admin: {} as Employee,
});

// Derived atom from userProfileAtom to more easily manage modifying delegates
export const delegatesAtom = atom(
  (get) => get(loggedInEmployeeAtom).delegates,
  async (_, set, updatedDelegates: Employee[]) => {
    const ldapArray = updatedDelegates.map(({ldap}) => ldap);
    await getService(UserService).setDelegatesApiV1DelegatesPost(ldapArray);

    set(loggedInEmployeeAtom, (draft) => {
      draft.delegates = updatedDelegates;
    });
  }
);
