/* eslint-disable deprecation/deprecation */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$wrapNodeInElement, mergeRegister} from '@lexical/utils';
import {$createSpinnerNode, SpinnerNode} from 'components/DSYS/editor/nodes/SpinnerNode';
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from 'lexical';
import {useEffect} from 'react';

export type InsertSpinnerPayload = Readonly<string>;

export const INSERT_SPINNER_COMMAND: LexicalCommand<InsertSpinnerPayload> =
  createCommand('INSERT_SPINNER_COMMAND');

export const REMOVE_SPINNER_COMMAND: LexicalCommand<InsertSpinnerPayload> =
  createCommand('REMOVE_SPINNER_COMMAND');

export const SpinnerPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([SpinnerNode])) {
      throw new Error('SpinnerPlugin: SpinnerNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand<InsertSpinnerPayload>(
        INSERT_SPINNER_COMMAND,
        (payload) => {
          const loadingNode = $createSpinnerNode(payload);
          $insertNodes([loadingNode]);
          if ($isRootOrShadowRoot(loadingNode.getParentOrThrow())) {
            $wrapNodeInElement(loadingNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<InsertSpinnerPayload>(
        REMOVE_SPINNER_COMMAND,
        (payload) => {
          const spinner = editor
            .getRootElement()
            ?.querySelector(`[data-lexical-beautiful-spinner-name="${payload}"]`);

          if (spinner) {
            spinner.remove();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
};
