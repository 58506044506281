import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {GratitudeLine, SoundOnLine, ThumbsUpLine} from '@dropbox/dig-icons/assets';
import {FolderMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {growthbookCacheAtom, isMobileAtom} from 'atoms/layout';
import {Employee, EmployeeFull, EmployeeService, Team} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {EmptyState} from 'components/DSYS/EmptyState';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {GoalsList} from 'components/goals/GoalsList';
import {DEFAULT_TIMEFRAME} from 'constant';
import {isSuperUser} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Fragment, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {getService} from 'utilities';
import {GoalsHeader as GoalsHeaderV2} from 'views/goals_v2/GoalsHeader';
import {TimeframeSelector} from 'views/goals_v2/TimeframeSelector';
import {ProjectCard} from 'views/projects/ProjectCard';

import {KudosModal} from './KudosModal';
import styles from './ProfileBody.module.css';
import {ProfileConfetti} from './ProfileConfetti';
import {ProfileDetails} from './ProfileDetails';

interface ProfileBodyProps {
  employee: EmployeeFull;
  employeeTeams: Team[];
  admin?: Employee | null;
}

const currentDate = new Date();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
const currentDay = String(currentDate.getDate()).padStart(2, '0');
const formattedCurrentDate = `${currentMonth}/${currentDay}`;

const getConfettiType = (employee: EmployeeFull) => {
  if (employee.birthday === formattedCurrentDate) {
    return 'birthday';
  }

  if (employee?.tenure != null && employee.tenure > 0 && employee.tenure % 12 == 0) {
    return 'anniversary';
  }

  return undefined;
};

export const ProfileBody = (props: ProfileBodyProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useAtomValue(isMobileAtom);
  const [isKudosOpen, showKudosModal] = useState(false);
  const [timeframe, setTimeframe] = useState<string>(
    location.state?.timeframe ?? DEFAULT_TIMEFRAME
  );
  const [projectTimeframe, setProjectTimeframe] = useState<string>(
    location.state?.timeframe ?? DEFAULT_TIMEFRAME
  );
  const pulseUser = useAtomValue(pulseUserAtom);

  const isOldTimeframe = timeframe !== DEFAULT_TIMEFRAME;

  const {isProjectsEnabled} = useAtomValue(growthbookCacheAtom);
  const {data} = useQuery({
    enabled: isProjectsEnabled,
    queryKey: ['employee', 'projects', props.employee.user_id, projectTimeframe],
    queryFn: () =>
      getService(EmployeeService).getProjectsApiV1PeopleEmployeeIdProjectsGet(
        props.employee.user_id,
        projectTimeframe
      ),
  });

  const handleMyTimeframeChange = (selectedQuarter: string) => setTimeframe(selectedQuarter);

  const profileActionLog = (action: string) =>
    analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  const confettiType = getConfettiType(props.employee);

  useEffect(() => {
    if (location.state?.timeframe !== timeframe) {
      navigate('.', {state: {...location.state, timeframe}, replace: true});
    }
  }, [timeframe, location, navigate]);

  return (
    <div className={styles.profileBodySection}>
      {confettiType && <ProfileConfetti ldap={props.employee.ldap} type={confettiType} />}
      <div className={styles.profileNameAvatar}>
        <Box position="relative">
          <Avatar
            user={props.employee}
            onLeave={props.employee.on_leave}
            size="profile"
            key={props.employee.ldap}
          />
        </Box>
        <div className={styles.profileNameCard}>
          <div className={styles.profileNameHeader}>
            <Title
              size={24}
              withAccessoryEnd={
                <>
                  {window.speechSynthesis && props.employee.pronunciation && (
                    <>
                      <IconButton
                        variant="borderless"
                        onClick={() => {
                          profileActionLog('pronunciation');
                          window.speechSynthesis.speak(
                            new SpeechSynthesisUtterance(props.employee.pronunciation!)
                          );
                        }}
                      >
                        <Box as={UIIcon} color="Text Subtle" src={SoundOnLine} />
                      </IconButton>{' '}
                    </>
                  )}
                  {props.employee.pronouns && (
                    <Text size="small" color="faint">
                      {props.employee.pronouns}
                    </Text>
                  )}
                </>
              }
            >
              {props.employee.name !== ' ' ? props.employee.name : props.employee.ldap}
            </Title>
          </div>
          <div>
            <Text size="large" color="faint">
              {props.employee.role}
              {props.employee.type == 'Regular' &&
                props.employee.level &&
                ` (${props.employee.level})`}
            </Text>
            {props.employee.type !== 'Regular' && (
              <Text size="large" color="faint">
                {''} ({props.employee.type})
              </Text>
            )}
            {props.employeeTeams && props.employeeTeams.length > 0 && (
              <Text size="large" color="faint">
                {' '}
                on{' '}
              </Text>
            )}
            {props.employeeTeams &&
              props.employeeTeams.length > 0 &&
              props.employeeTeams.map(
                (team, index) =>
                  team && (
                    <Fragment key={team.slug}>
                      <Text size="large" color="faint">
                        {index > 0 && <strong>{', '}</strong>}
                        <Link
                          to={`/teams/${team.slug}`}
                          onClick={() => profileActionLog('team')}
                          monochromatic
                          hasNoUnderline
                          showUnderlineOnHover
                          isBold
                        >
                          {team.name}
                        </Link>
                      </Text>
                    </Fragment>
                  )
              )}
          </div>
          {props.employee.email !== pulseUser?.email && (
            <Split gap={isMobile ? '4' : '12'} direction={isMobile ? 'vertical' : 'horizontal'}>
              <Split.Item>
                <ButtonLink
                  to={`/gratitude?to=${props.employee.ldap}`}
                  variant="outline"
                  withIconStart={<UIIcon src={GratitudeLine} />}
                  className={styles.gratitudeButton}
                  onClick={() => profileActionLog('gratitude')}
                >
                  {t('send_gratitude')}
                </ButtonLink>
              </Split.Item>
              {isSuperUser(pulseUser?.email) && (
                <Split.Item>
                  <Button
                    variant="outline"
                    withIconStart={<UIIcon src={ThumbsUpLine} />}
                    className={styles.gratitudeButton}
                    onClick={() => showKudosModal(true)}
                  >
                    {t('give_kudos')}
                  </Button>
                </Split.Item>
              )}
            </Split>
          )}
        </div>
      </div>
      <ProfileDetails employee={props.employee} admin={props.admin} onClick={profileActionLog} />
      {data?.total ? (
        <Box as={Stack} width="100%">
          <Split paddingBottom="8">
            <Split.Item width="fill">
              <Text isBold size="xlarge">
                {t('projects')}
              </Text>
            </Split.Item>
            <Split.Item>
              <TimeframeSelector
                selectedTimeframe={projectTimeframe}
                setSelectedTimeframe={(next) => {
                  analyticsLogger().logEvent('PROFILE_PROJECT_TIMEFRAME', {timeframe: next});
                  setProjectTimeframe(next);
                }}
              />
            </Split.Item>
          </Split>
          {data.projects.length ? (
            data.projects.map((project) => (
              <Box
                key={project.id}
                padding="8"
                {...withShade({duration: 'None'})}
                borderRadius="Medium"
              >
                <Link to={`/projects/${project.id}`} hasNoUnderline>
                  <ProjectCard
                    isProfile
                    title={project.title}
                    subtitle={project.subtitle}
                    projectId={project.id}
                    onStatusClick={() => profileActionLog('project')}
                  />
                </Link>
              </Box>
            ))
          ) : (
            <EmptyState
              title={!isOldTimeframe ? 'No projects for this quarter' : 'No projects just yet'}
              body={
                !isOldTimeframe
                  ? 'Try switching the quarter to another one.'
                  : 'Projects will show up here once added, so check again later!'
              }
              image={<FolderMini width={64} altText={''} />}
            />
          )}
        </Box>
      ) : null}
      <div className={styles.goals}>
        <GoalsHeaderV2
          timeframe={timeframe}
          setTimeframe={handleMyTimeframeChange}
          containerStyleProps={{marginRight: '8px'}}
          titleOverride={t('goals')}
          disableCountHeader={true}
          disableStickyHeader={true}
        />
        <GoalsList
          type={props.employee.email === pulseUser?.email ? 'self' : 'employee'}
          ldaps={[props.employee.ldap]}
          timeframe={timeframe}
          onClick={profileActionLog}
        />
      </div>
      <KudosModal
        open={isKudosOpen}
        closeModal={() => showKudosModal(false)}
        employee={props.employee}
      />
    </div>
  );
};
