import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkCircleLine} from '@dropbox/dig-icons/assets';
import {Avatar} from 'components/DSYS/Avatar';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {useProject} from 'components/projects/hooks';
import {EmptyCell} from 'components/shared/table/EmptyCell';
import {TimeAgo} from 'components/shared/TimeAgo';
import {useAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {RootNode} from 'lexical';
import {useEffect, useState} from 'react';

export const seenUpdatesAtom = atomWithStorage<{[projectId: number]: number}>('seenUpdates', {});

// TODO: This should be a shared component
const ProjectUpdate = ({
  projectId,
  onClick,
  width,
}: {
  projectId: number;
  onClick?: () => void;
  width: number;
}) => {
  const {data, isLoading} = useProject({id: projectId});
  const [editorNodes, setEditorNodes] = useState<RootNode>();
  const latestUpdate = data?.project?.latest_update;
  const [seenUpdates, setSeenUpdates] = useAtom(seenUpdatesAtom);
  const [loadedUpdates, setLoadedUpdates] = useState(false);

  useEffect(() => {
    if (Object.keys(seenUpdates)) {
      setLoadedUpdates(true);
    }

    setTimeout(() => {
      setLoadedUpdates(true);
    }, 1);
  }, [seenUpdates]);

  if (!latestUpdate || isLoading) {
    return <EmptyCell />;
  }

  return (
    <Table.Cell
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
        setSeenUpdates({
          ...seenUpdates,
          [projectId]: latestUpdate.id,
        });
      }}
    >
      <Box
        padding="8"
        borderRadius="Medium"
        borderStyle="Solid"
        backgroundColor={'transparent'}
        borderWidth="0"
        cursor="pointer"
        outline="none"
        textAlign="left"
        width="100%"
        boxShadow={{focusVisible: 'Focus Ring'}}
        {...withShade({
          direction: 'up',
          bgColors: {
            base: 'Opacity Surface',
            active: 'Opacity Surface',
          },
        })}
      >
        <Box display="none">
          <RichTextArea
            theme="small-faint"
            value={latestUpdate.comment}
            onRootReady={setEditorNodes}
          />
        </Box>
        <LabelGroup
          size="small"
          align="top"
          withLeftAccessory={<Avatar user={latestUpdate.employee.ldap} size="small" />}
          withText={
            <>
              {width < 100 ? null : (
                <Text
                  size="small"
                  isBold={loadedUpdates && seenUpdates[projectId] !== latestUpdate.id}
                >
                  <Box
                    as="div"
                    style={{textOverflow: 'ellipsis', maxWidth: width - 30}}
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {editorNodes?.__cachedText || ''}
                  </Box>
                </Text>
              )}
            </>
          }
          withSubtext={
            width < 100 ? null : (
              <Box as={Text} color="Text Subtle" size="xsmall" display="flex" alignItems="center">
                <TimeAgo timestamp={latestUpdate.created_at} />
                {loadedUpdates && seenUpdates[projectId] === latestUpdate.id && (
                  <Box as={UIIcon} src={CheckmarkCircleLine} size="small" color="Text Subtle" />
                )}
              </Box>
            )
          }
        />
      </Box>
    </Table.Cell>
  );
};

export const UpdateCell = ({
  projectId,
  onClick,
  width,
}: {
  projectId?: number;
  onClick?: () => void;
  width: number;
}) => {
  if (!projectId) {
    return <EmptyCell />;
  }

  return <ProjectUpdate projectId={projectId} onClick={onClick} width={width} />;
};
