import {Table} from '@dropbox/dig-components/dist/table';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CommentLine, LockLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Goal, GoalData, GoalRead, GoalUser, KeyResult} from 'client';
import {useGoalThreads} from 'components/comments/hooks';
import {AccordionIconButton} from 'components/DSYS/Accordion';
import {Link} from 'components/DSYS/Link';
import {getSubstatus} from 'components/shared/GoalStatusButton';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {isGoalPrivate, isGoalType} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {Fragment, ReactNode, Suspense, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {emailToLdap} from 'utilities';
import {DrawerGoalData, TeamInfo} from 'views/goals_v2/types';
import {getLatestUpdate, getRowData} from 'views/goals_v2/utils';

import {getThreads, getTotalCommentsCount} from './Drawers/utils';
import {ExpandGoalMenu} from './ExpandGoalMenu';
import styles from './GoalsTableRow.module.css';
import {ActionMenuCell} from './Table/ActionMenuCell';
import {ContributorCell} from './Table/ContributorCell';
import {GoalsNameCell} from './Table/GoalsNameCell';
import {StatusCell} from './Table/StatusCell';
import {TimestampCell} from './Table/TimestampCell';
import {UpdateCell} from './Table/UpdateCell';
import {getHeight} from './Table/utils';

const indentWidth: {[index: number]: string} = {
  0: '0',
  1: '0',
  2: '23',
  3: '44',
};

const isGoalsTableRowSelected = (
  selectedGoalData: DrawerGoalData | undefined,
  row: Goal | KeyResult | GoalRead,
  isGoalRow: boolean
) => {
  const isKeyResultSelected = selectedGoalData?.keyResultId;
  if (isGoalRow) {
    return !isKeyResultSelected && selectedGoalData?.goalId === row.id;
  } else {
    return isKeyResultSelected && selectedGoalData?.keyResultId === row.id;
  }
};

export const getKeyResultChildren = (
  keyResultId: number,
  goal: GoalData | KeyResult | GoalRead
) => {
  if (isGoalType(goal) && 'key_results_aligned_goals' in goal) {
    return goal.key_results_aligned_goals?.filter((g) => g.key_result_parent_id === keyResultId);
  }

  return [];
};

export const GoalsTableRow = ({
  uselessTable,
  expanded,
  columnConfigs,
  withLeftAccessory,
  isGoalsV2DrawerOpen,
  onRowClick,
  onContributorsClick,
  onContinueGoalClick,
  hasScrolledToRow,
  setScrolledToRow,
  selectedGoalData,
  expandedParentGoals,
  handleSetExpandedParentGoals,
  shouldGroupGoalsByEmployee,
  indent = 0,
  id,
  expandId,
  rowKeyPrefix,
  row,
  goalOwner,
  parentGoalOwner,
  isAlignedGoal,
  setExpanded,
  paddingTop,
  paddingLeft, // ...rest
  ownerLdap,
  rowProps = {},
  isLastRow = false,
  isDraft = false,
  showActionMenu = true,
  showUpdates = true,
  teamInfo,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  disableExpandGoalActionMenu,
  keyResultChildren,
}: {
  id: number;
  uselessTable?: boolean;
  rowKeyPrefix: string;
  row: GoalData | KeyResult | GoalRead;
  keyResultChildren?: Goal[];
  goalOwner: GoalUser;
  parentGoalOwner?: GoalUser;
  isAlignedGoal?: boolean;
  expandId?: string;
  expanded: {[key: string]: boolean};
  withLeftAccessory?: ReactNode;
  isGoalsV2DrawerOpen: boolean;
  onRowClick: (goalData: DrawerGoalData, isOpen: boolean) => void;
  onContributorsClick: (goalData: DrawerGoalData, source: string) => void;
  onContinueGoalClick: (goalData: DrawerGoalData, source: string) => void;
  hasScrolledToRow: boolean;
  setScrolledToRow: (hasScrolledToRow: boolean) => void;
  selectedGoalData: DrawerGoalData | undefined;
  expandedParentGoals: {[id: string]: GoalRead};
  handleSetExpandedParentGoals: (goal: GoalData, parentGoal: GoalRead) => void;
  setExpanded?: (value: React.SetStateAction<{[id: string]: boolean}>) => void;
  indent?: number;
  columnConfigs: ColumnConfig[];
  paddingTop?: BoxProps<'div'>['paddingTop'];
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  rowProps?: {[key: string]: any};
  isLastRow?: boolean;
  shouldGroupGoalsByEmployee: boolean;
  showActionMenu?: boolean;
  showUpdates?: boolean;
  isDraft?: boolean | null;
  ownerLdap?: string;
  teamInfo?: TeamInfo;
  timeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  disableExpandGoalActionMenu?: boolean;
}) => {
  const isGoalRow = isGoalType(row);
  const isSelected = isGoalsTableRowSelected(selectedGoalData, row, isGoalRow);
  const isDraftRow = isGoalRow ? !!row.is_draft : !!isDraft;
  const {t} = useTranslation();

  const {delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const pulseUser = useAtomValue(pulseUserAtom);
  const {currentRowGoalOwner, canEdit, canUpdate, latestUpdate, goalId, keyResultId} = getRowData(
    row,
    goalOwner,
    pulseUser?.email,
    delegatedBy
  );

  const threads = useGoalThreads({
    goalId,
    shouldRefetch: false,
    disabled: !isDraftRow,
  });

  const currentCommentCount = getTotalCommentsCount(getThreads(goalId, threads, keyResultId));

  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovered, setHovered] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [showAllKeyResults, setShowAllKeyResults] = useState(false);

  const {marginLeft, width} = useMemo(() => {
    const left = parseInt(indentWidth[indent] as any, 10);

    return {
      marginLeft: left.toString() as any,
      width: uselessTable
        ? columnConfigs[0].width + 44
        : columnConfigs[0].width + 44 - left - (indent === 1 ? 24 : 0) - (indent === 2 ? -72 : 0),
    };
  }, [columnConfigs, indent, uselessTable]);

  useEffect(() => {
    // If selected goal is not in view, scroll to it
    const rowRect = rowRef.current?.getBoundingClientRect();
    if (hasScrolledToRow || !isSelected || !rowRef.current || !rowRect) {
      return;
    }
    const isRowWithinView =
      rowRect.top >= 0 &&
      rowRect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    if (!isRowWithinView) {
      rowRef.current.scrollIntoView({block: 'center', behavior: 'smooth'});
      setScrolledToRow(true); // only allow scoll to row once
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  const filteredKeyResults = ((row as Goal)?.key_results ?? []).filter((kr) => {
    if (showAllKeyResults) {
      return true;
    }

    // We currently can't collapse owner goals and expand contributing goals for Team tables,
    // so we shouldn't filter out key results for them
    if (shouldGroupGoalsByEmployee) {
      return true;
    }

    if (emailToLdap(goalOwner.email) === ownerLdap) {
      return true;
    }

    // Do not filter out key results if the goal is aligned to another goal
    if (isAlignedGoal) {
      return true;
    }

    return kr.contributors.some((contributor) => contributor.ldap === ownerLdap);
  });
  const parentGoal = isGoalRow ? (row as GoalData).parent : undefined;
  const showViewParentGoalAction =
    !!parentGoal &&
    !Object.values(expandedParentGoals).find(
      (expandedParentGoal) => expandedParentGoal.id === parentGoal.id
    );
  const hasHiddenKeyResults =
    isGoalRow &&
    !!filteredKeyResults?.length &&
    filteredKeyResults.length !== row.key_results.length;
  const showExpandGoalActionMenu =
    !disableExpandGoalActionMenu && (showViewParentGoalAction || hasHiddenKeyResults);

  const renderLeftAccessory = (
    childRow: GoalRead | KeyResult,
    index: number,
    type: 'keyresult' | 'goal',
    hasChildren: boolean
  ) => {
    const expandedRowId = childRow.id;
    const activeExpandId =
      type === 'goal' ? `goals-${expandedRowId}` : `keyresult-${expandedRowId}`;

    const isMiddle: boolean = (
      type === 'keyresult'
        ? ('key_results' in row && index !== filteredKeyResults.length - 1) ||
          ('children' in row &&
            row.children &&
            Array.isArray(row.children) &&
            row.children.length > 0)
        : 'children' in row &&
          row.children &&
          Array.isArray(row.children) &&
          index !== row.children.length - 1
    ) as boolean;

    return (
      <Box
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
        height="100%"
      >
        {indent > 0 && (
          <Box
            as="span"
            height="100%"
            borderColor={isLastRow ? 'transparent' : 'Border Subtle'}
            borderLeft="Solid"
            style={{
              minHeight: '68px',
              width: '12px',
              marginLeft: '12px',
              paddingLeft: '23px',
            }}
            display="block"
          />
        )}
        <Box
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          height="100%"
        >
          {
            <>
              <Box as="div" style={{display: 'flex'}} height="100%">
                <Box as="div" height="100%">
                  <Box
                    as="span"
                    style={{
                      width: '24px',
                      height: '20px',
                      marginLeft: '12px',
                      borderRadius: isMiddle ? '0' : '0 0 0 4px',
                    }}
                    borderLeft="Solid"
                    borderBottom="Solid"
                    borderColor="Border Subtle"
                    display="block"
                  ></Box>
                  {isMiddle && (
                    <Box
                      as="span"
                      style={{
                        width: '12px',
                        height: 'calc(100% - 20px)',
                        marginLeft: '12px',
                      }}
                      borderLeft="Solid"
                      borderColor="Border Subtle"
                      display="block"
                    ></Box>
                  )}
                </Box>
                {type === 'goal' && (
                  <Box
                    as="div"
                    paddingTop="8"
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    height="100%"
                  >
                    <AccordionIconButton
                      isHidden={false}
                      isActive={expanded[activeExpandId]}
                      toggle={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const toggleExpanded = !expanded[activeExpandId];
                        if (toggleExpanded) {
                          const {latestUpdate: childRowLatestUpdate} = getLatestUpdate(
                            childRow.updates
                          );
                          analyticsLogger().logEvent('GOAL_TABLE_EXPANDED', {
                            status: childRowLatestUpdate?.status ?? 'no_status',
                          });
                        }
                        setExpanded!({
                          ...expanded,
                          [activeExpandId]: toggleExpanded,
                        });
                      }}
                    />
                    {expanded[activeExpandId] && hasChildren && (
                      <Box
                        as="div"
                        style={{
                          width: '1px',
                          height: 'calc(100% - 24px)',
                        }}
                        backgroundColor="Border Subtle"
                        marginLeft="12"
                      ></Box>
                    )}
                  </Box>
                )}
              </Box>
            </>
          }
        </Box>
      </Box>
    );
  };

  const renderChildren = () => {
    return (
      <>
        {isGoalRow &&
          filteredKeyResults.map((keyResult, index) => (
            <GoalsTableRow
              key={`${rowKeyPrefix}-kr-${keyResult.id}`}
              rowKeyPrefix={rowKeyPrefix}
              expandId={`keyresult-${keyResult.id}`}
              expanded={expanded}
              setExpanded={setExpanded}
              uselessTable={true}
              columnConfigs={columnConfigs}
              indent={indent + 1}
              id={keyResult.id}
              row={keyResult}
              shouldGroupGoalsByEmployee={shouldGroupGoalsByEmployee}
              goalOwner={currentRowGoalOwner}
              parentGoalOwner={parentGoalOwner}
              isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
              onRowClick={onRowClick}
              onContributorsClick={onContributorsClick}
              onContinueGoalClick={onContinueGoalClick}
              hasScrolledToRow={hasScrolledToRow}
              setScrolledToRow={setScrolledToRow}
              selectedGoalData={selectedGoalData}
              expandedParentGoals={expandedParentGoals}
              paddingLeft={paddingLeft}
              paddingTop="0"
              rowProps={rowProps}
              isLastRow={index === filteredKeyResults.length - 1}
              withLeftAccessory={renderLeftAccessory(keyResult, index, 'keyresult', false)}
              isDraft={row.is_draft}
              showActionMenu={showActionMenu}
              showUpdates={showUpdates}
              ownerLdap={ownerLdap}
              teamInfo={teamInfo}
              timeframe={timeframe}
              nextTimeframe={nextTimeframe}
              setNextTimeframe={setNextTimeframe}
              disableExpandGoalActionMenu={disableExpandGoalActionMenu}
              handleSetExpandedParentGoals={handleSetExpandedParentGoals}
              keyResultChildren={getKeyResultChildren(keyResult.id, row)}
            />
          ))}
        {'children' in row &&
          Array.isArray(row.children) &&
          row.children?.map((goal, index) => (
            <Suspense fallback={null} key={`goals-${goal.id}`}>
              <GoalsTableRow
                key={`${rowKeyPrefix}-subgoal-${goal.id}`}
                rowKeyPrefix={rowKeyPrefix}
                expandId={`goals-${goal.id}`}
                expanded={expanded}
                setExpanded={setExpanded}
                uselessTable={true}
                columnConfigs={columnConfigs}
                indent={indent + 1}
                shouldGroupGoalsByEmployee={shouldGroupGoalsByEmployee}
                id={goal.id}
                row={goal}
                goalOwner={currentRowGoalOwner}
                parentGoalOwner={parentGoalOwner}
                isAlignedGoal={true}
                isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
                onRowClick={onRowClick}
                onContributorsClick={onContributorsClick}
                onContinueGoalClick={onContinueGoalClick}
                hasScrolledToRow={hasScrolledToRow}
                setScrolledToRow={setScrolledToRow}
                selectedGoalData={selectedGoalData}
                expandedParentGoals={expandedParentGoals}
                handleSetExpandedParentGoals={handleSetExpandedParentGoals}
                paddingLeft={paddingLeft}
                paddingTop="0"
                rowProps={rowProps}
                isLastRow={Array.isArray(row.children) && index === (row.children?.length ?? 0) - 1}
                withLeftAccessory={renderLeftAccessory(
                  goal,
                  index,
                  'goal',
                  goal.key_results.length > 0
                )}
                showActionMenu={showActionMenu}
                showUpdates={showUpdates}
                ownerLdap={ownerLdap}
                teamInfo={teamInfo}
                timeframe={timeframe}
                nextTimeframe={nextTimeframe}
                setNextTimeframe={setNextTimeframe}
                disableExpandGoalActionMenu={disableExpandGoalActionMenu}
              />
            </Suspense>
          ))}
      </>
    );
  };

  const goalData = {goalId, keyResultId, teamInfo, isDraft: isDraftRow};
  const toggleGoalDetailsDrawer = !isGoalsV2DrawerOpen;
  return (
    <Fragment key={`${rowKeyPrefix}-${id}`}>
      <Box
        ref={rowRef}
        as={Table.Row}
        isSelectable
        onClick={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
        className={isSelected ? styles.goalsTableRowSelected : ''}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...rowProps}
      >
        <GoalsNameCell
          uselessTable={uselessTable}
          withLeftAccessory={withLeftAccessory}
          width={width}
          minWidth={columnConfigs[0].minWidth ? `${columnConfigs[0].minWidth}px` : `${width}px`}
          maxWidth={columnConfigs[0].maxWidth ? `${columnConfigs[0].maxWidth}px` : `${width}px`}
          title={row.title}
          type={isGoalRow ? 'goal' : 'keyresult'}
          subtitle={
            <>
              {!isGoalRow && (
                <>
                  <Box display="flex" alignItems="center">
                    {keyResultChildren && keyResultChildren.length > 0 && (
                      <Box display="flex" alignItems="center">
                        {t('sub_goal', {count: keyResultChildren.length})}
                      </Box>
                    )}
                    {currentCommentCount > 0 && isDraftRow && (
                      <CommentCount
                        count={currentCommentCount}
                        includeSeperator={
                          (keyResultChildren && keyResultChildren.length > 0) ?? false
                        }
                      />
                    )}
                  </Box>
                </>
              )}
              {isGoalRow && (
                <>
                  <Box display="flex" alignItems="center">
                    {hasHiddenKeyResults
                      ? t('key_result_count_with_total', {
                          count: filteredKeyResults.length,
                          totalCount: row.key_results.length,
                        })
                      : t('key_result', {count: row.key_results.length})}
                    {'children' in row &&
                      Array.isArray(row.children) &&
                      row.children.length > 0 && (
                        <Box display="inline-flex" alignItems="center" marginLeft="8">
                          • {t('sub_goal', {count: row.children.length})}
                        </Box>
                      )}
                    {currentCommentCount > 0 && isDraftRow && (
                      <CommentCount count={currentCommentCount} includeSeperator={true} />
                    )}
                    {isGoalPrivate(row as Goal) && (
                      <Box display="inline-flex" alignItems="center" marginLeft="8">
                        •
                        {
                          <Box
                            as={UIIcon}
                            marginLeft="4"
                            marginRight="2"
                            src={LockLine}
                            size="small"
                          />
                        }
                        {t('private')}
                      </Box>
                    )}
                    {showExpandGoalActionMenu ? (
                      <ExpandGoalMenu
                        goal={row as GoalData}
                        parentGoal={parentGoal}
                        showViewParentGoalAction={showViewParentGoalAction}
                        hasHiddenKeyResults={hasHiddenKeyResults}
                        setShowAllKeyResults={setShowAllKeyResults}
                        handleSetExpandedParentGoals={handleSetExpandedParentGoals}
                      />
                    ) : (
                      hasHiddenKeyResults && (
                        <Box
                          as={Link}
                          marginLeft="4"
                          to={`/goals/${row.id}`}
                          state={{source: 'profile-view-all'}}
                          onClick={() => {
                            analyticsLogger().logEvent('GOAL_TABLE_VIEW_ALL_CLICKED');
                          }}
                        >
                          {t('view_all')}
                        </Box>
                      )
                    )}
                  </Box>
                </>
              )}
            </>
          }
          marginLeft={marginLeft}
          paddingLeft={paddingLeft}
          paddingTop={paddingTop}
          onClick={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
          minHeight={
            showUpdates && latestUpdate && !isGoalRow ? getHeight(latestUpdate.comment) : undefined
          }
        />
        <ContributorCell
          row={row}
          ownerLdap={emailToLdap(currentRowGoalOwner.email)}
          onClick={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
          canEdit={canUpdate}
        />
        <StatusCell
          title={row.title}
          latestUpdate={latestUpdate}
          substatus={isGoalRow ? getSubstatus(row, latestUpdate) : undefined}
          goalId={goalId}
          keyResultId={isGoalRow ? undefined : row.id}
          timeframe={timeframe}
          nextTimeframe={nextTimeframe}
          setNextTimeframe={setNextTimeframe}
          user={currentRowGoalOwner}
          canEdit={isDraft && !isGoalRow ? canEdit ?? false : canUpdate}
          isDraft={isGoalRow ? row.is_draft : isDraft}
          teamInfo={teamInfo}
          onClick={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
          parentGoalUser={parentGoalOwner}
          ownerLdap={ownerLdap}
          disableAction={isDraftRow}
          onContinueGoalClick={(selectedStatus: string) =>
            onContinueGoalClick(
              {
                goalId,
                keyResultId,
                goalOwnerId: currentRowGoalOwner.user_id,
                goalOwnerEmail: currentRowGoalOwner.email,
                latestUpdateStatus: selectedStatus,
              },
              'modal'
            )
          }
          onSuccess={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
        />
        {!showUpdates ? (
          <></>
        ) : isGoalRow || !latestUpdate ? (
          <TimestampCell
            timestamp={latestUpdate?.updated_at ?? row.updated_at!}
            onClick={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
            canEdit={canEdit}
          />
        ) : (
          <UpdateCell
            latestUpdate={latestUpdate}
            goalOwner={currentRowGoalOwner}
            onClick={() => onRowClick(goalData, toggleGoalDetailsDrawer)}
            hasContributors={!isGoalRow && (row as KeyResult).contributors.length > 0}
            canEdit={canUpdate}
            maxWidth={columnConfigs[3].width}
          />
        )}
        {!showActionMenu ? (
          <></>
        ) : isHovered || isStatusModalOpen ? (
          <ActionMenuCell
            source="table"
            user={currentRowGoalOwner}
            goal={isGoalRow ? row : undefined}
            keyResult={isGoalRow ? undefined : row}
            canDelete={Boolean(canEdit)}
            teamInfo={teamInfo}
            parentGoalUser={parentGoalOwner}
            ownerLdap={ownerLdap}
            latestUpdate={latestUpdate}
            isDraftRow={isDraftRow}
            timeframe={timeframe}
            nextTimeframe={nextTimeframe}
            setNextTimeframe={setNextTimeframe}
            setIsStatusModalOpen={setIsStatusModalOpen}
            onContributorCallback={() => onContributorsClick(goalData, 'menu')}
            onContinueGoalCallback={(selectedStatus: string) =>
              onContinueGoalClick(
                {
                  goalId,
                  keyResultId,
                  goalOwnerId: currentRowGoalOwner.user_id,
                  goalOwnerEmail: currentRowGoalOwner.email,
                  latestUpdateStatus: selectedStatus,
                },
                'menu'
              )
            }
            variant="borderless"
          />
        ) : (
          <Table.Cell />
        )}
      </Box>
      {expandId && expanded[expandId] && renderChildren()}
    </Fragment>
  );
};

const CommentCount = ({count, includeSeperator}: {count: number; includeSeperator: boolean}) => {
  const {t} = useTranslation();
  return (
    <Box as="span" display="flex" alignItems="center" marginLeft={includeSeperator ? '8' : '0'}>
      {includeSeperator && <Box marginRight="8">•</Box>}
      <UIIcon src={CommentLine} size="small" />
      <Box as="span" marginLeft="4">
        {t('comments', {count})}
      </Box>
    </Box>
  );
};
