import {Checkbox} from '@dropbox/dig-components/controls';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {Team} from 'client';
import styles from 'components/goals/edit/StrategyConnectSearch.module.css';
import {useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useRef, useState} from 'react';

export const GoalTeamSearch = ({
  teams = [],
  handleTeamSelected,
}: {
  teams?: Team[];
  handleTeamSelected: (teams: Team[]) => void;
}) => {
  const [inputValue, setInputValue] = useState('');
  const searchedTeamResults = useGlobalSearch({
    input: inputValue,
    filter: 'teams',
    showMore: false,
  });
  const searchedTeams = searchedTeamResults.map((result) => result as Team);

  const inputContainerRef = useRef<HTMLElement | null>(null);

  const handleSelection = (team: Team) => {
    const isChecked = teams.findIndex((tm) => tm.slug === team.slug) !== -1;
    const newSelected = isChecked
      ? [...teams.filter((tm) => tm.slug !== team.slug)]
      : [team, ...teams];
    handleTeamSelected(newSelected);
  };

  const renderTypeaheadRow = (team: Team) => {
    const isChecked = teams.findIndex((tm) => tm.slug === team.slug) !== -1;
    return (
      <Typeahead.Row
        key={team.slug}
        value={team}
        withTitle={
          <Box whiteSpace="nowrap" overflow="hidden" style={{textOverflow: 'ellipsis'}}>
            {team.name}
          </Box>
        }
        role="menuitemcheckbox"
        aria-label={`Option ${team.slug}`}
        aria-checked={isChecked}
        withLeftAccessory={<Checkbox checked={isChecked} readOnly tabIndex={-1} />}
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.objectiveActionContainer}>
      <Typeahead.Wrapper
        isPortaled={false}
        onSelection={handleSelection}
        closeOnSelection={false}
        className={styles.strategySelectOptions}
      >
        {({getTriggerProps, getContentProps}) => (
          <>
            <TextInput.Container ref={inputContainerRef} style={{maxWidth: '241px'}}>
              <TextInput.Accessory>
                <UIIcon src={SearchLine} />
              </TextInput.Accessory>
              <TextInput.Input
                placeholder={
                  teams.length
                    ? t('team_selected', {count: teams.length})
                    : t('select_teams').toString()
                }
                value={inputValue}
                {...getTriggerProps({
                  onChange: onInputChange,
                  onKeyDown: handleKeyDown,
                })}
                style={{fontSize: 14}}
              />
            </TextInput.Container>
            <Typeahead.Container
              {...getContentProps()}
              className={styles.typeaheadResultsContainer}
              triggerRef={inputContainerRef}
            >
              {searchedTeams.length ? (
                <Typeahead.Results
                  title={t('teams')}
                  results={searchedTeams}
                  renderRow={renderTypeaheadRow}
                  initialResults={inputValue.length ? 10 : 5}
                  maxResults={inputValue.length ? 10 : 5}
                />
              ) : inputValue.length ? (
                <Typeahead.Results
                  title={t('teams')}
                  results={[{}]}
                  renderRow={renderNoResultsRow}
                />
              ) : null}
            </Typeahead.Container>
          </>
        )}
      </Typeahead.Wrapper>
    </div>
  );
};
