import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  CircleStandardFilledFill,
  CircleStandardLine,
  FailFill,
  ZoomOutFill,
} from '@dropbox/dig-icons/assets';

export const statusOptions = [
  {
    label: 'open',
    statusOptions: [
      {value: 'on_track', color: '#9fc418ff', icon: CircleStandardFilledFill},
      {value: 'at_risk', color: '#dcaf21ff', icon: CircleStandardFilledFill},
      {value: 'off_track', color: '#fb9d83ff', icon: CircleStandardFilledFill},
    ],
  },
  {
    label: 'closed',
    statusOptions: [
      {value: 'complete', color: '#759112ff', icon: CheckmarkCircleFill},
      {value: 'cancelled', color: '#bbb5aeff', icon: ZoomOutFill},
      {value: 'missed', color: '#fb9d83ff', icon: FailFill},
    ],
  },
  {
    label: 'draft',
    statusOptions: [{value: 'draft', color: '#BBB5AE', icon: CircleStandardLine}],
  },
];

export const getStatusStyle = (status?: string) => {
  const noStatus = {
    value: 'no_status',
    color: '#BBB5AE',
    icon: CircleStandardLine,
  };

  return status
    ? statusOptions
        .find((option) => option.statusOptions.some((item) => item.value === status))
        ?.statusOptions.find((item) => item.value === status) || noStatus
    : noStatus;
};

export const StatusButtonIcon = ({
  size = 'standard',
  status,
  substatus,
}: {
  size?: 'small' | 'standard';
  status?: string;
  substatus?: string;
}) => {
  const statusStyle = getStatusStyle(status);
  const subStatusStyle = substatus ? getStatusStyle(substatus) : undefined;

  return (
    <Box display="flex" alignItems="center">
      <UIIcon size={size} src={statusStyle.icon} color={statusStyle.color} />

      {subStatusStyle && statusStyle.value !== subStatusStyle?.value && (
        <>
          <Box
            as={UIIcon}
            src={CircleStandardFilledFill}
            color="Background Base"
            size="small"
            style={{width: 14, marginLeft: -9, marginTop: 10}}
          />
          <UIIcon
            src={subStatusStyle.icon}
            color={subStatusStyle.color}
            size="small"
            style={{width: 10, marginLeft: -12, marginTop: 10}}
          />
        </>
      )}
    </Box>
  );
};
