import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine} from '@dropbox/dig-icons/assets';
import {derivedShowAIGoalAssistantDrawerAtom, newGoalAtom, showNewBannerAtom} from 'atoms/goals';
import {snackbarAtom} from 'atoms/snackbar';
import {
  Employee,
  Goal,
  GoalData,
  GoalUpdate,
  KeyResult,
  KeyResultCreate,
  KeyResultUpdate,
  Team,
} from 'client';
import {useGoalService, useKeyResultService} from 'components/goals/hooks';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {SkeletonEditGoal} from 'components/shared/SkeletonGoal';
import {ROUTE_PATHS} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {isEmployee, isTeam} from 'hooks/useEmployee';
import {useProfile} from 'hooks/useProfile';
import {t} from 'i18next';
import {useAtom, useSetAtom} from 'jotai';
import {RESET} from 'jotai/utils';
import {useCallback, useEffect, useRef, useState} from 'react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {getEmployeeService, getGoalService, getKeyResultService} from 'utilities';
import {EditGoalDetailsDrawer} from 'views/goals_v2/Drawers/EditGoalDetailsDrawer';
import {EditGoalPrivacyDrawer} from 'views/goals_v2/Drawers/EditGoalPrivacyDrawer';
import {ParentGoalSearchDrawer} from 'views/goals_v2/Drawers/ParentGoalSearchDrawer';
import {shouldCloseAndResetDrawers} from 'views/goals_v2/Drawers/utils';

import {GoalDetailsSection} from './GoalDetailsSection';
import {GoalEditSection, GoalEditSectionHeader, KeyResultEditSection} from './GoalEditSection';
import styles from './NewGoalContent.module.css';
import {ParentGoalSection} from './ParentGoalSection';
//import {StrategyConnectSearch} from './StrategyConnectSearch';

type KeyResultUpdateWrapper = {
  id: number;
  keyResultId: number;
  userId: string;
  data: KeyResultUpdate;
};

type StagedKeyResult = KeyResultCreate & {id: number};

const getGoalUpdate = (goal: Goal): GoalUpdate => {
  const goalUpdate: GoalUpdate = {
    title: goal.title,
    timeframe: goal.timeframe,
    private: goal.private,
    strategy_ids: goal.strategy_ids ?? '',
    project_ids: goal.projects?.map(({id}) => id),
    key_result_parent_id: goal.key_result_parent_id ?? undefined,
    parent_id: goal?.parent?.id ?? undefined,
    is_draft: goal.is_draft,
    is_custom_privacy_included: goal.is_custom_privacy_included,
    individual_privacies: goal.individual_privacies,
    team_privacies: goal.team_privacies,
    created_at: goal.created_at,
  };
  return goalUpdate;
};

const getKeyResultsToUpdate = (goal: Goal): (StagedKeyResult | KeyResultUpdateWrapper)[] => {
  const keyResultsToUpdate: KeyResultUpdateWrapper[] = [];
  if (goal.key_results && goal.key_results.length > 0) {
    goal.key_results.forEach((keyResult, index) => {
      const keyResultUpdate: KeyResultUpdateWrapper = {
        id: index + 1,
        userId: goal.users![0].user_id,
        keyResultId: keyResult.id,
        data: {
          ...keyResult,
          contributors: keyResult.contributors.map(({ldap}) => ldap),
        },
      };
      keyResultsToUpdate.push(keyResultUpdate);
    });
  } else if (goal.key_results && goal.key_results.length === 0) {
    const stagedKeyResults: StagedKeyResult[] = [];
    // add 3 key results to the stagedKeyResults array
    for (let i = 0; i < 3; i++) {
      const keyResultUpdate: StagedKeyResult = {
        id: i + 1,
        title: '',
        sort: i,
        created_at: new Date().toISOString(),
      };
      stagedKeyResults.push(keyResultUpdate);
    }
    return stagedKeyResults;
  }
  keyResultsToUpdate.sort((a, b) => {
    if (a.data.sort === 0 && b.data.sort === 0) {
      if (!a.data?.created_at || !b.data?.created_at) {
        return 0;
      }
      return a.data.created_at.localeCompare(b.data.created_at);
    } else {
      return a.data.sort - b.data.sort;
    }
  });
  const keyResults: (StagedKeyResult | KeyResultUpdateWrapper)[] = [];
  keyResultsToUpdate.forEach((keyResult) => {
    keyResults.push(keyResult);
  });
  return keyResults;
};

interface EditGoalContentProps {
  delegateId?: string;
  goalToEdit: Goal;
}

export const EditGoalContent = React.forwardRef<HTMLDivElement, EditGoalContentProps>(
  ({delegateId, goalToEdit}) => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const keyResultIndexRef = useRef<number>(getKeyResultsToUpdate(goalToEdit).length + 1);
    const [goal, setGoal] = useState<GoalUpdate>(getGoalUpdate(goalToEdit));
    const [keyResults, setKeyResults] = useState<(StagedKeyResult | KeyResultUpdateWrapper)[]>(
      getKeyResultsToUpdate(goalToEdit)
    );
    const [keyResultsToBeDeleted, setKeyResultsToBeDeleted] = useState<
      {userId: string; keyResultId: number}[]
    >([]);
    const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);
    const setSnackbarMessage = useSetAtom(snackbarAtom);
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const {createKeyResult, updateKeyResult, deleteKeyResult, deleteDryRunKeyResult} =
      useKeyResultService({timeframe: goal.timeframe});
    const {updateGoal} = useGoalService({timeframe: goal.timeframe});

    // Set initial values for the GoalEditSection coming from alignment
    const [, setInitialSelectedEmployee] = useState<Employee | undefined>(undefined);
    const [, setInitialSelectedKeyResult] = useState<KeyResult | undefined>(undefined);
    const [, setInitialSelectedGoal] = useState<Goal | undefined>(undefined);
    const [, setInitialSelectedGoals] = useState<Goal[] | undefined>(undefined);

    // initial value for strategies
    /*
  const parsedStrategyIds = goalToEdit?.strategy_ids
    ? goalToEdit.strategy_ids.split(',').map(Number)
    : undefined;
  */

    const [isLoading, setIsLoading] = useState(true);

    const [requestStatus, setRequestStatus] = useState('idle');
    const canSave = requestStatus === 'idle';

    const [, setNewGoal] = useAtom(newGoalAtom);

    const navigate = useNavigate();

    const setShowNewBanner = useSetAtom(showNewBannerAtom);

    const [showEditGoalDetailDrawer, setShowEditGoalDetailDrawer] = useState(false);
    const [showCustomPrivacyDrawer, setShowCustomPrivacyDrawer] = useState(false);

    const {data, isLoading: isDelegateLoading} = useProfile({ldap: delegateId});

    const [showParentGoalSearchDrawer, setShowParentGoalSearchDrawer] = useState(false);
    const [parentGoal, setParentGoal] = useState<GoalData>();
    const [parentKeyResult, setParentKeyResult] = useState<KeyResult>();

    const handleOnInputChange = useCallback((idx: number, value: string) => {
      if (idx === 0) {
        setGoal((prev) => ({...prev, title: value}));
        return;
      }

      setKeyResults((prev) =>
        prev.map((entry) =>
          entry.id === idx
            ? 'data' in entry
              ? {...entry, data: {...entry.data, title: value}}
              : {...entry, title: value}
            : entry
        )
      );
    }, []);

    const handleAddContributor = useCallback((idx: number, contributorIds: string[]) => {
      setKeyResults((prev) =>
        prev.map((entry) =>
          entry.id === idx
            ? 'data' in entry
              ? {...entry, data: {...entry.data, contributors: contributorIds}}
              : {...entry, contributors: contributorIds}
            : entry
        )
      );
    }, []);

    const handleOnDeleteKeyResult = useCallback(
      async (idx: number) => {
        // find the key result to delete from the keyResults array by idx
        const keyResult = keyResults.find((entry) => entry.id === idx);
        if (keyResult && 'data' in keyResult) {
          try {
            await deleteDryRunKeyResult({
              userId: keyResult.userId,
              keyResultId: keyResult.keyResultId,
            });
            const updatedKeyResults = keyResults.filter((entry) => entry.id !== idx);
            setKeyResults(updatedKeyResults);
            setKeyResultsToBeDeleted((prev) => [
              ...prev,
              {userId: keyResult.userId, keyResultId: keyResult.keyResultId},
            ]);
          } catch (error: any) {
            if (error.name === 'ApiError' && error.message == 'Bad Request') {
              setSnackbarMessage({text: t('delete_goal_with_children_error')});
            } else {
              setSnackbarMessage({text: t('couldnt_delete')});
            }
          }
        } else {
          const updatedKeyResults = keyResults.filter((entry) => entry.id !== idx);
          setKeyResults(updatedKeyResults);
        }
      },
      [deleteDryRunKeyResult, keyResults, setSnackbarMessage]
    );

    const handleCompleteGoalClickV2 = async () => {
      setShowAIGoalAssistantDrawer(false);
      if (!canSave) return;

      setRequestStatus('pending');
      let parentGoalId: number | undefined = undefined;
      let parentKeyResultId: number | undefined | null = undefined;

      if (parentGoal || parentKeyResult) {
        if (parentKeyResult) {
          parentKeyResultId = parentKeyResult.id;
        } else if (parentGoal) {
          parentGoalId = parentGoal.id;
        }
      } else if (!parentGoal && !parentKeyResult) {
        parentGoalId = undefined;
        parentKeyResultId = undefined;
      } else {
        parentGoalId = goalToEdit?.parent?.id;
        parentKeyResultId = goalToEdit?.key_result_parent_id;
      }

      try {
        await updateGoal({
          userId: goalToEdit.users![0].user_id,
          goalId: goalToEdit.id,
          data: {
            ...goal,
            parent_id: parentGoalId,
            key_result_parent_id: parentKeyResultId,
            updated_at: new Date().toISOString(),
          },
        });

        if (keyResults.length > 0) {
          await updateOrAddKeyResults();
        }

        if (keyResultsToBeDeleted.length > 0) {
          await deleteKeyResults();
        }

        setNewGoal(RESET);
        setShowNewBanner((prev) => ({...prev, [goalToEdit.id]: false}));
        navigate(`/goals/${goalToEdit.id}`, {state: {source: 'edit'}});
      } catch (error) {
        reportAndLogError(error, 'Error saving goal');
      } finally {
        setRequestStatus('idle');
        setSnackbarMessage({
          text: t('saved'),
          action: {
            text: t('add_another_goal'),
            onClick: () => {
              navigate(ROUTE_PATHS.GOALS_V2_ONBOARDING);
            },
          },
        });
      }
    };

    const deleteKeyResults = async () => {
      try {
        const results = keyResultsToBeDeleted.map((entry) => {
          deleteKeyResult({
            userId: entry.userId,
            keyResultId: entry.keyResultId,
          });
        });
        await Promise.all(results);
      } catch (error) {
        throw new Error('Error deleting key results');
      }
    };

    const updateOrAddKeyResults = async () => {
      try {
        const results = keyResults.map((entry, idx) => {
          if ('data' in entry) {
            updateKeyResult({
              keyResultId: entry.keyResultId,
              userId: goalToEdit.users![0].user_id,
              data: {
                title: entry.data.title,
                contributors: entry.data.contributors,
                created_at: entry.data.created_at,
                goal_id: entry.data.goal_id,
                sort: idx,
                updated_at: new Date().toISOString(),
              },
            });
          } else {
            // filter out key results that have no title
            if (!entry.title || entry.title === '') {
              return;
            }
            // update created_at to current time + delay
            const baseTime = new Date();
            const newTimestamp = new Date(baseTime.getTime() + idx * 1000).toISOString();

            createKeyResult({
              userId: goalToEdit.users![0].user_id,
              goalId: goalToEdit.id,
              data: {
                ...entry,
                created_at: newTimestamp,
                updated_at: newTimestamp,
              },
            });
          }
        });

        await Promise.all(results);
      } catch (error) {
        throw new Error('Error updating key results');
      }
    };

    const fetchGoalAndEmployeeDetails = async (goalId: number) => {
      try {
        const goalResponse = await getGoalService().readGoalByIdApiV1GoalsGoalIdGet(goalId);
        setInitialSelectedGoal(goalResponse);
        setParentGoal(goalResponse);
        if (goalResponse.users && goalResponse.users.length > 0) {
          const ldap = goalResponse.users[0].email.split('@')[0];
          const employee = await getEmployeeService().getEmployeeByLdapApiV1PeopleLdapLdapGet(ldap);
          setInitialSelectedEmployee(employee);
          const goalsResult = await getGoalService().readGoalsByLdapIdApiV1GoalsUsersLdapGet(ldap);
          setInitialSelectedGoals(goalsResult.goals);
        }
      } catch (error) {
        reportAndLogError(error, 'Error fetching goal and employee details');
      }
    };

    const handleAddKeyResultClick = () => {
      // Create a new keyResult object

      const newKeyResult: StagedKeyResult = {
        id: keyResultIndexRef.current,
        title: '',
        sort: keyResultIndexRef.current,
        created_at: new Date().toISOString(),
      };
      keyResultIndexRef.current += 1;
      setKeyResults((prevKeyResults) => [...prevKeyResults, newKeyResult]);
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, 100);
    };

    const disableDeleteKeyResult = (idx: number) => {
      const remainingKeyResults = keyResults.filter((entry) => entry.id !== idx);
      const remainingKeyResultsTitles = remainingKeyResults.map((entry) =>
        'data' in entry ? entry.data.title : entry.title
      );
      const currentKeyResult = keyResults.find((entry) => entry.id === idx);
      const currentKeyResultTitle = currentKeyResult
        ? 'data' in currentKeyResult
          ? currentKeyResult.data.title
          : currentKeyResult.title
        : '';
      return (
        currentKeyResultTitle !== '' && remainingKeyResultsTitles.every((title) => title === '')
      );
    };

    const disableSave = () => {
      // Disable save if goal title is empty
      if (!goal.title || goal.title === '') {
        return true;
      }
      // Disable save if key results title are empty
      const keyResultTitles = keyResults.map((entry) =>
        'data' in entry ? entry.data.title : entry.title
      );
      return keyResultTitles.every((title) => title === '');
    };

    const handleTimeframeSelection = (timeframe: string) => {
      setGoal((prev) => ({...prev, timeframe}));
    };

    const handleVisibilitySelection = (selected: number) => {
      if (selected == 0) {
        setGoal((prev) => ({...prev, private: false, is_custom_privacy_included: false}));
      }
      if (selected == 1) {
        setGoal((prev) => ({...prev, private: true, is_custom_privacy_included: false}));
      }
      if (selected == 2) {
        setGoal((prev) => ({...prev, private: false, is_custom_privacy_included: true}));
        setShowCustomPrivacyDrawer(true);
      }
    };

    const handleCustomPrivacySaved = (employeesOrTeams: (Employee | Team)[]) => {
      const employees = employeesOrTeams.filter(isEmployee);
      const teams = employeesOrTeams.filter(isTeam);
      setGoal((prev) => ({...prev, individual_privacies: employees, team_privacies: teams}));
      setShowCustomPrivacyDrawer(false);
    };

    /*
  const handleStrategiesSelection = (strategyIds: string) => {
    setGoal((prev) => ({...prev, strategy_ids: strategyIds}));
  };
  */

    const handleCancelClick = () => {
      setNewGoal(RESET);
      setShowAIGoalAssistantDrawer(false);
      navigate(-1);
    };

    function handleDragEnd(event: DragEndEvent) {
      const {active, over} = event;

      if (active.id !== over?.id) {
        setKeyResults((items) => {
          const oldIndex = items.findIndex((entry) => entry.id === active.id);
          const newIndex = items.findIndex((entry) => entry.id === over?.id);

          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }

    useEffect(() => {
      let isMounted = true;
      const fetchKeyResultAndDetails = async () => {
        const keyResultId = goalToEdit?.key_result_parent_id;

        // Only proceed if keyResultId is defined
        if (keyResultId) {
          try {
            const keyResult =
              await getKeyResultService().readKeyResultApiV1KeyResultsKeyResultIdGet(keyResultId);
            if (isMounted) {
              setInitialSelectedKeyResult(keyResult);
              setParentKeyResult(keyResult);
              await fetchGoalAndEmployeeDetails(keyResult.goal_id);
            }
          } catch (error) {
            reportAndLogError(error, 'Error fetching key result');
          } finally {
            if (isMounted) {
              setIsLoading(false);
            }
          }
        } else if (goalToEdit.parent?.id) {
          await fetchGoalAndEmployeeDetails(goalToEdit.parent?.id);
          if (isMounted) {
            setIsLoading(false);
          }
        } else {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      };

      fetchKeyResultAndDetails();

      return () => {
        isMounted = false;
      };
    }, [goalToEdit?.key_result_parent_id, goalToEdit.parent?.id, goalToEdit.id]);

    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        const header = document.querySelector('.dig-GlobalHeader');
        const drawer = document.querySelector('.dig-Drawer');
        const toolTipOveraly = document.querySelector('.dig-Toggletip-backdrop');
        const toopTipInner = document.querySelector('.dig-Tooltip-inner');

        if (
          (!header || !header.contains(event.target as Node)) &&
          (!drawer || !drawer.contains(event.target as Node)) &&
          (!toolTipOveraly || !toolTipOveraly.contains(event.target as Node)) &&
          (!toopTipInner || !toopTipInner.contains(event.target as Node)) &&
          shouldCloseAndResetDrawers(event.target as HTMLElement)
        ) {
          setShowCustomPrivacyDrawer(false);
          setShowEditGoalDetailDrawer(false);
          setShowParentGoalSearchDrawer(false);
        }
      };

      document.body.addEventListener('click', handleClick);

      return () => {
        document.body.removeEventListener('click', handleClick);
      };
    }, []);

    if (isLoading || isDelegateLoading) {
      return <SkeletonEditGoal />;
    }

    const renderKeyResults = () => {
      return (
        <div className={styles.keyResultContainer} ref={bottomRef}>
          <GoalEditSectionHeader subtitle={t('key_results')} tooltip={t('key_result_tooltip')} />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToFirstScrollableAncestor]}
          >
            <SortableContext items={keyResults} strategy={verticalListSortingStrategy}>
              {keyResults.map((keyResultEntry) => {
                return (
                  <Box style={{marginBottom: -24}} key={keyResultEntry.id} width="100%">
                    <FormRow>
                      <KeyResultEditSection
                        source="edit"
                        // goalId={goalToEdit.id}
                        idx={keyResultEntry.id}
                        keyResultCreateValues={
                          'data' in keyResultEntry ? keyResultEntry.data : keyResultEntry
                        }
                        // timeframe={goalToEdit.timeframe}
                        handleOnInputChange={handleOnInputChange}
                        handleAddContributor={handleAddContributor}
                        handleOnDelete={handleOnDeleteKeyResult}
                        delegateId={delegateId}
                        isEdit
                        disableDelete={disableDeleteKeyResult(keyResultEntry.id)}
                      />
                    </FormRow>
                  </Box>
                );
              })}
            </SortableContext>
          </DndContext>
          <IconButton
            variant="outline"
            shape="circular"
            size="small"
            onClick={handleAddKeyResultClick}
          >
            <Box as={UIIcon} src={AddLine} color="Border Base" />
          </IconButton>
        </div>
      );
    };

    return (
      <>
        <Box style={{gap: '24px'}} display="flex" flexDirection="column">
          <ParentGoalSection
            isDrawerOpen={showParentGoalSearchDrawer}
            onEditClick={() => {
              setShowParentGoalSearchDrawer(true);
              setShowAIGoalAssistantDrawer(false);
            }}
            onDeleteClick={() => {
              setParentGoal(undefined);
              setParentKeyResult(undefined);
            }}
            parentGoal={parentGoal}
            parentKeyResult={parentKeyResult}
          />
          <Box style={{marginBottom: '-20px'}}>
            <GoalEditSection
              idx={0}
              goalCreated={false}
              goalCreateValues={goal}
              handleOnInputChange={handleOnInputChange}
              isEdit={true}
            />
          </Box>
          {renderKeyResults()}
          <GoalDetailsSection
            onEditClick={() => {
              setShowEditGoalDetailDrawer(true);
              setShowAIGoalAssistantDrawer(false);
            }}
            goalCreateValues={goal}
            isEditClicked={showEditGoalDetailDrawer}
            delegatesProfilePage={data}
          />
          <EditGoalDetailsDrawer
            isOpen={showEditGoalDetailDrawer}
            onClose={() => setShowEditGoalDetailDrawer(false)}
            goalCreateValues={goal}
            handleTimeframeSelection={handleTimeframeSelection}
            handleVisibilitySelection={handleVisibilitySelection}
            delegateProfilePage={data}
          />
          {showCustomPrivacyDrawer && (
            <EditGoalPrivacyDrawer
              isOpen={showCustomPrivacyDrawer}
              onClose={() => setShowCustomPrivacyDrawer(false)}
              onBack={() => setShowCustomPrivacyDrawer(false)}
              onCustomPrivacySave={handleCustomPrivacySaved}
              delegateId={delegateId}
              contributorLdaps={
                !keyResults.length
                  ? []
                  : 'data' in keyResults[0]
                  ? (keyResults as KeyResultUpdateWrapper[]).flatMap(
                      (entry) => entry?.data?.contributors ?? []
                    )
                  : (keyResults as StagedKeyResult[]).flatMap((entry) => entry.contributors ?? [])
              }
              goalCreateValues={goal}
              delegateProfilePage={data}
            />
          )}
          <ParentGoalSearchDrawer
            isOpen={showParentGoalSearchDrawer}
            onClose={() => setShowParentGoalSearchDrawer(false)}
            setParentGoal={setParentGoal}
            setParentKeyResult={setParentKeyResult}
            selectedParentGoal={parentGoal}
          />
          <EditSaveButtons
            cta={t('save_goal')}
            disableSave={disableSave()}
            isLoading={requestStatus === 'pending'}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleCompleteGoalClickV2}
          />
        </Box>
      </>
    );
  }
);

EditGoalContent.displayName = 'EditGoalContent';
