import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {ProjectService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {TimeAgo} from 'components/shared/TimeAgo';
import {getService} from 'utilities';

export const ProjectDigest = () => {
  const {isLoading, data} = useQuery({
    queryKey: ['digest'],
    queryFn: getService(ProjectService).getDigestApiV1DigestProjectsGet,
  });

  const projectsGroupedByTeam = data?.digests.reduce(
    (acc, project) => {
      const team = project.project.team?.name ?? '';

      if (!acc[team]) {
        // eslint-disable-next-line no-param-reassign
        acc[team] = [];
      }

      acc[team].push(project);

      return acc;
    },
    {} as Record<string, any[]>
  );

  if (isLoading) {
    return <Layout.Container>Loading...</Layout.Container>;
  }

  return (
    <Layout.Container>
      <Stack gap="16">
        <Text>Teams: {data?.teams.map((project) => project.name).join(', ')}</Text>
        <Text>Projects: {data?.projects.map((project) => project.title).join(', ')}</Text>
        {Object.keys(projectsGroupedByTeam ?? [])?.map((team) => (
          <Stack key={team} gap="8">
            <Title>{team}</Title>
            {projectsGroupedByTeam![team].map(({project, updates}) => (
              <Stack key={project.id}>
                <Title size="small">{project.title}</Title>
                {updates.map((update: any) => (
                  <Box as={Text} key={update.id} display="block">
                    {update.comment} <TimeAgo timestamp={update.created_at} />
                  </Box>
                ))}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </Layout.Container>
  );
};
