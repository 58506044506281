import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  BriefcaseLine,
  GratitudeLine,
  LightbulbSpot,
  NewsLine,
  PersonMultipleLine,
  PulseLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {growthbookCacheAtom} from 'atoms/layout';
import cx from 'classnames';
import {Link} from 'components/DSYS/Link';
import {ROUTE_PATHS} from 'constant';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useLocation} from 'react-router-dom';

import styles from './LeftNav.module.css';

export const LeftNav = () => {
  const {isNewsEnabled, isProjectsEnabled, isPulseEnabled} = useAtomValue(growthbookCacheAtom);

  const navItems = [
    {
      text: t('goals'),
      path: ROUTE_PATHS.GOALS,
      icon: TargetLine,
      state: {resetView: true},
    },
    {
      text: t('people'),
      path: ROUTE_PATHS.PEOPLE,
      icon: PersonMultipleLine,
    },
    {
      text: t('teams'),
      path: ROUTE_PATHS.TEAMS,
      icon: TeamLine,
    },
    {
      text: t('news'),
      path: ROUTE_PATHS.NEWS,
      icon: NewsLine,
      hidden: !isNewsEnabled,
    },
    {
      text: t('projects'),
      path: ROUTE_PATHS.PROJECTS,
      icon: BriefcaseLine,
      hidden: !isProjectsEnabled,
    },
    {
      text: t('pulse'),
      path: ROUTE_PATHS.PULSES,
      icon: PulseLine,
      hidden: !isPulseEnabled,
    },
    {
      text: t('strategy_plural'),
      path: ROUTE_PATHS.STRATEGIES,
      icon: LightbulbSpot,
    },
    {
      text: t('gratitude'),
      path: ROUTE_PATHS.GRATITUDE_BROWSE,
      icon: GratitudeLine,
    },
  ].filter((item) => !item.hidden);

  return (
    <div className={styles.collapsedLeftNav}>
      <Stack gap="8">
        {navItems.map((props, key) => (
          <LinkItem key={key} {...props} />
        ))}
      </Stack>
    </div>
  );
};

const LinkItem = ({
  onClick,
  text,
  path,
  icon,

  state,
}: {
  text: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  onClick?: () => void;
  path?: string;

  extra?: React.ReactNode;
  state?: {[key: string]: any};
}) => {
  const location = useLocation();

  return (
    <Tooltip title={text} placement="right">
      <div>
        <Link
          to={path ?? location.pathname}
          onClick={onClick}
          state={{source: 'left-nav', ...state}}
          hasNoUnderline
        >
          <IconButton
            variant="transparent"
            shape="circular"
            tabIndex={-1}
            className={cx(styles.itemLink, {
              [styles.activeItemLink]: path && location.pathname.startsWith(path),
            })}
          >
            <UIIcon src={icon} className={styles.itemIcon} />
          </IconButton>
        </Link>
      </div>
    </Tooltip>
  );
};
