import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine, PersonMultipleLine, SearchLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import {sortEmployeesSelfFirst} from 'helpers/utils';
import {useEmployeesNoSuspense, useSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import React from 'react';
import {useEffect, useState} from 'react';
import {ContributorsDrawerHeader} from 'views/goals_v2/Drawers/ContributorsDrawer';
import {DrawerContainer, DrawerOverlay} from 'views/goals_v2/Drawers/DrawerOverlay';

const renderTypeaheadRow = (employee: Employee) => {
  return (
    <Typeahead.Row
      key={employee.user_id}
      value={employee.ldap}
      withTitle={<Text isBold>{employee.name}</Text>}
      withSubtitle={
        <Text size="small" color="faint">
          {employee.role}
        </Text>
      }
      withLeftAccessory={<Avatar user={employee} />}
    />
  );
};

const EmployeeSearch = ({
  autoFocus = true,
  selections,
  onSelection,
}: {
  autoFocus?: boolean;
  selections: string[];
  onSelection: (employee: Employee) => void;
}) => {
  const {directReports} = useAtomValue(loggedInEmployeeAtom);
  const [userInputValue, setUserInputValue] = useState('');
  const searchResult = useSearch({input: userInputValue});

  const employeeHints = searchResult.length ? searchResult : directReports;

  const filteredEmployeeHints = employeeHints.filter(
    (employee) => !selections.find((email) => email === employee.email)
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (ldap: string) => {
    const selectedEmployee = employeeHints.find((e) => e.ldap === ldap);
    if (selectedEmployee) {
      onSelection(selectedEmployee);
      setUserInputValue('');
    }
  };

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <FormRow>
            <TextInput
              autoFocus={autoFocus}
              size="large"
              placeholder={t('search_dropboxers')}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck={false}
              value={userInputValue}
              withRightAccessory={<Box as={UIIcon} src={SearchLine} color="Text Subtle" />}
              {...getTriggerProps({onChange: onInputChange})}
            />
          </FormRow>

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={50}
              results={filteredEmployeeHints}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

export const ContributorsBody = ({
  isOpen,
  contributorLdaps,
  contributors,
  ownerLdap,
  isOwner,
  autoFocus = true,
  onContributorsChanged,
  handleContribute,
  setContributors,
  setChanged,
}: {
  isOpen: boolean;
  ownerLdap: string;
  autoFocus?: boolean;
  contributorLdaps: string[];
  contributors: Employee[];
  onContributorsChanged?: (contributorLdaps: string[]) => void;
  isOwner?: boolean;
  handleContribute?: (join: boolean, employeeId: string) => void;
  setContributors: (contributors: Employee[]) => void;
  setChanged?: (changed: boolean) => void;
}) => {
  const {employee, delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const employeeLdaps = [ownerLdap, ...contributorLdaps];
  const [owner, ...employees] = useEmployeesNoSuspense({ldaps: employeeLdaps});

  useEffect(() => {
    setContributors(employees);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, JSON.stringify(employeeLdaps), setContributors]);

  return (
    <Stack>
      {isOwner && owner?.email && (
        <EmployeeSearch
          autoFocus={autoFocus}
          selections={[owner.email, ...contributors.map((c) => c.email ?? '')]}
          onSelection={(selectedEmployee) => {
            if (
              owner.email === selectedEmployee.email ||
              contributors.find((c) => c.user_id === selectedEmployee.user_id)
            ) {
              return;
            }

            setChanged?.(true);
            setContributors([...contributors, selectedEmployee]);
            onContributorsChanged?.([...contributors, selectedEmployee].map(({ldap}) => ldap));
          }}
        />
      )}

      <Box>
        {contributors?.sort(sortEmployeesSelfFirst(employee)).map((contributor) => (
          <Box as={Split} key={contributor.user_id} padding="12" paddingLeft="0" alignY="center">
            <Split.Item width="fill">
              <LabelGroup
                withLeftAccessory={
                  <Avatar user={contributor} isInactive={contributor.is_deactivated ?? false} />
                }
                withText={contributor.name}
                withSubtext={contributor.role}
              />
            </Split.Item>
            {(isOwner ||
              employee.ldap === contributor.ldap ||
              delegatedBy?.some(({ldap}) => ldap === contributor.ldap)) && (
              <Split.Item>
                <IconButton
                  variant="transparent"
                  onClick={() => {
                    const newContributors = contributors.filter(
                      (c) => c.user_id !== contributor.user_id
                    );
                    setContributors(newContributors);
                    setChanged?.(true);

                    if (
                      employee.ldap === contributor.ldap ||
                      delegatedBy?.some(({ldap}) => ldap === contributor.ldap)
                    ) {
                      handleContribute?.(false, contributor.user_id);
                      return;
                    }
                    onContributorsChanged?.(newContributors.map(({ldap}) => ldap));
                  }}
                >
                  <UIIcon src={DeleteLine} style={{color: 'var(--dig-color__border__base)'}} />
                </IconButton>
              </Split.Item>
            )}
          </Box>
        ))}

        {owner && (
          <Box as={Split} padding="12" paddingLeft="0" alignY="center">
            <Split.Item width="fill">
              <LabelGroup
                withLeftAccessory={
                  <Avatar user={owner} isInactive={owner.is_deactivated ?? false} />
                }
                withText={owner.name}
                withSubtext={owner.role}
              />
            </Split.Item>
            <Split.Item>
              <Text color="faint" size="small">
                {t('author')}
              </Text>
            </Split.Item>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

interface ContributorsProps {
  isOpen: boolean;
  source: string;
  ownerLdap: string;
  contributorLdaps: string[];
  handleDelete?: (contributorLdaps: string[]) => void;
  handleClose: () => void;
  isOwner?: boolean;
  handleContribute?: (join: boolean, employeeId: string) => void;
  handleContributorsChanged?: (contributors: string[]) => void;
}

export const Contributors = React.forwardRef<HTMLDivElement, ContributorsProps>(
  ({
    isOpen,
    source,
    ownerLdap,
    contributorLdaps,
    handleDelete,
    handleClose,
    isOwner,
    handleContribute,
    handleContributorsChanged,
  }) => {
    const {employee, delegatedBy} = useAtomValue(loggedInEmployeeAtom);
    const [contributors, setContributors] = useState<Employee[]>([]);
    const [joinAs, setJoinAs] = useState<string>();
    const showJoinButtons = Boolean(
      !contributorLdaps.includes(employee.ldap) ||
        !delegatedBy?.some(({ldap}) => contributorLdaps.includes(ldap))
    );
    const [changed, setChanged] = useState(false);

    useEffect(() => {
      if (isOpen) {
        analyticsLogger().logEvent('CONTRIBUTORS_SHOWN', {
          source,
          type: showJoinButtons ? 'join' : 'owner',
        });
      }
    }, [source, showJoinButtons, isOpen, isOwner]);

    useEffect(() => {
      if (!joinAs && delegatedBy && employee?.ldap) {
        const foo = [employee, ...delegatedBy].filter(
          ({ldap}) => ![ownerLdap, ...contributorLdaps].includes(ldap)
        );
        if (foo?.length) {
          setJoinAs(foo[0].user_id);
        }
      }
    }, [delegatedBy, employee, ownerLdap, contributorLdaps, joinAs]);

    const onClose = () => {
      if (isOwner && changed) {
        analyticsLogger().logEvent('CONTRIBUTORS_CHANGED', {
          source,
          type: 'owner',
        });
        if (source === 'kr-card') {
          handleContributorsChanged?.(contributors.map((c) => c.ldap));
        }
      }
      handleClose();
    };

    const onHandleContribute = (join: boolean, employeeId: string) => {
      analyticsLogger().logEvent('CONTRIBUTORS_CHANGED', {
        source,
        type: 'join',
      });

      handleContribute?.(join, employeeId);
    };

    const renderDrawer = () => {
      return (
        <DrawerOverlay isOpen={isOpen}>
          <DrawerContainer
            isOpen={isOpen}
            header={<ContributorsDrawerHeader onClose={onClose} />}
            body={
              <ContributorsBody
                isOpen={isOpen}
                ownerLdap={ownerLdap}
                contributorLdaps={contributorLdaps}
                contributors={contributors}
                handleContribute={onHandleContribute}
                onContributorsChanged={undefined}
                isOwner={isOwner}
                setChanged={setChanged}
                setContributors={setContributors}
              />
            }
            footer={
              <Split gap="8" alignX="right">
                <Split.Item>
                  <Button variant="opacity" onClick={onClose}>
                    {t('cancel')}
                  </Button>
                </Split.Item>
                <Split.Item>
                  {!isOwner && showJoinButtons ? (
                    <Button
                      variant="primary"
                      onClick={() => onHandleContribute?.(true, joinAs ?? employee.user_id)}
                    >
                      {t('join')}
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleContributorsChanged?.(contributors.map((c) => c.ldap));
                        onClose();
                      }}
                      disabled={!changed}
                    >
                      {t('save')}
                    </Button>
                  )}
                </Split.Item>
              </Split>
            }
          />
        </DrawerOverlay>
      );
    };

    // TODO: Remove KeyResultCard after we migrate draft goal details page to drawer
    if (source !== 'kr-card') {
      return renderDrawer();
    }

    return (
      <Modal open={isOpen} onRequestClose={onClose} withCloseButton="Close">
        <Box style={{minHeight: 800}} paddingBottom={isOwner || !showJoinButtons ? '36' : '0'}>
          <Modal.Header>
            <Modal.Title>
              <Box display="flex" alignItems="center">
                <Box as={UIIcon} src={PersonMultipleLine} marginRight="8" />
                {t('owners')}
              </Box>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ContributorsBody
              isOpen={isOpen}
              ownerLdap={ownerLdap}
              contributorLdaps={contributorLdaps}
              contributors={contributors}
              handleContribute={onHandleContribute}
              onContributorsChanged={handleDelete}
              isOwner={isOwner}
              setChanged={setChanged}
              setContributors={setContributors}
            />
          </Modal.Body>
        </Box>
        {!isOwner && showJoinButtons && (
          <Modal.Footer>
            {delegatedBy && (
              <Box style={{marginRight: 'auto'}}>
                <DelegateSelectMenu
                  filterLdaps={[ownerLdap, ...contributorLdaps]}
                  delegateId={joinAs}
                  setDelegateId={setJoinAs}
                />
              </Box>
            )}
            <Button variant="opacity" onClick={handleClose}>
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => onHandleContribute?.(true, joinAs ?? employee.user_id)}
            >
              {t('join')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
);

Contributors.displayName = 'Contributors';
