import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine, LockLine, SearchLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {derivedShowAIGoalAssistantDrawerAtom, newGoalAtom} from 'atoms/goals';
import {Employee, Goal, KeyResult} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {GoalEditSectionHeader} from 'components/goals/edit/GoalEditSection';
import GoalAccordion from 'components/shared/GoalAccordion';
import {getNextQuarter} from 'components/shared/TimeAgo';
import {DEFAULT_TIMEFRAME, TIMEFRAME_OPTIONS} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {isGoalPrivate} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {RESET} from 'jotai/utils';
import {useEffect, useMemo, useState} from 'react';
import {getEmployeeService, getGoalService} from 'utilities';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';
import {groupGoalsByLdap} from 'views/goals_v2/utils';

import styles from './GoalSearchMenu.module.css';

export const GoalSearchMenu = ({
  delegateId,
  goalCreated,
  initialSelectedEmployee,
  initialSelectedGoal,
  initialSelectedKeyResult,
  initialSelectedGoals,
  setOffset,
  autoFocus = true,
}: {
  goalCreated: boolean;
  delegateId?: string;
  initialSelectedEmployee?: Employee;
  initialSelectedGoal?: Goal;
  initialSelectedKeyResult?: KeyResult;
  initialSelectedGoals?: Goal[];
  setOffset?: (offset: number) => void;
  autoFocus?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const {reportingLine} = useAtomValue(loggedInEmployeeAtom);
  const [userInputValue, setUserInputValue] = useState<string>('');
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(
    initialSelectedEmployee
  );
  const [selectedGoal, setSelectedGoal] = useState<Goal | undefined>(initialSelectedGoal);
  const [selectedKeyResult, setSelectedKeyResult] = useState<KeyResult | undefined>(
    initialSelectedKeyResult
  );

  const user = useAtomValue(pulseUserAtom);
  const setNewGoal = useSetAtom(newGoalAtom);
  const [employeeHints, setEmployeeHints] = useState<Employee[]>(
    reportingLine ? reportingLine.filter((e) => e.ldap != user?.email.split('@')[0]) : []
  );
  const [selectedGoals, setSelectedGoals] = useState<Goal[]>(initialSelectedGoals ?? []);
  const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);

  const handleSelection = (ldap: string) => {
    const cur = employeeHints.find((e) => e.ldap === ldap);
    setSelectedEmployee(cur);
    setShowAIGoalAssistantDrawer(false);
    if (ldap) {
      setNewGoal({
        isNewParentGoalSelected: true,
        selectedParentGoalId: undefined,
        selectedParentKeyResultId: undefined,
        showAlignment: true,
        showSetPersonalGoalButton: false,
      });
    } else {
      setSelectedGoal(undefined);
      setSelectedKeyResult(undefined);
      setSelectedGoals([]);
      setNewGoal({
        isNewParentGoalSelected: true,
        selectedParentGoalId: undefined,
        selectedParentKeyResultId: undefined,
        showAlignment: true,
        showSetPersonalGoalButton: true,
      });
    }
    if (ldap) {
      getGoalService()
        .readGoalsByLdapIdApiV1GoalsUsersLdapGet(ldap)
        .then((res) => {
          setSelectedGoal(undefined);
          setSelectedKeyResult(undefined);
          setSelectedGoals(res.goals || []);
        })
        .catch(() => {
          setSelectedGoals([]);
          //todo: log not found event
        });
    }
  };

  const handleToggle = ({isOpen}: {isOpen: boolean}) => {
    setOpen(isOpen);
    if (isOpen) {
      setTimeout(() => {
        const height = document.querySelector('.dig-Typeahead-container')?.clientHeight ?? 0;
        setOffset?.(height);
      }, 0);
    } else {
      setOffset?.(0);
      setEmployeeHints(
        reportingLine ? reportingLine.filter((e) => e.ldap != user?.email.split('@')[0]) : []
      );
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);

    if (open) {
      const height = document.querySelector('.dig-Typeahead-container')?.clientHeight ?? 0;
      setOffset?.(height);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const height = document.querySelector('.dig-Typeahead-container')?.clientHeight ?? 0;
      setOffset?.(height);
    }, 800);
  }, [setOffset]);

  const renderTypeaheadRow = (employee: Employee) => {
    return (
      <Typeahead.Row
        key={employee.user_id}
        className={styles.typeaheadRow}
        value={employee.ldap}
        withTitle={<Text isBold>{employee.name}</Text>}
        withSubtitle={
          <Text size="small" color="faint">
            {employee.role}
          </Text>
        }
        withLeftAccessory={<Avatar user={employee} />}
      />
    );
  };

  useEffect(() => {
    if (selectedEmployee?.user_id === delegateId) {
      setSelectedEmployee(undefined);
    }
    setEmployeeHints(
      (delegateId
        ? reportingLine?.filter((e) => e.user_id !== delegateId)
        : reportingLine?.filter((e) => e.ldap != user?.email.split('@')[0])) ?? []
    );
  }, [delegateId, reportingLine, selectedEmployee?.user_id, user?.email]);

  useEffect(() => {
    if (userInputValue.length && user) {
      getEmployeeService()
        .searchPeopleApiV1PeopleSearchGet(userInputValue)
        .then((res) => {
          setEmployeeHints(
            (delegateId
              ? res?.filter((e) => e.user_id !== delegateId)
              : res?.filter((e) => e.ldap != user?.email.split('@')[0])) ?? []
          );
        })
        .catch((err) => {
          reportAndLogError(err, 'Failed to search people');
        });
    }
  }, [userInputValue, user, delegateId]);

  useEffect(() => {
    if (userInputValue.length && user) {
      getEmployeeService()
        .searchPeopleApiV1PeopleSearchGet(userInputValue)
        .then((res) => {
          setEmployeeHints(res.filter((e) => e.ldap !== user.email.split('@')[0]));
        })
        .catch((err) => {
          reportAndLogError(err, 'Failed to search people');
        });
    }
  }, [userInputValue, user]);

  if (selectedEmployee) {
    return (
      <>
        <GoalSearchSelectedComponent
          goalCreated={goalCreated}
          value={selectedEmployee.name}
          selectedEmployee={selectedEmployee}
          handleSelection={handleSelection}
        />
        <GoalSearchResults
          goalCreated={goalCreated}
          goals={selectedGoals.filter((goal) => goal.users?.[0].email === selectedEmployee.email)}
          handleEmployeeSelection={handleSelection}
          selectedGoal={selectedGoal}
          selectedKeyResult={selectedKeyResult}
          setSelectedGoal={setSelectedGoal}
          setSelectedKeyResult={setSelectedKeyResult}
          employeeLdap={selectedEmployee.ldap}
        />
      </>
    );
  }

  return (
    <Typeahead.Wrapper onSelection={handleSelection} hasMaxHeight={520} onToggle={handleToggle}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            placeholder={t('search_dropboxers').toString()}
            autoFocus={autoFocus}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            value={userInputValue}
            withRightAccessory={<Box as={UIIcon} src={SearchLine} color="Text Subtle" />}
            {...getTriggerProps({onChange: onInputChange})}
          />

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={50}
              results={employeeHints}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

const GoalSearchSelectedComponent = ({
  value,
  selectedEmployee,
  selectedGoal,
  selectedKeyResult,
  handleSelection,
  goalCreated,
}: {
  goalCreated: boolean;
  value?: string;
  selectedEmployee?: Employee;
  selectedGoal?: Goal | null;
  selectedKeyResult?: KeyResult | null;
  handleSelection: (value: string) => void;
}) => {
  const renderIcon = () => {
    if (selectedEmployee) {
      return <Avatar user={selectedEmployee} />;
    }

    if (selectedKeyResult) {
      return <SubtleKeyResultIcon />;
    }
    return <SubtleObjectiveIcon />;
  };

  const isPrivate = selectedGoal && isGoalPrivate(selectedGoal);
  return (
    <div className={styles.searchMenuSelectedContainer}>
      <div className={styles.selectedComponentContent}>
        {renderIcon()}

        <div className={styles.selectedComponentText}>
          <Text className={styles.title} isBold>
            {value}
          </Text>

          {selectedEmployee ? (
            <Text size="small" color="faint">
              {selectedEmployee.role}
            </Text>
          ) : (
            <div>
              <Text size="small" color="faint">
                {selectedGoal?.timeframe}
              </Text>
              {selectedGoal && !selectedKeyResult && (
                <>
                  {' · '}
                  <Text size="small" color="faint">
                    {t('key_result', {count: selectedGoal.key_results.length})}
                    {isPrivate && (
                      <Box display="inline-flex" alignItems="center" marginLeft="8">
                        •{<Box as={UIIcon} marginLeft="4" src={LockLine} size="small" />}
                        {t('private')}
                      </Box>
                    )}
                  </Text>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!goalCreated && (
        <IconButton
          variant="opacity"
          size="medium"
          shape="circular"
          onClick={() => handleSelection('')}
        >
          <UIIcon src={CloseLine} color="#736C64" />
        </IconButton>
      )}
    </div>
  );
};

export const groupGoalsByTimeframe = (goals: Goal[]) => {
  const groupedGoals: {[timeframe: string]: Goal[]} = {};
  goals.forEach((goal) => {
    if (!groupedGoals[goal.timeframe]) {
      groupedGoals[goal.timeframe] = [];
    }
    groupedGoals[goal.timeframe].push(goal);
  });
  const currentQuarter = DEFAULT_TIMEFRAME;
  const nextQuarter = getNextQuarter(currentQuarter);
  // only keep current quarter and next quarter goals in the groupedGoals
  Object.keys(groupedGoals).forEach((timeframe) => {
    if (timeframe !== currentQuarter && timeframe !== nextQuarter) {
      delete groupedGoals[timeframe];
    }
  });
  return groupedGoals;
};

const GoalSearchResults = ({
  goals,
  goalCreated,
  handleEmployeeSelection,
  selectedGoal,
  selectedKeyResult,
  setSelectedGoal,
  setSelectedKeyResult,
  employeeLdap,
}: {
  goals: Goal[];
  goalCreated: boolean;
  handleEmployeeSelection: (value: string) => void;
  selectedGoal?: Goal;
  selectedKeyResult?: KeyResult;
  setSelectedGoal: (goal: Goal | undefined) => void;
  setSelectedKeyResult: (keyResult: KeyResult | undefined) => void;
  employeeLdap: string;
}) => {
  const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);
  const setNewGoal = useSetAtom(newGoalAtom);

  const groupGoalsByEmployeeLdap = useMemo(
    () => groupGoalsByLdap(goals, [employeeLdap], true),
    [goals, employeeLdap]
  );
  const sortedGoals = groupGoalsByEmployeeLdap[employeeLdap];
  const groupedGoals = groupGoalsByTimeframe(sortedGoals);
  const sortedTimeframes = Object.keys(groupedGoals).sort((a, b) => {
    return Object.keys(TIMEFRAME_OPTIONS).indexOf(b) - Object.keys(TIMEFRAME_OPTIONS).indexOf(a);
  });

  // check if there are no grouped goals
  const isGroupedGoalsEmpty = Object.values(groupedGoals).every(
    (goals_entry) => goals_entry.length === 0
  );

  const handleGoalSelected = (goal: Goal) => {
    setSelectedGoal(goal);
    setNewGoal({
      isNewParentGoalSelected: true,
      selectedParentGoalId: goal.id,
      selectedParentKeyResultId: undefined,
      showAlignment: true,
      showSetPersonalGoalButton: false,
    });
  };

  const handleKeyResultSelected = (parentGoal: Goal, keyResult: KeyResult) => {
    setSelectedGoal(parentGoal);
    setSelectedKeyResult(keyResult);
    setNewGoal({
      isNewParentGoalSelected: true,
      selectedParentGoalId: keyResult.goal_id,
      selectedParentKeyResultId: keyResult.id,
      showAlignment: true,
      showSetPersonalGoalButton: false,
    });
  };

  const handleSelection = () => {
    setSelectedGoal(undefined);
    setSelectedKeyResult(undefined);
    setShowAIGoalAssistantDrawer(false);
    setNewGoal({
      isNewParentGoalSelected: true,
      selectedParentGoalId: undefined,
      selectedParentKeyResultId: undefined,
      showAlignment: true,
      showSetPersonalGoalButton: false,
    });
  };

  const handleNoGoalFoundClick = () => {
    handleEmployeeSelection('');
    setNewGoal(RESET);
  };

  const handleCreatePersonalGoalClick = () => {
    setNewGoal({
      isNewParentGoalSelected: true,
      selectedParentGoalId: undefined,
      selectedParentKeyResultId: undefined,
      showAlignment: false,
      showSetPersonalGoalButton: false,
    });
  };

  if (selectedGoal || selectedKeyResult) {
    return (
      <div className={styles.goalSearchResultsContainer}>
        <GoalEditSectionHeader
          subtitle={t('where_does_my_goal_add_value')}
          tooltip={t('where_does_my_goal_add_value_tooltip')}
        />
        <GoalSearchSelectedComponent
          goalCreated={goalCreated}
          value={selectedKeyResult ? selectedKeyResult.title : selectedGoal?.title}
          selectedGoal={selectedGoal}
          selectedKeyResult={selectedKeyResult}
          handleSelection={handleSelection}
        />
      </div>
    );
  }

  if (isGroupedGoalsEmpty) {
    return (
      <div className={styles.goalSearchResultsContainer}>
        <GoalEditSectionHeader
          subtitle={t('where_does_my_goal_add_value')}
          tooltip={t('where_does_my_goal_add_value_tooltip')}
        />
        <div className={styles.noGoalSearchResultItemContainer}>
          <div className={styles.selectedComponentContent}>
            <SubtleObjectiveIcon />
            <div>
              <Text size="large">{t('no_results_found')}</Text>
              <div className={styles.noGoalTextContainer}>
                <Text size="small" color="faint">
                  {t('try')}
                </Text>
                <Button
                  variant="transparent"
                  size="small"
                  onClick={handleNoGoalFoundClick}
                  className={styles.noGoalButton}
                >
                  <Text size="small" color="faint">
                    {t('aligning_with_others')}
                  </Text>
                </Button>
                <Text size="small" color="faint">
                  {t('or')}
                </Text>
                <Button
                  variant="transparent"
                  size="small"
                  onClick={handleCreatePersonalGoalClick}
                  className={styles.noGoalButton}
                >
                  <Text size="small" color="faint">
                    {t('create_a_personal_goal')}
                  </Text>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.goalSearchResultsContainer}>
      <GoalEditSectionHeader
        subtitle={t('where_does_my_goal_add_value')}
        tooltip={t('where_does_my_goal_add_value_tooltip')}
        extraText={t('select_objective_or_key_result').toString()}
      />
      <div className={styles.groupedGoals}>
        {sortedTimeframes.map((timeframe) => {
          return (
            <GoalAccordion
              key={timeframe}
              timeframe={timeframe}
              goals={groupedGoals[timeframe]}
              handleGoalSelected={handleGoalSelected}
              handleKeyResultSelected={handleKeyResultSelected}
            />
          );
        })}
      </div>
    </div>
  );
};
