import {isMimeType, mediaFileReader} from '@lexical/utils';
import {INSERT_IMAGE_COMMAND} from 'components/DSYS/editor/plugins/ImagesPlugin';
import {
  INSERT_SPINNER_COMMAND,
  REMOVE_SPINNER_COMMAND,
} from 'components/DSYS/editor/plugins/SpinnerPlugin';
import {INSERT_VIDEO_COMMAND} from 'components/DSYS/editor/plugins/VideosPlugin';
import {LexicalEditor} from 'lexical';
import {getBackendUrl} from 'utilities';

export const ACCEPTABLE_FILE_TYPES = [
  'image',
  'image/heic',
  'image/heif',
  'image/gif',
  'image/webp',
  'image/png',
  'image/jpeg',

  'video/mp4',
  'video/avi',
  'video/mov',
  'video/quicktime',
];

export const handleFiles = async (
  files: File[],
  uploadMedia: (data: {path: string; file: File}) => Promise<string>,
  editor: LexicalEditor
): Promise<void> => {
  const filesResult = await mediaFileReader(files, ACCEPTABLE_FILE_TYPES);

  for (const {file} of filesResult) {
    if (isMimeType(file, ACCEPTABLE_FILE_TYPES)) {
      try {
        const path = `${new Date().toISOString()}_${file.name}`;
        editor.dispatchCommand(INSERT_SPINNER_COMMAND, path);
        const url = await uploadMedia({path, file});
        editor.dispatchCommand(REMOVE_SPINNER_COMMAND, path);
        if (file.type.startsWith('image/')) {
          editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
            altText: file.name,
            src: getBackendUrl() + `/api/v1/news/media/${url}`,
          });
        } else if (file.type.startsWith('video/')) {
          editor.dispatchCommand(INSERT_VIDEO_COMMAND, {
            altText: file.name,
            src: getBackendUrl() + `/api/v1/news/media/${url}`,
          });
        }
      } catch (error) {
        console.error('Upload failed:', error);
      }
    } else {
      console.error('Unsupported file type:', file.type);
    }
  }
};
