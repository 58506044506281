import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Toggle} from '@dropbox/dig-components/dist/controls';
import {List} from '@dropbox/dig-components/dist/list';
import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CalendarLine,
  CelebrateLine,
  EmojiWinkLine,
  LocationLine,
  MentionLine,
  OpenLine,
  PersonCircleLine,
  PhoneSupportLine,
  ThemeLine,
} from '@dropbox/dig-icons/assets';
import {FingerprintMini} from '@dropbox/dig-illustrations';
import {useMutation, useSuspenseQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {delegatesAtom, loggedInEmployeeAtom} from 'atoms/employee';
import {themePreferenceAtom} from 'atoms/settings';
import {snackbarAtom} from 'atoms/snackbar';
import cx from 'classnames';
import {Employee, Setting, SettingService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';
import {findNotification} from 'components/notifications/utils/findNotification';
import {useNotificationArchiver} from 'components/notifications/utils/useNotificationArchiver';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {isDev} from 'helpers/environment';
import {reportAndLogError} from 'helpers/logging';
import {isSuperUser} from 'helpers/utils';
import {useDebouncedValue} from 'hooks/useDebounce';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {ReactNode, Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

import styles from './Settings.module.css';

type EditableSetting = Omit<Setting, 'id' | 'ldap' | 'created_at' | 'updated_at' | 'user_id'>;

export const Settings = () => {
  const [searchParams] = useSearchParams();
  const {archiveNotification} = useNotificationArchiver();
  const [selectedTab, setSelectedTab] = useState(searchParams.get('t') ?? 'general');
  useDocumentTitle(t('settings'));

  useEffect(() => {
    const notification = findNotification({type: 'new_user'});

    if (notification) {
      archiveNotification({id: notification.id});
      analyticsLogger().logEvent('NOTIFICATION_INBOX_CLICKED', {
        type: notification.type,
        click: 'organic',
      });
    }
  }, [archiveNotification, searchParams]);

  return (
    <Layout.Container size="condensed">
      <Title size={24}>{t('settings')}</Title>
      <Suspense fallback={null}>
        <Tabs selectedTab={selectedTab} onSelection={setSelectedTab} style={{minHeight: 386}}>
          <Tabs.Group hasHorizontalPadding={false}>
            <Tabs.Tab id="general">{t('settings_profile')}</Tabs.Tab>
            <Tabs.Tab id="notifications">{t('settings_notifications')}</Tabs.Tab>
          </Tabs.Group>

          <Tabs.Panel tabId="general">
            <SettingsControls />
          </Tabs.Panel>
          <Tabs.Panel tabId="notifications">
            <NotificationControls />
          </Tabs.Panel>
        </Tabs>
      </Suspense>
    </Layout.Container>
  );
};
const NotificationControls = () => {
  const {settings, setSettings} = useSettingsQuery();
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const handleToggle = async (property: keyof EditableSetting) => {
    try {
      await setSettings({
        ...settings,
        [property]: !settings[property],
      });
      setSnackbarMessage({text: t('saved')});
    } catch (error) {
      reportAndLogError(error, `Could not save ${property} settings`);
      setSnackbarMessage({text: t('couldnt_save')});
    }
  };

  return (
    <>
      <Box marginTop="24">
        <Text isBold>{t('settings_notification_activity')}</Text>
      </Box>
      <List spacing="large" className={styles.settingsList}>
        <SettingComponent
          text={<Text>{t('settings_badge_awarded_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.badge_awarded_email}
              onClick={() => handleToggle('badge_awarded_email')}
            />
          }
        />
        <SettingComponent
          text={<Text>{t('settings_gratitude_viewed_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.gratitude_viewed_email}
              onClick={() => handleToggle('gratitude_viewed_email')}
            />
          }
        />
      </List>

      <Box paddingTop="24" borderTop="Solid" borderColor="Border Subtle">
        <Text isBold>{t('settings_notification_birthday')}</Text>
      </Box>
      <List spacing="large" className={styles.settingsList}>
        <SettingComponent
          text={<Text>{t('settings_upcoming_birthday_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.upcoming_birthday_email}
              onClick={() => handleToggle('upcoming_birthday_email')}
            />
          }
        />

        <SettingComponent
          text={<Text>{t('settings_upcoming_birthday_peer_email')}</Text>}
          accessory={
            <Toggle
              isToggled={settings.upcoming_birthday_peer_email}
              onClick={() => handleToggle('upcoming_birthday_peer_email')}
            />
          }
        />
      </List>
    </>
  );
};

const SettingsControls = () => {
  const [themePreference, setThemePreference] = useAtom(themePreferenceAtom);
  const {settings, setSettings} = useSettingsQuery();
  const user = useAtomValue(pulseUserAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const handleToggle = async (property: keyof EditableSetting) => {
    try {
      await setSettings({
        ...settings,
        [property]: !settings[property],
      });
      setSnackbarMessage({text: t('saved')});
    } catch (error) {
      reportAndLogError(error, `Could not save ${property} settings`);
      setSnackbarMessage({text: t('couldnt_save')});
    }
  };

  return (
    <>
      <Box borderRadius="Medium" backgroundColor="Background Subtle" padding="16" marginTop="16">
        <Split alignY="top">
          <Split.Item width="fill">
            <LabelGroup
              align="top"
              withLeftAccessory={
                <Box paddingRight="8" className={styles.fingerprint}>
                  <FingerprintMini width={36} altText={'workday'} />
                </Box>
              }
              withText={<Text isBold>{t('settings_edit_profile_workday')}</Text>}
              withSubtext={t('settings_visit_profile')}
            />
          </Split.Item>
          <Split.Item>
            <ButtonLink
              size="small"
              to={
                employee?.workday_profile
                  ? `${employee.workday_profile}#TABINDEX=7&SUBTABINDEX=0`
                  : 'https://wd5.myworkday.com/dropbox/d/pex/home.htmld'
              }
              variant="opacity"
              withIconEnd={<Box as={UIIcon} color="Text Subtle" src={OpenLine} />}
            >
              {t('settings_edit_profile')}
            </ButtonLink>
          </Split.Item>
        </Split>
      </Box>
      <List spacing="large" className={styles.settingsList}>
        <SettingComponent
          icon={PersonCircleLine}
          text={t('settings_profile_image')}
          subtext={t('settings_profile_image_description')}
          accessory={
            <ButtonLink
              to="https://dropboxprod.service-now.com/esc_dbx?id=sc_cat_item&sys_id=2016ccf71bece010769a7661cd4bcbfe"
              variant="transparent"
            >
              {t('settings_profile_image_request')}
            </ButtonLink>
          }
        />
        <SettingComponent
          icon={LocationLine}
          text={t('settings_share_my_location')}
          subtext={t('settings_display_metro')}
          accessory={
            <Toggle
              isToggled={settings.show_location}
              onClick={() => handleToggle('show_location')}
            />
          }
        />
        <SettingComponent
          icon={CalendarLine}
          text={t('settings_share_timeoff')}
          subtext={t('settings_display_upcoming')}
          accessory={
            <Toggle isToggled={settings.show_pto} onClick={() => handleToggle('show_pto')} />
          }
        />

        <SettingComponent
          icon={MentionLine}
          text={t('settings_pronouns')}
          subtext={
            <Trans
              i18nKey="settings_pronouns_workday"
              t={t}
              components={{
                Link: (
                  <Link
                    monochromatic
                    to={
                      employee?.workday_profile
                        ? `${employee.workday_profile}#TABINDEX=7&SUBTABINDEX=2`
                        : 'https://wd5.myworkday.com/dropbox/d/pex/home.htmld'
                    }
                  />
                ),
              }}
            />
          }
          accessory={
            <Toggle
              isToggled={settings.show_pronouns}
              onClick={() => handleToggle('show_pronouns')}
            />
          }
        />

        <SettingComponent
          icon={CelebrateLine}
          text={t('settings_birthday')}
          subtext={t('settings_birthday_workday')}
          accessory={
            <Toggle
              isToggled={settings.show_birthday}
              onClick={() => handleToggle('show_birthday')}
            />
          }
        />

        <SettingComponent
          icon={EmojiWinkLine}
          text={t('settings_working_with_me')}
          subtext={t('settings_working_with_me_subtext')}
          extra={
            <Suspense fallback={null}>
              <WorkingWithMe initialValue={settings.working_with_me ?? undefined} />
            </Suspense>
          }
          expanded={settings.enable_working_with_me}
          accessory={
            <Toggle
              isToggled={settings.enable_working_with_me}
              onClick={() => handleToggle('enable_working_with_me')}
            />
          }
        />

        <SettingComponent
          icon={PhoneSupportLine}
          text={t('settings_delegation')}
          subtext={t('settings_delegation_subtext')}
          extra={<Suspense fallback={null}>{<Delegates />}</Suspense>}
          expanded={settings.enable_delegation}
          accessory={
            <Toggle
              isToggled={settings.enable_delegation}
              onClick={() => handleToggle('enable_delegation')}
            />
          }
        />

        {(isDev || isSuperUser(user?.email)) && (
          <SettingComponent
            icon={ThemeLine}
            text={t('settings_theme')}
            subtext={t('settings_theme_description')}
            accessory={
              <Select
                id="theme-select"
                defaultValue={themePreference}
                onChange={(value) =>
                  setThemePreference(value as Parameters<typeof setThemePreference>[0])
                }
              >
                <Select.Option value="auto">
                  {t('settings_theme_same_as_system') as string}
                </Select.Option>
                <Select.Option value="dark">{t('settings_theme_dark') as string}</Select.Option>
                <Select.Option value="bright">{t('settings_theme_bright') as string}</Select.Option>
              </Select>
            }
          />
        )}
      </List>
    </>
  );
};

const Delegates = () => {
  const [delegates, setDelegates] = useAtom(delegatesAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const removeEmployee = (employee: Employee) => {
    setDelegates((delegates ?? []).filter((e) => e.ldap !== employee.ldap));
    setSnackbarMessage({text: t('saved')});
  };

  const selectEmployee = useCallback(
    (selected: Employee) => {
      const found = delegates?.find((employee) => employee.ldap === selected.ldap);
      if (!found) {
        setDelegates([...delegates!, selected]);
        setSnackbarMessage({text: t('saved')});
      }
    },
    [delegates, setDelegates, setSnackbarMessage]
  );

  const selectEmployees = useCallback(
    (selected: Employee[]) => {
      const found = selected.filter(
        (employee) => !delegates?.find((e) => e.ldap === employee.ldap)
      );
      if (found.length) {
        setDelegates([...(delegates ?? []), ...found]);
        setSnackbarMessage({text: t('saved')});
      }
    },
    [delegates, setDelegates, setSnackbarMessage]
  );

  return (
    <PeopleSearchMenu
      selectedEmployees={delegates ?? []}
      onRemoveEmployee={removeEmployee}
      onSelectEmployee={selectEmployee}
      onSelectEmployees={selectEmployees}
    />
  );
};

const WorkingWithMe = ({initialValue}: {initialValue: string | undefined}) => {
  const [value, setValue] = useState<EditorState>();

  const content = useRef(initialValue);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const debouncedValue = useDebouncedValue(value, 300);
  const {settings, setSettings} = useSettingsQuery();

  useEffect(() => {
    const debouncedJson = debouncedValue?.toString() ?? '';
    if (debouncedJson && debouncedJson !== settings.working_with_me) {
      setSettings({...settings, working_with_me: debouncedJson}).then(() =>
        setSnackbarMessage({text: t('saved')})
      );
    }
  }, [settings, setSettings, debouncedValue, setSnackbarMessage]);

  return (
    <div style={{overflow: 'auto', maxHeight: '280px'}}>
      <RichTextArea
        editable
        topOffset={0}
        theme="small"
        placeholder={t('settings_working_with_me_placeholder')}
        value={content.current}
        onChange={(state) => setValue(state)}
      />
    </div>
  );
};

interface SettingProps {
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  text: ReactNode;
  subtext?: ReactNode;
  accessory?: ReactNode;
  extra?: ReactNode;
  expanded?: boolean;
}

const SettingComponent = ({icon, text, subtext, accessory, extra, expanded}: SettingProps) => (
  <>
    <Box display="flex" flexDirection="column">
      <List.Item className={styles.settingsListItem}>
        {icon && (
          <List.Accessory>
            <Box as={UIIcon} color="Text Subtle" src={icon} />
          </List.Accessory>
        )}
        <List.Content>
          <LabelGroup withText={<Text isBold>{text}</Text>} withSubtext={subtext} />
        </List.Content>
        <List.Accessory>{accessory}</List.Accessory>
      </List.Item>
      <div className={cx(styles.extra, {[styles.expanded]: expanded})}>{extra}</div>
    </Box>
  </>
);

export const useSettingsQuery = () => {
  const pulseUser = useAtomValue(pulseUserAtom);

  // Get settings data
  const {data: settings} = useSuspenseQuery({
    queryKey: ['settings'],
    queryFn: getService(SettingService).getSettingApiV1SettingsGet,
  });

  // Update settings data
  const {mutateAsync: setSettings} = useMutation({
    mutationFn: getService(SettingService).upsertSettingApiV1SettingsPost,
    onMutate: async (newSettings) => {
      await queryClient.cancelQueries({queryKey: ['settings']});

      // Optimistically update
      queryClient.setQueryData(['settings'], newSettings);
    },
    onError: () => queryClient.setQueryData(['settings'], settings),
    onSettled: (response) => {
      queryClient.setQueryData(['settings'], response);
      if (pulseUser) {
        queryClient.invalidateQueries({queryKey: ['profile', pulseUser.email.split('@')[0]]});
      }
    },
  });

  return {settings, setSettings};
};
