import {Box} from '@dropbox/dig-foundations';
import {isMobileAtom} from 'atoms/layout';
import {useAtomValue} from 'jotai';
import {Children, createContext, ReactNode, useContext} from 'react';

type GridSize = 'condensed' | 'large' | 'full';

const GridContext = createContext<{
  withNav: boolean;
}>({withNav: false});

const Container = ({size = 'full', children}: {size?: GridSize; children: ReactNode}) => {
  const withNav = Children.toArray(children).some((child: any) => child.type === Nav);
  const hasHelper = Children.toArray(children).some((child: any) =>
    [Nav, Body, Column].includes(child.type)
  );

  const isMobile = useAtomValue(isMobileAtom);

  if (!hasHelper) {
    if (size === 'full') {
      return (
        <Box
          marginX="auto"
          style={{
            maxWidth: 1240,
          }}
        >
          {children}
        </Box>
      );
    } else if (size === 'large') {
      return (
        <Box
          marginX="auto"
          style={{
            display: 'grid',
            columnGap: 16,
            gridTemplateColumns: `repeat(7, 1fr)`,
            maxWidth: 1240,
          }}
        >
          <Column index={isMobile ? 0 : 1} span={isMobile ? 7 : 5}>
            {children}
          </Column>
        </Box>
      );
    } else if (size === 'condensed') {
      return (
        <Box
          marginX="auto"
          style={{
            display: 'grid',
            columnGap: 16,
            gridTemplateColumns: `repeat(5, 1fr)`,
            maxWidth: 1240,
          }}
        >
          <Column index={isMobile ? 0 : 1} span={isMobile ? 7 : 3}>
            {children}
          </Column>
        </Box>
      );
    }

    return (
      <Box
        marginX="auto"
        style={{
          display: 'grid',
          columnGap: 16,
          gridTemplateColumns: `repeat(5, 1fr)`,
          maxWidth: 1240,
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <GridContext.Provider value={{withNav}}>
      <Box
        marginX="auto"
        style={{
          display: 'grid',
          columnGap: 16,
          gridTemplateColumns: `repeat(${!withNav ? 5 : 7}, 1fr)`,
          maxWidth: 1240,
        }}
      >
        {children}
      </Box>
    </GridContext.Provider>
  );
};

const Nav = ({children}: {children: ReactNode}) => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <Box
      marginBottom={isMobile ? '16' : undefined}
      style={{gridColumn: `1 / span ${isMobile ? 7 : 2}`}}
    >
      {children}
    </Box>
  );
};

const Body = ({children}: {children: ReactNode}) => {
  const {withNav} = useContext(GridContext);
  const isMobile = useAtomValue(isMobileAtom);

  if (!withNav) {
    throw new Error('Body must be a child of a Container with a Nav');
  }

  return (
    <Box
      style={{
        marginLeft: 40,
        gridColumn: `${isMobile ? 1 : withNav ? 3 : 2} / span ${isMobile ? 7 : withNav ? 5 : 3}`,
      }}
    >
      {children}
    </Box>
  );
};

/** Prefer other components to this one */
const Column = ({
  span,
  index,
  // preferComposition,
  children,
}: {
  span: 1 | 2 | 3 | 5 | 7;
  index?: number;
  // preferComposition?: boolean;
  children: ReactNode;
}) => {
  const {withNav} = useContext(GridContext);
  const size = withNav ? 7 : 5;
  const startColumn = index !== undefined ? index + 1 : Math.floor((size - span) / 2) + 1;

  return (
    <Box
      // paddingX={preferComposition ? undefined : '16'}
      style={{gridColumn: `${startColumn} / span ${span}`}}
    >
      {children}
    </Box>
  );
};

export const Layout = {Container, Nav, Body, Column};
