import {Stack} from '@dropbox/dig-foundations';
import {Team} from 'client';
import {STEAM_TEAM_SLUG} from 'constant';
import {EmployeeGoalsTableComponent} from 'views/goals_v2/TableComponents/EmployeeGoalsTableComponent';
import {TeamGoalsTableComponent} from 'views/goals_v2/TableComponents/TeamGoalsTableComponent';
import {GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {getEmptyFilter} from 'views/goals_v2/utils';

export const GoalsTablesComponent = ({
  keyPrefix,
  tableProps,
  ldaps = [],
  teams = [],
}: {
  keyPrefix: string;
  tableProps: GoalsTablePropsWithoutData;
  ldaps?: string[];
  teams?: Team[];
}) => {
  const {filter = getEmptyFilter()} = tableProps;
  const filterWithTeamInfo = teams.length ? {...filter, teams} : filter;
  const teamSlugs = teams.map(({slug}) => slug);
  const filterTeams = filter.teams.length
    ? filter.teams.filter(({slug}) => {
        if (keyPrefix === 'company_goals' && slug === STEAM_TEAM_SLUG) {
          return false;
        }
        return !teamSlugs.includes(slug);
      })
    : [];
  const filterLdaps = filter.people.length
    ? filter.people.filter((ldap) => !ldaps.includes(ldap))
    : [];

  // If there are ldaps, show employee tables first, then team tables (with additional filter tables on top)
  if (ldaps.length) {
    return (
      <Stack gap="12">
        {filterLdaps.map((ldap, index) => (
          <EmployeeGoalsTableComponent
            index={teams.length + filterTeams.length + index}
            key={`${keyPrefix}-${ldap}`}
            keyPrefix={keyPrefix}
            ldap={ldap}
            {...tableProps}
          />
        ))}
        {filterTeams.map((team, index) => {
          return (
            <TeamGoalsTableComponent
              index={index}
              key={`${keyPrefix}-${team.slug}`}
              keyPrefix={keyPrefix}
              team={team}
              {...tableProps}
              filter={filterWithTeamInfo}
            />
          );
        })}
        {ldaps.map((ldap, index) => (
          <EmployeeGoalsTableComponent
            index={filterLdaps.length + teams.length + filterTeams.length + index}
            key={`${keyPrefix}-${ldap}`}
            keyPrefix={keyPrefix}
            ldap={ldap}
            {...tableProps}
          />
        ))}
        {teams.map((team, index) => {
          return (
            <TeamGoalsTableComponent
              index={filterTeams.length + index}
              key={`${keyPrefix}-${team.slug}`}
              keyPrefix={keyPrefix}
              team={team}
              {...tableProps}
              filter={filterWithTeamInfo}
            />
          );
        })}
      </Stack>
    );
  }

  // If there are teams, show team tables first, then employee tables (with additional filter tables on top)
  return (
    <Stack gap="12">
      {filterTeams.map((team, index) => {
        return (
          <TeamGoalsTableComponent
            index={index}
            key={`${keyPrefix}-${team.slug}`}
            keyPrefix={keyPrefix}
            team={team}
            {...tableProps}
            filter={filterWithTeamInfo}
          />
        );
      })}
      {filterLdaps.map((ldap, index) => (
        <EmployeeGoalsTableComponent
          index={teams.length + filterTeams.length + index}
          key={`${keyPrefix}-${ldap}`}
          keyPrefix={keyPrefix}
          ldap={ldap}
          {...tableProps}
        />
      ))}
      {teams.map((team, index) => {
        return (
          <TeamGoalsTableComponent
            index={filterTeams.length + index}
            key={`${keyPrefix}-${team.slug}`}
            keyPrefix={keyPrefix}
            team={team}
            {...tableProps}
            filter={filterWithTeamInfo}
          />
        );
      })}
      {ldaps.map((ldap, index) => (
        <EmployeeGoalsTableComponent
          index={filterLdaps.length + teams.length + filterTeams.length + index}
          key={`${keyPrefix}-${ldap}`}
          keyPrefix={keyPrefix}
          ldap={ldap}
          {...tableProps}
        />
      ))}
    </Stack>
  );
};
