import {useSortable} from '@dnd-kit/sortable';
import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Toggletip, Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine, DragHandleLine, MoreHorizontalLine, ShareLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {derivedShowAIGoalAssistantDrawerAtom} from 'atoms/goals';
import {growthbookCacheAtom} from 'atoms/layout';
import {GoalCreate, GoalUpdate, KeyResultCreate} from 'client';
import GoalAIAssistant from 'components/ai/GoalAIAssistant';
import {Facepile} from 'components/DSYS/Facepile';
import {Contributors} from 'components/goals/Contributors';
import {t} from 'i18next';
import {useAtom, useAtomValue, useSetAtom} from 'jotai';
import {useCallback, useEffect, useRef, useState} from 'react';
import React from 'react';
import {SetGoalsV2DrawerDataType} from 'views/goals_v2/Drawers/types';
import {shouldCloseAndResetDrawers} from 'views/goals_v2/Drawers/utils';
import {SubtleKeyResultIcon} from 'views/goals_v2/icons';

import styles from './GoalEditSection.module.css';

export const GoalEditSection = ({
  idx,
  goalCreateValues,
  goalCreated,
  handleOnInputChange,
}: {
  idx: number;
  goalCreateValues: GoalCreate | GoalUpdate;
  goalCreated: boolean;
  handleOnInputChange: (idx: number, value: string) => void;
  isEdit?: boolean;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const {isGoalAiEnabled} = useAtomValue(growthbookCacheAtom);
  const [showAIGoalAssistantDrawer, setShowAIGoalAssistantDrawer] = useAtom(
    derivedShowAIGoalAssistantDrawerAtom
  );
  const [editedAI, setEditedAI] = useState(false);

  return (
    <div className={styles.objectiveActionContainer}>
      <GoalEditSectionHeader subtitle={t('objective')} tooltip={t('objective_tooltip')} />
      <FormRow>
        <TextArea
          placeholder={t('start_writing_placeholder').toString()}
          id="startWriting"
          value={goalCreateValues.title}
          onChange={(e) => {
            handleOnInputChange(idx, e.currentTarget.value);
            if (editedAI) {
              analyticsLogger().logEvent('OBJECTIVE_AI_EDITED');
              setEditedAI(false);
            }
          }}
          spellCheck="true"
          readOnly={goalCreated}
          autoFocus={false}
          resizable="auto"
          onFocus={() => setShowAIGoalAssistantDrawer(true)}
          ref={textareaRef}
        />
      </FormRow>
      {isGoalAiEnabled && (
        <GoalAIAssistant
          idx={idx}
          questionText={goalCreateValues.title}
          onClose={() => setShowAIGoalAssistantDrawer(false)}
          onInsert={(index, number) => {
            setEditedAI(true);
            handleOnInputChange(index, number);
          }}
          showAIAssistant={showAIGoalAssistantDrawer}
        />
      )}
    </div>
  );
};

interface KeyResultEditSectionProps {
  source: string;
  idx: number;
  keyResultCreateValues: KeyResultCreate;
  handleOnInputChange: (idx: number, value: string) => void;
  handleAddContributor: (idx: number, contributorIds: string[]) => void;
  handleOnDelete: (idx: number) => void;
  delegateId?: string;
  isEdit?: boolean;
  disableDelete: boolean;
}

export const KeyResultEditSection = React.forwardRef<HTMLDivElement, KeyResultEditSectionProps>(
  ({
    source,
    idx,
    keyResultCreateValues,
    handleOnInputChange,
    handleAddContributor,
    handleOnDelete,
    delegateId,
    isEdit,
    disableDelete,
  }) => {
    const {delegatedBy, employee} = useAtomValue(loggedInEmployeeAtom);
    const selectedDelegate = delegatedBy?.find((d) => d.user_id === delegateId) ?? employee;

    const [showContributors, setShowContributors] = useState(false);
    const [, setContributorsDrawerData] = useState<SetGoalsV2DrawerDataType>({
      drawerType: 'contributor',
      isOpen: false,
      source,
    });

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);
    const {attributes, isSorting, listeners, transform, setNodeRef, transition, isDragging} =
      useSortable({
        id: idx,
      });

    const adjustTextareaHeight = () => {
      const element = textareaRef.current;
      if (element) {
        element.style.height = '56px';
        element.style.height = element.scrollHeight + 'px';
      }
    };

    useEffect(() => {
      // if (textareaRef.current && idx === 0 && !isEdit) {
      //   textareaRef.current.focus();
      // }
      const element = textareaRef.current;
      if (element) {
        element.style.height = element.scrollHeight + 'px';
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const style = {
      transition,
      transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
      zIndex: isDragging ? 1 : undefined,
      opacity: isSorting && !isDragging ? 0.7 : 1,
    };

    const handleToggleContributorsDrawerData = useCallback(
      (open: boolean) => {
        setContributorsDrawerData({
          drawerType: 'contributor',
          isOpen: open,
          source,
        });
      },
      [source]
    );

    const handleSelection = (value: string) => {
      if (value === 'join') {
        setShowContributors(true);
        handleToggleContributorsDrawerData(true);
      } else if (value === 'delete') {
        handleOnDelete(idx);
      }
    };

    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        const header = document.querySelector('.dig-GlobalHeader');
        const drawer = document.querySelector('.dig-Drawer');

        if (
          (!header || !header.contains(event.target as Node)) &&
          (!drawer || !drawer.contains(event.target as Node)) &&
          shouldCloseAndResetDrawers(event.target as HTMLElement)
        ) {
          setShowContributors(false);
          handleToggleContributorsDrawerData(false);
        }
      };

      document.body.addEventListener('click', handleClick);

      return () => {
        document.body.removeEventListener('click', handleClick);
      };
    }, [handleToggleContributorsDrawerData]);

    return (
      <Box
        backgroundColor="Background Base"
        borderRadius="Small"
        boxShadow={isDragging ? 'Floating' : undefined}
        className={styles.keyResultContainer}
        ref={setNodeRef}
        style={style}
      >
        <Box
          position="absolute"
          color={isDragging ? 'Border Base' : 'Border Subtle'}
          style={{left: -26, top: 16, cursor: isDragging ? 'grabbing' : 'grab'}}
          {...attributes}
          {...listeners}
          tabIndex={-1}
        >
          <Box as={UIIcon} src={DragHandleLine} size="medium" color="Border Base" />
        </Box>

        <div className={styles.keyResultAvatar}>
          <SubtleKeyResultIcon />
        </div>

        <TextArea
          placeholder={t('start_writing_placeholder').toString()}
          size="large"
          id="startWriting"
          value={keyResultCreateValues.title}
          onChange={(e) => handleOnInputChange(idx, e.currentTarget.value)}
          className={styles.keyResultTextArea}
          spellCheck="true"
          autoFocus={false}
          onFocus={() => setShowAIGoalAssistantDrawer(false)}
          ref={textareaRef}
          onInput={adjustTextareaHeight}
        />

        {keyResultCreateValues.contributors?.length ? (
          <Box position="absolute" style={{right: 42, top: 12}}>
            <Facepile
              size="small"
              ldaps={[selectedDelegate.ldap, ...keyResultCreateValues.contributors]}
              ownerIsFirst
              onClick={() => {
                setShowContributors(true);
                handleToggleContributorsDrawerData(true);
              }}
            />
          </Box>
        ) : (
          <>
            <Tooltip title={t('add_co-owners')}>
              <IconButton
                variant="borderless"
                size="medium"
                shape="circular"
                className={styles.keyResultButton}
                onClick={() => {
                  setShowContributors(true);
                  handleToggleContributorsDrawerData(true);
                }}
                style={{right: 42}}
              >
                <UIIcon src={ShareLine} />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Menu.Wrapper onSelection={handleSelection}>
          {({getContentProps, getTriggerProps}) => (
            <>
              <IconButton
                variant="borderless"
                size="medium"
                shape="circular"
                className={styles.keyResultButton}
                style={
                  getContentProps().open || keyResultCreateValues.contributors?.length
                    ? {display: 'inline-block'}
                    : undefined
                }
                {...getTriggerProps()}
              >
                <UIIcon src={MoreHorizontalLine} size="medium" />
              </IconButton>
              {getContentProps().open && (
                <Menu.Content {...getContentProps()}>
                  <Menu.Segment>
                    <Menu.ActionItem
                      key="join"
                      value="join"
                      withLeftAccessory={<UIIcon src={ShareLine} />}
                    >
                      {keyResultCreateValues.contributors?.length
                        ? t('edit_co-owners')
                        : t('add_co-owners')}
                    </Menu.ActionItem>
                    {!disableDelete && (
                      <Menu.ActionItem
                        key="delete"
                        value="delete"
                        withLeftAccessory={<UIIcon src={DeleteLine} />}
                      >
                        {t('delete')}
                      </Menu.ActionItem>
                    )}
                  </Menu.Segment>
                </Menu.Content>
              )}
            </>
          )}
        </Menu.Wrapper>

        <Contributors
          isOwner
          source={isEdit ? 'edit' : 'create'}
          isOpen={showContributors}
          contributorLdaps={keyResultCreateValues.contributors ?? []}
          handleDelete={(contributors) => {
            handleAddContributor(idx, contributors);
          }}
          handleClose={() => setShowContributors(false)}
          handleContributorsChanged={(contributors) => {
            handleAddContributor(idx, contributors);
          }}
          ownerLdap={selectedDelegate.ldap}
        />
      </Box>
    );
  }
);

KeyResultEditSection.displayName = 'KeyResultEditSection';

interface GoalEditSectionHeaderProps {
  title?: string;
  subtitle: string;
  tooltip: string;
  extraText?: string;
}

export const GoalEditSectionHeader = ({
  title,
  subtitle,
  tooltip,
  extraText,
}: GoalEditSectionHeaderProps) => {
  return (
    <div className={styles.sectionHeaderWrapper}>
      <div className={styles.sectionHeader}>
        {title && (
          <Text color="faint" size="small" isBold>
            {title}
          </Text>
        )}
        <FormLabel className={styles.subTitleContainer} subtext={<Toggletip title={tooltip} />}>
          {subtitle}
        </FormLabel>
      </div>
      {extraText && <Text color="faint">{extraText}</Text>}
    </div>
  );
};
