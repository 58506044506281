import {Button} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Select, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Toggletip} from '@dropbox/dig-components/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {Twinkle2Line} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee, GoalUser, Update} from 'client';
import {GoalData, KeyResult} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {StatusButtonIcon, statusOptions} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {useGoal, useUpdateService} from 'components/goals/hooks';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import styles from 'components/shared/StatusModal.module.css';
import {getNextQuarter} from 'components/shared/TimeAgo';
import {reportAndLogError} from 'helpers/logging';
import {isGoal as isGoalRow} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {SyntheticEvent, useEffect, useMemo, useRef, useState} from 'react';
import React from 'react';
import {ContinueGoalToggle} from 'views/goals_v2/ContinueGoal';
import {ContinueGoalButton} from 'views/goals_v2/ContinueGoalButton';
import {GoalHeaderDetails} from 'views/goals_v2/GoalHeaderDetails';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';
import {TeamInfo} from 'views/goals_v2/types';
import {hasOpenStatusKeyResult, isClosedStatus} from 'views/goals_v2/utils';
import {getRowData} from 'views/goals_v2/utils';

type StateType = {open: boolean; action: string};

const inferStatusUpdatePrompt = (newStatus: string) => {
  switch (newStatus) {
    case 'on_track':
      return {promptStr: 'status_update_helper_on_track', required: false};
    case 'at_risk':
      return {promptStr: 'status_update_helper_kr_at_risk_or_off_track', required: true};
    case 'off_track':
      return {promptStr: 'status_update_helper_kr_at_risk_or_off_track', required: true};
    case 'complete':
      return {promptStr: 'status_update_helper_complete', required: false};
    case 'cancelled':
      return {promptStr: 'status_update_helper_cancelled', required: true};
    case 'missed':
      return {promptStr: 'status_update_helper_missed', required: true};
    default:
      return {promptStr: 'status_update_helper', required: false};
  }
};

const StatusModalHeader = ({
  goal,
  keyResult,
  delegateChip,
}: {
  goal: GoalData;
  keyResult?: KeyResult;
  delegateChip?: React.ReactNode;
}) => {
  const {delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const pulseUser = useAtomValue(pulseUserAtom);

  const row = keyResult ? keyResult : goal;
  const isGoal = isGoalRow(row);
  const goalOwner = goal.users![0];
  const {numKeyResults} = getRowData(row, goalOwner, pulseUser?.email, delegatedBy);
  const currentRowTimeframe = row.timeframe ?? goal.timeframe;
  const subGoals = keyResult
    ? goal.key_results_aligned_goals?.filter(
        (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
      )
    : goal.children;
  const numSubGoals = subGoals?.length ?? 0;

  const title = keyResult ? t('key_result_alone') : t('objective');
  const icon = keyResult ? SubtleKeyResultIcon : SubtleObjectiveIcon;

  return (
    <Modal.Header
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <Box as="div" alignItems="center" display="flex" style={{gap: '4px', marginLeft: '-5px'}}>
        <Box as={UIIcon} src={icon} color="Text Subtle" />
        <Box as={Eyebrow} color="Text Subtle" size="xsmall">
          {title}
        </Box>
      </Box>
      <Box as="div" display="flex" justifyContent="space-between" marginTop="12">
        <Box>
          <Title size={18}>
            <Truncate lines={2} style={{hyphens: 'auto'}}>
              {row.title}
            </Truncate>
          </Title>
          <Box marginTop="4">
            <GoalHeaderDetails
              isGoalRow={isGoal}
              goal={goal}
              currentRowTimeframe={currentRowTimeframe}
              numKeyResults={numKeyResults}
              numSubGoals={numSubGoals}
            />
          </Box>
        </Box>
        {delegateChip}
      </Box>
    </Modal.Header>
  );
};

export const StatusModal = ({
  goalId,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  user,
  keyResultId,
  state,
  setState,
  setIsStatusModalOpen,
  previousUpdate,
  teamInfo,
  parentGoalUser,
  ownerEmployeeLdap,
  onSuccess,
  onContinueGoalClick,
}: {
  title: string;
  keyResultId?: number;
  goalId: number;
  timeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  user: GoalUser;
  state: StateType;
  setState: React.Dispatch<React.SetStateAction<StateType>>;
  setIsStatusModalOpen?: (isOpen: boolean) => void;
  previousUpdate?: Update;
  teamInfo?: TeamInfo;
  parentGoalUser?: GoalUser;
  ownerEmployeeLdap?: string;
  onSuccess?: () => void;
  onContinueGoalClick?: (selectedStatus: string) => void;
}) => {
  const [saveState, setSaveState] = useState('idle');
  const [updateAs, setUpdateAs] = useState<Employee>();

  const [selectedStatus, setSelectedStatus] = useState(
    previousUpdate ? previousUpdate.status : 'no_status'
  );

  const {delegatedBy, employee} = useAtomValue(loggedInEmployeeAtom);
  const ownerLdap = user.email.split('@')[0];
  const isDelegated = delegatedBy?.some(({email}) => email === user.email);

  const editorState = useRef<EditorState>();
  const [currentMetric, setCurrentMetric] = useState(previousUpdate?.current_metric ?? '');
  const [targetMetric, setTargetMetric] = useState(previousUpdate?.target_metric ?? '');
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const canSave = selectedStatus !== 'no_status' && saveState === 'idle';

  const isGoal = goalId && !keyResultId;

  const goal = useGoal({goalId});
  const keyResult = keyResultId ? goal?.key_results.find(({id}) => id === keyResultId) : undefined;
  const contributorLdaps = useMemo(
    () => keyResult?.contributors?.map(({ldap}) => ldap) ?? [],
    [keyResult?.contributors]
  );

  const ownerLdaps = ownerEmployeeLdap ? new Set([ownerEmployeeLdap]) : new Set<string>();
  if (ownerLdap && ownerLdap !== ownerEmployeeLdap) {
    ownerLdaps.add(ownerLdap);
  }
  if (keyResult) {
    keyResult.contributors.map(({ldap}) => ldap).forEach((ldap) => ownerLdaps.add(ldap));
  }

  const {addUpdate} = useUpdateService({
    timeframe: timeframe,
    teamInfo: teamInfo,
    parentGoalUserLdap: parentGoalUser?.email.split('@')[0],
    ownerLdaps: Array.from(ownerLdaps),
    onSettledCallback: onSuccess,
  });

  const enforceEnableContinueGoalToggle = state.action === 'enforce-enable-continue-goal-toggle';
  const [isContinueGoalToggled, setIsContinueGoalToggled] = useState(
    enforceEnableContinueGoalToggle
  );
  const showContinueGoalToggle =
    onContinueGoalClick &&
    (keyResult ? true : !hasOpenStatusKeyResult(goal, user)) &&
    (enforceEnableContinueGoalToggle || isClosedStatus(selectedStatus, false));
  const showContinueGoalSplitButton = showContinueGoalToggle && isContinueGoalToggled;
  let options = statusOptions;
  if (enforceEnableContinueGoalToggle) {
    // Only show 'closed' status options when Continue Goal toggle is enabled via action menu
    options = options.filter((statusOption) => statusOption.label === 'closed');
  }

  const [statusUpdatePrompt, setStatusUpdatePrompt] = useState<string>('status_update_helper');
  const [statusUpdateRequired, setStatusUpdateRequired] = useState(false);
  const [hasStatusUpdate, setHasStatusUpdate] = useState(
    previousUpdate && previousUpdate.comment !== ''
  );
  const disableSubmit = !canSave || (statusUpdateRequired && !hasStatusUpdate);

  useEffect(() => {
    if (isDelegated) {
      setUpdateAs(delegatedBy?.find(({email}) => email === user.email));
    }
  }, [delegatedBy, isDelegated, user.email]);

  const closeModal = () => {
    setState({open: false, action: ''});
    setIsStatusModalOpen?.(false);
    setNextTimeframe(getNextQuarter(timeframe));
  };

  const confirm = async (hideSuccessSnackbar?: boolean) => {
    if (canSave) {
      try {
        setSaveState('pending');
        await addUpdate({
          userId: user.user_id,
          employeeId: updateAs ? updateAs.user_id : undefined,
          isDelegated: (updateAs && updateAs.email !== employee.email) ?? false,
          ldap: user.email.split('@')[0],
          data: {
            goal_id: goalId,
            key_result_id: keyResultId,
            status: selectedStatus,
            comment: isGoal ? '' : editorState.current?.toString() ?? '',
            current_metric: currentMetric,
            target_metric: targetMetric,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        });
        setSaveState('idle');
        if (!hideSuccessSnackbar) {
          setSnackbarMessage({text: t('status_updated')});
        }
      } catch (error) {
        setSaveState('error');
        reportAndLogError(error, 'Could not update status');
        setSnackbarMessage({text: t('couldnt_update_status')});
      }
    }
    setState({open: false, action: ''});
    setIsStatusModalOpen?.(false);
  };

  const handleStatusChange = (val: string, event: SyntheticEvent<Element, Event>) => {
    setSelectedStatus(val);
    if (!isGoal) {
      const {promptStr, required} = inferStatusUpdatePrompt(val);
      setStatusUpdatePrompt(promptStr);
      setStatusUpdateRequired(required);
    }
    if (!enforceEnableContinueGoalToggle) {
      setIsContinueGoalToggled(false);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    setSelectedStatus(previousUpdate ? previousUpdate.status : 'no_status');
  }, [previousUpdate, setSelectedStatus]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'k')) {
        closeModal();
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (canSave) {
          confirm();
          e.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.open]);

  return (
    <Modal
      open={state.open}
      onRequestClose={(e: React.SyntheticEvent) => {
        closeModal();
        e.stopPropagation();
      }}
      isCentered
      withCloseButton="Close"
      onAfterOpen={(obj?: {overlayEl: Element; contentEl: HTMLDivElement} | undefined) => {
        if (obj) {
          const {overlayEl} = obj;
          overlayEl.addEventListener('click', function (event) {
            if (event.target === overlayEl) {
              closeModal();
              event.stopPropagation();
            }
          });
        }
      }}
    >
      <StatusModalHeader
        goal={goal}
        keyResult={keyResult}
        delegateChip={
          <Split alignX="right" style={{marginLeft: '8px'}}>
            {!isGoal && ownerLdap !== employee.ldap && contributorLdaps.includes(employee.ldap) ? (
              <Split.Item>
                {delegatedBy && (
                  <DelegateSelectMenu
                    delegateId={updateAs?.user_id}
                    setDelegateId={(id) =>
                      setUpdateAs(delegatedBy.find(({user_id}) => user_id === id))
                    }
                    filterLdaps={delegatedBy
                      .map(({ldap}) => ldap)
                      .filter((ldap) => !contributorLdaps.includes(ldap) && ldap !== ownerLdap)}
                  />
                )}
              </Split.Item>
            ) : (
              updateAs?.email === user.email && (
                <Split.Item>
                  {delegatedBy && (
                    <DelegateSelectMenu
                      delegateId={updateAs?.user_id}
                      showDisabledDelegateChip={true}
                    />
                  )}
                </Split.Item>
              )
            )}
          </Split>
        }
      />
      <Modal.Body
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        <FormRow>
          <FormLabel
            htmlFor="status"
            subtext={isGoal ? '' : <Toggletip title={t('status_tooltip')} />}
          >
            {t('status')}
          </FormLabel>
          <Select
            id="status"
            defaultValue={previousUpdate ? previousUpdate.status : 'no_status'}
            onChange={(val, e) => handleStatusChange(val, e)}
            value={selectedStatus}
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
            }}
          >
            {options
              .filter((statusOption) => statusOption.label !== 'draft')
              .map((statusOption) => (
                <Select.OptGroup key={statusOption.label} withLabel={t(statusOption.label)}>
                  {statusOption.statusOptions.map((status) => (
                    <Select.Option
                      className={styles.statusOptionOverride}
                      key={status.value}
                      value={status.value}
                      withAccessory={
                        <UIIcon
                          src={status.icon}
                          color={status.color}
                          size="medium"
                          style={{height: 24, width: 24}}
                        />
                      }
                    >
                      {t(status.value).toString()}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            <Select.Option
              value={previousUpdate ? previousUpdate.status : 'no_status'}
              withAccessory={<StatusButtonIcon status={previousUpdate?.status} />}
              hidden
            >
              {previousUpdate ? t(previousUpdate.status).toString() : t('no_status').toString()}
            </Select.Option>
          </Select>
          {isGoal && (
            <Split
              gap="8"
              alignY="top"
              paddingTop="8"
              paddingLeft="12"
              style={{height: isGoal ? 250 : 24}}
            >
              <Split.Item>
                <Box as={UIIcon} src={Twinkle2Line} color="Text Subtle" />
              </Split.Item>
              <Split.Item>
                <Text color="faint" size="small">
                  {t('automatic_objective_status_change')}
                </Text>
              </Split.Item>
            </Split>
          )}
        </FormRow>

        {!isGoal && (
          <>
            <FormRow>
              <FormLabel
                htmlFor="statusUpdate"
                subtext={<Toggletip title={t(statusUpdatePrompt)} />}
              >
                {t('status_update')}
              </FormLabel>
              <RichTextArea
                editable
                value={previousUpdate?.comment}
                selectAll
                theme="editor"
                minHeight={88}
                placeholder={t('status_update_placeholder').toString()}
                onChange={(editor) => {
                  editorState.current = editor;
                  if (editor.toString() == '') {
                    setHasStatusUpdate(false);
                  } else {
                    setHasStatusUpdate(true);
                  }
                }}
              />
            </FormRow>
            <FormRow>
              <FormLabel htmlFor="metric" subtext={<Toggletip title={t('metric_tooltip')} />}>
                {t('metric')}
              </FormLabel>
              <Split gap="8">
                <Split.Item width="fill">
                  <TextInput
                    id="metric"
                    placeholder={t('current_metric').toString()}
                    size="large"
                    value={currentMetric}
                    onChange={(e) => setCurrentMetric(e.currentTarget.value)}
                    autoComplete="off"
                  />
                </Split.Item>
                <Split.Item width="fill">
                  <TextInput
                    placeholder={t('target_metric').toString()}
                    size="large"
                    value={targetMetric}
                    onChange={(e) => setTargetMetric(e.currentTarget.value)}
                    autoComplete="off"
                  />
                </Split.Item>
              </Split>
            </FormRow>
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        preferComposition
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        <Split gap="8" alignX="right">
          {showContinueGoalToggle && (
            <Split.Item width="fill">
              <ContinueGoalToggle
                isContinueGoalToggled={isContinueGoalToggled}
                setIsContinueGoalToggled={(isToggled: boolean) => {
                  if (isToggled) {
                    analyticsLogger().logEvent('CONTINUE_GOAL_TOGGLED', {
                      type: keyResultId ? 'kr' : 'objective',
                      status: selectedStatus,
                    });
                  }
                  setIsContinueGoalToggled(isToggled);
                }}
                enforceEnableContinueGoalToggle={enforceEnableContinueGoalToggle}
              />
            </Split.Item>
          )}
          <Split.Item>
            <Button
              variant="opacity"
              onClick={(e: React.SyntheticEvent) => {
                closeModal();
                e.stopPropagation();
              }}
            >
              {t('cancel')}
            </Button>
          </Split.Item>
          <Split.Item>
            {showContinueGoalSplitButton ? (
              <ContinueGoalButton
                currentTimeframe={timeframe}
                nextTimeframe={nextTimeframe}
                setNextTimeframe={setNextTimeframe}
                handleContinueGoal={() => {
                  if (isGoal && hasOpenStatusKeyResult(goal, user)) {
                    setSnackbarMessage({text: t('continue_goal_try_again_message')});
                  } else {
                    confirm(true);
                    onContinueGoalClick(selectedStatus);
                    closeModal();
                  }
                }}
                disabled={disableSubmit}
              />
            ) : (
              <Button
                variant="primary"
                onClick={(e: React.SyntheticEvent) => {
                  confirm();
                  e.stopPropagation();
                }}
                disabled={disableSubmit}
                isLoading={saveState === 'pending'}
              >
                {t('update')}
              </Button>
            )}
          </Split.Item>
        </Split>
      </Modal.Footer>
    </Modal>
  );
};
