import {Team} from 'client';
import {useTeamsGoalsSuspense} from 'components/goals/hooks';
import {useEffect} from 'react';
import {NoFilterTable} from 'views/goals_v2/EmptyGoalsTable';
import {GoalsTable} from 'views/goals_v2/GoalsTable';
import {GoalsHeaderData, GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {
  filterTable,
  getEmptyFilter,
  getExpandedTableProps,
  hasTooManyGoals,
} from 'views/goals_v2/utils';

/*
 * Table that loads employee goals for team members (based on `team`)
 */
export const TeamGoalsTableComponent = ({
  index,
  keyPrefix,
  team,
  filter = getEmptyFilter(),
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  columnConfigs,
  onColumnDrag,
  isGoalsV2DrawerOpen,
  onRowClick,
  onContributorsClick,
  onContinueGoalClick,
  hasScrolledToRow,
  setScrolledToRow,
  selectedGoalData,
  goalsHeaderData,
  setGoalsHeaderData,
  handlerAddFilterButtonClick,
}: GoalsTablePropsWithoutData & {
  index: number;
  keyPrefix: string;
  team: Team;
}) => {
  const teamSlug = team.slug ?? '';
  const teamsSet = new Set([team, ...filter.teams]);
  const {isFetched, goalsMap: filterTeamGoalsMap} = useTeamsGoalsSuspense({
    teams: Array.from(teamsSet),
    timeframe: timeframe,
  });
  const teamGoals = filterTeamGoalsMap[teamSlug] ?? [];
  const filteredTeamGoals = teamGoals.filter(filterTable(filter));

  const goalsHeaderDataString = JSON.stringify(goalsHeaderData);
  const filterString = JSON.stringify(filter);
  useEffect(() => {
    // After goals are fetched for team, store goals count for header
    if (isFetched && setGoalsHeaderData && teamSlug) {
      const goalsCountMap = goalsHeaderData?.goalsCountMap ?? {};
      const filteredGoalsCountMap = goalsHeaderData?.filteredGoalsCountMap ?? {};
      const teamsMap = goalsHeaderData?.teamsMap ?? {};
      goalsCountMap[teamSlug] = teamGoals.length;
      filteredGoalsCountMap[teamSlug] = filteredTeamGoals.length;
      teamsMap[teamSlug] = team;
      const newGoalsHeaderData: GoalsHeaderData = {
        ...goalsHeaderData,
        goalsCountMap,
        filteredGoalsCountMap,
        teamsMap,
      };
      setGoalsHeaderData(newGoalsHeaderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, goalsHeaderDataString, setGoalsHeaderData, filterString, timeframe]);

  if (goalsHeaderData && handlerAddFilterButtonClick && hasTooManyGoals(goalsHeaderData, filter)) {
    return index === 0 ? <NoFilterTable onButtonClick={handlerAddFilterButtonClick} /> : null;
  }

  return (
    <GoalsTable
      keyPrefix={keyPrefix}
      withTitle={team.name ?? ''}
      type="other"
      data={filteredTeamGoals}
      timeframe={timeframe}
      nextTimeframe={nextTimeframe}
      setNextTimeframe={setNextTimeframe}
      filter={filter}
      columnConfigs={columnConfigs}
      onColumnDrag={onColumnDrag}
      isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
      onRowClick={onRowClick}
      onContributorsClick={onContributorsClick}
      onContinueGoalClick={onContinueGoalClick}
      hasScrolledToRow={hasScrolledToRow}
      setScrolledToRow={setScrolledToRow}
      selectedGoalData={selectedGoalData}
      teamInfo={{teamSlug, timeframe}}
      {...getExpandedTableProps(true)}
    />
  );
};
