import {isMobileAtom} from 'atoms/layout';
import {atom} from 'jotai';
import {atomWithReset} from 'jotai/utils';

export type NewGoal = {
  isNewParentGoalSelected?: boolean;
  selectedParentGoalId?: number;
  selectedParentKeyResultId?: number;
  showAlignment: boolean;
  showSetPersonalGoalButton: boolean;
};

export const newGoalAtom = atomWithReset<NewGoal>({
  isNewParentGoalSelected: undefined,
  selectedParentGoalId: undefined,
  selectedParentKeyResultId: undefined,
  showAlignment: true,
  showSetPersonalGoalButton: true,
});

const showAIGoalAssistantDrawerAtom = atom(false);

// Derived atom that returns true if showAIGoalAssistantDrawerAtom and !isMobile
export const derivedShowAIGoalAssistantDrawerAtom = atom(
  (get) => get(showAIGoalAssistantDrawerAtom) && !get(isMobileAtom),
  (_, set, update: boolean) => {
    set(showAIGoalAssistantDrawerAtom, update);
  }
);

interface NewGoalBannerMap {
  [key: number]: boolean;
}

export const showNewBannerAtom = atom<NewGoalBannerMap>({});

interface NewDraftGoalBannerMap {
  [key: number]: boolean;
}

export const showNewDraftGoalBannerAtom = atom<NewDraftGoalBannerMap>({});
