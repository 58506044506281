import {Identify, identify, init, reset, setUserId, track} from '@amplitude/analytics-browser';
import {ROUTE_PATHS} from 'constant';
import {isProd} from 'helpers/environment';

import {AnalyticsKeys} from './keys';

const amplitudeApiKey = isProd
  ? 'e66da721f58ea768758f76a1c04d796b'
  : 'a2b0da7551dbf5cc5594f26877d355d9';

type EventData = {[key: string]: boolean | number | string | undefined | string[]};

class AmplitureClient {
  constructor() {
    init(amplitudeApiKey, {
      trackingOptions: {
        ipAddress: false,
      },
      defaultTracking: {
        sessions: true,
        attribution: false,
        formInteractions: false,
        fileDownloads: false,
        pageViews: {
          trackOn: () => {
            const supportedRoutes = [
              ROUTE_PATHS.GOALS,
              ROUTE_PATHS.PEOPLE,
              ROUTE_PATHS.TEAMS,
              ROUTE_PATHS.STRATEGIES,
              ROUTE_PATHS.PROJECTS,
              ROUTE_PATHS.GRATITUDE_BROWSE,
              ROUTE_PATHS.SETTINGS,
            ];
            return supportedRoutes.some((path) => window.location.pathname === path);
          },
        },
      },
    });
  }

  logEvent(name: AnalyticsKeys, data?: EventData) {
    if (!isProd) {
      if (data) {
        console.info('Analytics:', name, data);
      } else {
        console.info('Analytics:', name);
      }
    }
    track(name, data);
  }

  enable(userId: string) {
    setUserId(userId);
  }

  setProperty(key: string, value: string | null) {
    if (!value) {
      return;
    }
    const identifyEvent = new Identify();
    identifyEvent.set(key, value);
    identify(identifyEvent);
  }

  logout() {
    reset();
  }
}

let instance: AmplitureClient | undefined;
export const analyticsLogger = () => {
  if (!instance) {
    instance = new AmplitureClient();
  }
  return instance;
};
