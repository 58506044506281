import {Chip} from '@dropbox/dig-components/dist/chip';
import {Text} from '@dropbox/dig-components/dist/typography';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';

import styles from './NewsPostPreview.module.css';

interface NewsPostPreviewProps {
  title: string;
  category: string[];
  body: string;
}

export const NewsPostPreview = (props: NewsPostPreviewProps) => {
  return (
    <div>
      <Title className={styles.title} weightVariant="emphasized" size="large">
        {props.title}
      </Title>
      <Info category={props.category} />
      <RichTextArea value={props.body} autoFocus={false} theme="news" />
    </div>
  );
};

const Info = ({category}: {category: string[]}) => {
  const date = new Date();
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  const formattedDate = date.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);

  return (
    <div className={styles.info}>
      {category.map((cat) => (
        <Chip key={cat} size="small">
          <Chip.Content>{cat}</Chip.Content>
        </Chip>
      ))}
      <Text color="subtle">{formattedDate}</Text>
    </div>
  );
};
