import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Box} from '@dropbox/dig-foundations';
import {NewsPost} from 'client/models/NewsPost';
import {t} from 'i18next';

import styles from './NewsRow.module.css';
import {NewsThumbnail} from './NewsThumbnail';

interface NewsRowProps {
  id: NewsPost['id'];
  title: NewsPost['title'];
  category: NewsPost['category'];
  creationDate: NewsPost['created_at'];
  is_draft?: boolean;
  isCategoryView?: boolean;
}

export const NewsRow = ({
  id,
  title,
  category,
  creationDate,
  is_draft,
  isCategoryView,
}: NewsRowProps) => {
  const date = new Date(creationDate);
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  const formattedDate = date.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions);

  const categoryString = category[0] ? category[0].name : '';
  const categoryLabel = isCategoryView ? '' : categoryString ? categoryString + ' • ' : '';

  return (
    <Box
      as={LabelGroup}
      size="medium"
      withText={<Truncate lines={2}>{title}</Truncate>}
      withSubtext={
        is_draft ? (
          <DraftSubtext categoryString={categoryString} />
        ) : (
          <Box marginTop="2">{categoryLabel + formattedDate}</Box>
        )
      }
      withLeftAccessory={<NewsThumbnail id={id} isNewsFeed />}
    />
  );
};

export const DraftSubtext = ({categoryString}: {categoryString: string}) => (
  <Box className={styles.subtext}>
    {categoryString}
    <Box as={Chip} className={styles.draftChip} size="small">
      <Chip.Content>{t('draft')}</Chip.Content>
    </Box>
  </Box>
);
