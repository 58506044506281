import {Box} from '@dropbox/dig-foundations';
import {PulseResponseWithEmployee} from 'client';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {UpdatePost} from 'components/DSYS/UpdatePost';

export const PulseResponseBox = ({
  response,
  borderBottom,
}: {
  response: PulseResponseWithEmployee;
  borderBottom: boolean;
}) => {
  return (
    <Box borderBottom={borderBottom ? 'Solid' : undefined} borderColor="Border Subtle">
      <UpdatePost ldap={response.employee.ldap} update={response}>
        <RichTextArea value={response.response} />
      </UpdatePost>
    </Box>
  );
};
