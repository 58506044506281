import {Button} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {LockLine, PersonMultipleLine, TeamLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Employee, EmployeeFull, GoalCreate, GoalUpdate, ProfilePage, Team} from 'client';
import {GoalPrivacyList} from 'components/goals/privacy/GoalPrivacyList';
import {
  countUniqueEmployee,
  dedupeEmployeesOrTeams,
  dedupeTeam,
  excludeOwnerAndDelegate,
  hasPrivacyChanged,
  isDirectReport,
  isEmployeeTeam,
  sortEmployeesOrTeamsByName,
} from 'components/goals/privacy/utils';
import {PrivacySearchMenu} from 'components/shared/PrivacySearchMenu';
import {isEmployee, isTeam, useEmployees} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DrawerHeader} from 'views/goals_v2/Drawers/DrawerHeader';

import {DrawerOverlay} from './DrawerOverlay';

const EditGoalPrivacyDrawerHeader = ({
  onClose,
  onBack,
}: {
  onClose: () => void;
  onBack: () => void;
}) => {
  const {t} = useTranslation();
  return (
    <DrawerHeader
      headerTitle={t('privacy')}
      headerIcon={<Box as={UIIcon} src={LockLine} style={{marginLeft: '-3px'}} />}
      onClose={onClose}
      onBack={onBack}
    />
  );
};

export const EditGoalPrivacyDrawer = ({
  isOpen,
  onClose,
  onBack,
  onCustomPrivacySave,
  goalCreateValues,
  contributorLdaps,
  delegateId,
  delegateProfilePage,
}: {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onCustomPrivacySave: (selectedEmployeesOrTeams: (Employee | Team)[]) => void;
  goalCreateValues: GoalCreate | GoalUpdate;
  contributorLdaps: string[];
  delegateId?: string;
  delegateProfilePage?: ProfilePage;
}) => {
  const {employee, directReports, reportingLine, employeeTeams, delegates} =
    useAtomValue(loggedInEmployeeAtom);
  // const {data, isLoading} = useProfile({ldap: delegateId});

  const contributors = useEmployees({
    ldaps: contributorLdaps,
  });

  if (delegateId && delegateProfilePage) {
    return (
      <DrawerOverlay
        isOpen={isOpen}
        header={<EditGoalPrivacyDrawerHeader onClose={onClose} onBack={onBack} />}
        body={
          <GoalPrivacyBody
            goalCreateValues={goalCreateValues}
            onClose={onClose}
            onCustomPrivacySave={onCustomPrivacySave}
            employee={delegateProfilePage.employee}
            directReports={delegateProfilePage.directReports}
            reportingLine={delegateProfilePage.reportingLine}
            employeeTeams={delegateProfilePage.employeeTeams || []}
            delegates={delegateProfilePage.delegates || []}
            contributors={contributors}
          />
        }
      />
    );
  }

  return (
    <DrawerOverlay
      isOpen={isOpen}
      header={<EditGoalPrivacyDrawerHeader onClose={onClose} onBack={onBack} />}
      body={
        <GoalPrivacyBody
          goalCreateValues={goalCreateValues}
          onClose={onClose}
          onCustomPrivacySave={onCustomPrivacySave}
          employee={employee}
          directReports={directReports}
          reportingLine={reportingLine}
          employeeTeams={employeeTeams}
          delegates={delegates || []}
          contributors={contributors}
        />
      }
    />
  );
};

const GoalPrivacyBody = ({
  goalCreateValues,
  onClose,
  onCustomPrivacySave,
  employee,
  directReports,
  reportingLine,
  employeeTeams,
  delegates,
  contributors,
}: {
  onClose: () => void;
  onCustomPrivacySave: (selectedEmployeesOrTeams: (Employee | Team)[]) => void;
  goalCreateValues: GoalCreate | GoalUpdate;
  employee: EmployeeFull;
  directReports: Employee[];
  reportingLine: Employee[];
  employeeTeams: Team[];
  delegates: Employee[];
  contributors: Employee[];
}) => {
  const directReportsWithoutSelf = excludeOwnerAndDelegate(directReports, delegates, employee);
  const [employeeTeamChips, setEmployeeTeamChips] = useState<Team[]>(
    dedupeTeam(employeeTeams, goalCreateValues?.team_privacies) || []
  );
  const [directReportsChip, setDirectReportsChip] = useState<Employee[]>(
    directReportsWithoutSelf || []
  );

  const {t} = useTranslation();

  const [selectedEmployeesOrTeams, setSelectedEmployeesOrTeams] = useState<(Employee | Team)[]>(
    sortEmployeesOrTeamsByName(
      goalCreateValues?.individual_privacies,
      goalCreateValues?.team_privacies
    )
  );

  const onSelectEmployeeOrTeam = (employeeOrTeam: Employee | Team) => {
    setSelectedEmployeesOrTeams((prev) => [employeeOrTeam, ...prev]);
    if (isTeam(employeeOrTeam) && isEmployeeTeam(employeeOrTeam, employeeTeams)) {
      setEmployeeTeamChips((prev) => prev.filter((c) => c.team_id !== employeeOrTeam.team_id));
    }
  };

  const onRemoveEmployeeOrTeam = (employeeOrTeam: Employee | Team) => {
    setSelectedEmployeesOrTeams((prev) =>
      prev.filter((e) => {
        if (isEmployee(employeeOrTeam) && isEmployee(e)) {
          return e.user_id !== employeeOrTeam.user_id;
        } else if (isTeam(employeeOrTeam) && isTeam(e)) {
          return e.team_id !== employeeOrTeam.team_id;
        }
        return true;
      })
    );
    if (isTeam(employeeOrTeam) && isEmployeeTeam(employeeOrTeam, employeeTeams)) {
      setEmployeeTeamChips((prev) => [...prev, employeeOrTeam]);
    }
    if (isEmployee(employeeOrTeam) && isDirectReport(employeeOrTeam, directReportsWithoutSelf)) {
      setDirectReportsChip((prev) => [...prev, employeeOrTeam]);
    }
  };

  const onTeamChipClick = (team: Team) => {
    setSelectedEmployeesOrTeams((prev) => [team, ...prev]);
    setEmployeeTeamChips((prev) => prev.filter((c) => c.team_id !== team.team_id));
  };

  const onDirectReportsChipClick = () => {
    setSelectedEmployeesOrTeams((prev) => dedupeEmployeesOrTeams([...directReportsChip, ...prev]));
    setDirectReportsChip([]);
  };

  const teamCount = useMemo(
    () => dedupeEmployeesOrTeams(selectedEmployeesOrTeams).filter(isTeam).length,
    [selectedEmployeesOrTeams]
  );

  return (
    <>
      <Stack gap="12">
        <Stack display="flex" gap="8">
          <Cluster>
            <Box as={Text} isBold size="large">
              {t('custom')}
            </Box>
            <Toggletip title={t('choose_who_may_view_and_align_to_this_goal')} />
          </Cluster>
          <FormRow>
            <PrivacySearchMenu
              selectedEmployeesOrTeams={selectedEmployeesOrTeams}
              onSelectEmployeeOrTeam={onSelectEmployeeOrTeam}
              reportingLine={reportingLine}
              delegates={delegates}
            />
          </FormRow>
        </Stack>
        <Cluster
          paddingLeft="2"
          paddingRight="2"
          style={{display: 'flex', gap: '4px', marginTop: '-20px'}}
        >
          {directReportsChip &&
            directReportsChip.length > 0 &&
            directReportsWithoutSelf.length == directReportsChip.length && (
              <Chip variant="standard" style={{height: '24px'}} onClick={onDirectReportsChipClick}>
                <Chip.IconAccessory>
                  <UIIcon src={PersonMultipleLine} />
                </Chip.IconAccessory>
                <Chip.Content>{t('reports')}</Chip.Content>
              </Chip>
            )}
          {employeeTeamChips.map((team) => (
            <Chip
              key={team.team_id}
              variant="standard"
              style={{height: '24px'}}
              onClick={() => onTeamChipClick(team)}
            >
              <Chip.IconAccessory>
                <UIIcon src={TeamLine} />
              </Chip.IconAccessory>
              <Chip.Content>{team.name}</Chip.Content>
            </Chip>
          ))}
        </Cluster>
      </Stack>
      <Box
        as="div"
        style={{
          // border: '1px solid var(--dig-color__border__subtle)',
          width: '480px',
          height: '1px',
          backgroundColor: 'var(--dig-color__border__subtle)',
          marginTop: '20px',
          marginLeft: '-24px',
        }}
      />
      <Box
        as={Stack}
        gap="8"
        // borderTop="Solid"
        // borderColor="Border Subtle"
        // marginTop="20"
        paddingTop="20"
      >
        <Box as={Text} isBold>
          {t('people_count', {
            count: countUniqueEmployee(
              dedupeEmployeesOrTeams(selectedEmployeesOrTeams).filter(isEmployee),
              reportingLine,
              delegates
            ),
          })}
        </Box>
        {teamCount > 0 && (
          <>
            <Box as={Text}> {t('and')} </Box>
            <Box as={Text} isBold>
              {t('team', {count: teamCount})}
            </Box>
          </>
        )}
        <Box as={Text}> {t('may_view_and_align_to_this_goal')}</Box>
        <GoalPrivacyList
          goalOwner={employee}
          reportingLine={reportingLine}
          delegates={delegates}
          contributors={contributors}
          canDelete={true}
          selectedEmployeesOrTeams={dedupeEmployeesOrTeams(selectedEmployeesOrTeams)}
          onRemoveEmployeeOrTeam={onRemoveEmployeeOrTeam}
          padding={'12px 12px'}
          maxHeight="100%"
        />
      </Box>
      <Box marginTop="20">
        <Split gap="8" alignX="right">
          <Split.Item>
            <Button variant="opacity" onClick={onClose}>
              {t('cancel')}
            </Button>
          </Split.Item>
          <Split.Item>
            <Button
              variant="primary"
              onClick={() => onCustomPrivacySave(dedupeEmployeesOrTeams(selectedEmployeesOrTeams))}
              disabled={!hasPrivacyChanged(goalCreateValues, selectedEmployeesOrTeams)}
            >
              {t('save')}
            </Button>
          </Split.Item>
        </Split>
      </Box>
    </>
  );
};
