import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine} from '@dropbox/dig-icons/assets';
import {PlugMini} from '@dropbox/dig-illustrations';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import {t} from 'i18next';
import {useNavigate} from 'react-router-dom';

import styles from './NewGoalHeader.module.css';

export const NewGoalHeader = ({
  isEditing,
  goalCreated,
  delegateId,
  setDelegateId,
}: {
  isEditing?: boolean;
  goalCreated: boolean;
  delegateId?: string;
  setDelegateId: (userId?: string) => void;
}) => {
  const navigate = useNavigate();

  return (
    <Box style={{position: 'relative'}}>
      <Box as="div" style={{position: 'absolute', left: '-40px'}}>
        <IconButton
          variant="borderless"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Box as={UIIcon} src={ArrowLeftLine} />
        </IconButton>
      </Box>
      <Box display="flex" marginBottom="24">
        <Box display="flex" style={{gap: '8px'}} alignItems="center">
          <Box className={styles.plugMini} style={{width: '32px', height: '32px'}}>
            <PlugMini altText="plugMini" width={32} height={32} />
          </Box>
          <Box as={Text} size="large" isBold variant="label">
            {isEditing ? t('edit_goal') : t('create_a_new_goal')}
          </Box>
        </Box>
        <DelegateSelectMenu
          disabled={goalCreated || isEditing}
          delegateId={delegateId}
          setDelegateId={setDelegateId}
        />
      </Box>
    </Box>
  );
};
