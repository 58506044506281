import {AvatarSizes} from '@dropbox/dig-components/dist/avatar';
import {Text} from '@dropbox/dig-components/dist/typography';
import {withShade} from '@dropbox/dig-foundations';
import cx from 'classnames';
import {Avatar} from 'components/DSYS/Avatar';
import {Link} from 'components/DSYS/Link';
import {ROUTE_PATHS} from 'constant';
import {t} from 'i18next';

import styles from './ProfileCard.module.css';
import {ReportCountBadge} from './ReportCountBadge';

interface ProfileCardProps {
  profile_username?: string;
  team_id?: string;
  profile_photo_url?: string | null;
  display_name: string;
  profile_info: string;
  additional_profile_info?: string;
  size?: Exclude<AvatarSizes, 'medium' | 'standard'>;
  viewOnly?: boolean;
  isDeactivated?: boolean | null;
  directReportsCount?: number | null;
  totalReportsCount?: number | null;
  source?: string;
  onClick?: () => void;
  city?: string | null;
  showEmployeeCity?: boolean;
}

const ProfileCard = ({
  profile_username,
  team_id,
  profile_photo_url,
  display_name,
  profile_info,
  additional_profile_info,
  source,
  size,
  isDeactivated = false,
  viewOnly = false,
  directReportsCount = 0,
  totalReportsCount = 0,
  onClick,
  city,
  showEmployeeCity,
}: ProfileCardProps) => {
  const profileLink = profile_username
    ? `${ROUTE_PATHS.PEOPLE}/${profile_username}`
    : team_id
    ? `${ROUTE_PATHS.TEAMS}${team_id}`
    : '';

  return (
    <>
      <Link
        to={profile_username !== 'No email available' ? profileLink : '#'}
        state={{source: source ?? 'profile-card'}}
        hasNoUnderline
        isClickable={!isDeactivated && !viewOnly}
        onClick={onClick}
        {...withShade({
          className: cx(styles.profileLink, styles.profileContainer, {
            [styles.deactivatedContainer]: isDeactivated,
          }),
        })}
      >
        <div
          className={cx(styles.avatarContainer, {
            [styles.avatarContainerLarge]: size === 'large',
          })}
        >
          <Avatar user={{name: display_name, profile_photo_url}} size="large" />
          {directReportsCount && directReportsCount > 0 && totalReportsCount ? (
            <ReportCountBadge
              direct_reports_count={directReportsCount}
              total_report_count={totalReportsCount}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={styles.textContainer}>
          <Text
            isBold
            className={cx(styles.name, {
              [styles.deactivatedName]: isDeactivated,
            })}
          >
            {display_name !== ' ' ? display_name : profile_username}
          </Text>
          {showEmployeeCity && city && <Text size="small">{city}</Text>}
          {size === 'large' ? (
            <>
              <Text
                size="small"
                color="faint"
                className={cx(styles.name, {
                  [styles.deactivatedName]: isDeactivated,
                })}
              >
                {profile_info}
              </Text>
              {additional_profile_info && !isDeactivated && (
                <Text size="small" color="faint" isBold>
                  {additional_profile_info}
                </Text>
              )}
            </>
          ) : (
            <Text
              size="small"
              color="faint"
              className={cx(styles.name, {
                [styles.deactivatedName]: isDeactivated,
              })}
            >
              {profile_info}
            </Text>
          )}
          {isDeactivated && (
            <Text isBold className={styles.deactivatedText}>
              {t('inactive')}
            </Text>
          )}
        </div>
      </Link>
    </>
  );
};

export default ProfileCard;
