import {useTeamSuspense} from 'components/teams/hooks';
import {STEAM_TEAM_SLUG} from 'constant';
import {sortEmployees} from 'helpers/utils';
import {useEmployees} from 'hooks/useEmployee';
import {useEffect} from 'react';
import {GoalsTablesComponent} from 'views/goals_v2/TableComponents/GoalsTablesComponent';
import {GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {isCompanyGoalsSelected} from 'views/goals_v2/utils';

/*
 * Table view that loads employee goals for S-team as their own tables
 */
export const CompanyGoalsTableView = (tableProps: GoalsTablePropsWithoutData) => {
  const {filter, goalsHeaderData, setGoalsHeaderData, quickFilterSelected} = tableProps;
  const sTeam = useTeamSuspense({slug: STEAM_TEAM_SLUG.toString()});
  const sortedSTeam = useEmployees({
    ldaps: sTeam?.members.map((member) => member.ldap) ?? [],
  }).sort(sortEmployees);
  const sTeamLdaps = sortedSTeam.map(({ldap}) => ldap);
  const hasCompanyGoalsSelected = filter && isCompanyGoalsSelected(filter, quickFilterSelected);
  const ldaps = hasCompanyGoalsSelected ? sTeamLdaps : [];

  useEffect(() => {
    if (goalsHeaderData && setGoalsHeaderData && sTeamLdaps) {
      setGoalsHeaderData({
        ...goalsHeaderData,
        sTeamLdaps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(goalsHeaderData), setGoalsHeaderData, JSON.stringify(sTeamLdaps)]);

  const keyPrefix = 'company_goals';
  return <GoalsTablesComponent keyPrefix={keyPrefix} tableProps={tableProps} ldaps={ldaps} />;
};
