import {Employee, Goal, GoalRead, KeyResult, Team} from 'client';

export const isNumeric = (str: string) => {
  return !isNaN(parseFloat(str)) && isFinite(Number(str));
};

export const isSuperUser = (email?: string | null) => {
  return [
    'youcef@dropbox.com',
    'baochenh@dropbox.com',
    'swhitton@dropbox.com',
    'kshay@dropbox.com',
    'mtruong@dropbox.com',
    'lila@dropbox.com',
  ].includes(email ?? '');
};

export const isContentAdmin = (email?: string) => {
  return (
    [
      'jdeprey@dropbox.com',
      'neilmcmahon@dropbox.com',
      'robh@dropbox.com',
      'smagier@dropbox.com',
    ].includes(email ?? '') || isSuperUser(email)
  );
};

export const isContentEditor = (email?: string) => {
  return (
    [
      'enaumann@dropbox.com',
      'mckennah@dropbox.com',
      'mjulian@dropbox.com',
      'lizall@dropbox.com',
      'littlej@dropbox.com',
      'kellyc@dropbox.com',
      'hsinha@dropbox.com',
      'afisher@dropbox.com',
      'lorin@dropbox.com',
    ].includes(email ?? '') || isContentAdmin(email)
  );
};

// One way sha-256 hash of email address to be used as a unique identifier
export const hashEmail = async (email: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

export const isGoal = (selected: Goal | KeyResult): selected is Goal => {
  return (selected as Goal).key_results !== undefined;
};

export const isGoalType = (selected: Goal | KeyResult | GoalRead): selected is Goal | GoalRead => {
  return (
    (selected as Goal).key_results !== undefined || (selected as GoalRead).key_results !== undefined
  );
};

// Sorts employees by level, tenure_percentile, and name
export const sortEmployees = (a: Employee, b: Employee) => {
  // Sort by level in descending order
  const levelA = parseInt((a.level ?? '0').replace(/[^\d]/g, ''), 10);
  const levelB = parseInt((b.level ?? '0').replace(/[^\d]/g, ''), 10);
  if (levelB !== levelA) {
    return levelB - levelA;
  }

  // If levels are equal, sort by tenure
  if (a.tenure_percentile && b.tenure_percentile) {
    if (a.tenure_percentile < b.tenure_percentile) {
      return -1;
    }
    if (a.tenure_percentile > b.tenure_percentile) {
      return 1;
    }
  }

  // If hire dates are equal or either is missing, sort by name
  return a.name.localeCompare(b.name);
};

// Sorts team by name
export const sortTeams = (a: Team, b: Team) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
};

export const sortEmployeesSelfFirst = (employee: Employee) => (a: Employee, b: Employee) => {
  return a.ldap === employee.ldap ? -1 : b.ldap === employee.ldap ? 1 : sortEmployees(a, b);
};

export const isGoalPrivate = (goal: Goal): boolean => {
  return !!goal.private || !!goal.is_custom_privacy_included;
};
