import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {Menu, WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  DropboxLine,
  FilterLine,
  PersonLine,
  PersonMultipleLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {isMobileAtom} from 'atoms/layout';
import {Team} from 'client';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {STEAM_TEAM_NAME} from 'constant';
import {useEmployees} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {LegacyRef, ReactNode} from 'react';
import {GoalEmployeeSearch} from 'views/goals_v2/GoalEmployeeSearch';
import {GoalTeamSearch} from 'views/goals_v2/GoalTeamSearch';
import {GoalsTableFilter, SelectedKey} from 'views/goals_v2/types';
import {getEmptyFilter, hasPrimaryFiltersSelected, statusOptions} from 'views/goals_v2/utils';

type Filter = {type: keyof GoalsTableFilter; value: string | number | string[]};

const FilterSection = ({
  options,
  section,
  filter,
}: {
  section: string;
  options: {label?: string | ReactNode; option: Filter}[];
  filter: GoalsTableFilter;
  handleSelection?: (filter: Filter) => void;
}) => {
  const isSelected = (data: Filter) => {
    if (data.type === 'teams') {
      const teamNames = filter[data.type].map((team) => team.name);
      return teamNames.includes(data.value.toString());
    }
    return !!filter[data.type]?.includes(data.value.toString());
  };

  return (
    <Box>
      <Box
        as={Text}
        isBold
        size="small"
        color="Text Subtle"
        paddingY="4"
        paddingX="16"
        display="block"
      >
        {section}
      </Box>
      {['teams', 'people'].includes(options[0].option.type) ? (
        <Menu.Row>{options[0].label}</Menu.Row>
      ) : (
        options.map(({label, option}) => (
          <Box
            as={Menu.ActionItem}
            borderRadius="Small"
            key={`${option.type}-${option.value}`}
            value={option}
            role="menuitemcheckbox"
            aria-checked={isSelected(option)}
            withLeftAccessory={<Checkbox checked={isSelected(option)} readOnly tabIndex={-1} />}
          >
            <Text isBold={isSelected(option)}>{label ?? t(option.value.toString())}</Text>
          </Box>
        ))
      )}
    </Box>
  );
};

export const FilterChips = ({
  filter,
  setFilter,
  handleQuickFilterSelected,
}: {
  filter: GoalsTableFilter;
  setFilter: (filter: GoalsTableFilter, shouldNotUpdateUrl?: boolean) => void;
  handleQuickFilterSelected?: (selectedItem: SelectedKey, newFilter?: GoalsTableFilter) => void;
}) => {
  const employees = useEmployees({ldaps: filter.people});

  const getLabel = (value: string) => {
    if (value === 'reports=1') {
      return t('your_reports');
    }
    return t(value);
  };

  const getLabelIcon = (type: string, value: string | null) => {
    if (value === STEAM_TEAM_NAME) {
      return <UIIcon src={DropboxLine} />;
    }
    if (type === 'reports' && value === '1') {
      return <UIIcon src={PersonMultipleLine} />;
    }
    if (type === 'people') {
      return <UIIcon src={PersonLine} />;
    }
    return <UIIcon src={TeamLine} />;
  };

  return (
    <Split alignY="center" paddingTop="4">
      <Split gap="4" alignX="right" style={{flexWrap: 'wrap'}}>
        {Object.entries(filter).map(([type, values]) => {
          if (type === 'reports') {
            if (values === '0') {
              return null;
            }
            return (
              <Split.Item key={`chip-${type}-${values}`}>
                <Chip
                  isSelected
                  size="small"
                  onDelete={() => {
                    const newFilter = {...filter, [type]: '0'};
                    setFilter(newFilter);
                    if (handleQuickFilterSelected && !hasPrimaryFiltersSelected(newFilter)) {
                      handleQuickFilterSelected('others', newFilter);
                    }
                  }}
                >
                  <Chip.IconAccessory>{getLabelIcon(type, values as string)}</Chip.IconAccessory>
                  <Chip.Content>{getLabel(`${type}=${values as string}`)}</Chip.Content>
                </Chip>
              </Split.Item>
            );
          }
          if (type === 'people') {
            return (values as string[]).map((value) => {
              if (!value) {
                return null;
              }
              return (
                <Split.Item key={`chip-${type}-${value}`}>
                  <Chip
                    isSelected
                    size="small"
                    onDelete={() => {
                      const toUpdate = [...(filter[type as keyof GoalsTableFilter] as string[])];
                      const index = toUpdate.indexOf(value);
                      toUpdate.splice(index, 1);

                      const newFilter = {...filter, [type]: toUpdate};
                      setFilter(newFilter);
                      if (handleQuickFilterSelected && !hasPrimaryFiltersSelected(newFilter)) {
                        handleQuickFilterSelected('others', newFilter);
                      }
                    }}
                  >
                    <Chip.IconAccessory>{getLabelIcon(type, value)}</Chip.IconAccessory>
                    <Chip.Content>
                      {employees.find((employee) => employee.ldap === value)?.name ?? value}
                    </Chip.Content>
                  </Chip>
                </Split.Item>
              );
            });
          }
          if (type === 'teams') {
            return (values as Team[]).map((value) => {
              if (!value) {
                return null;
              }
              return (
                <Split.Item key={`chip-${type}-${value.team_id}`}>
                  <Chip
                    isSelected
                    size="small"
                    onDelete={() => {
                      const toUpdate = [...(filter[type as keyof GoalsTableFilter] as Team[])];
                      const index = toUpdate.findIndex((team) => team.team_id === value.team_id);
                      if (index !== -1) {
                        toUpdate.splice(index, 1);
                      }
                      const newFilter = {...filter, [type]: toUpdate};
                      setFilter(newFilter);
                      if (handleQuickFilterSelected && !hasPrimaryFiltersSelected(newFilter)) {
                        handleQuickFilterSelected('others', newFilter);
                      }
                    }}
                  >
                    <Chip.IconAccessory>{getLabelIcon(type, value.name)}</Chip.IconAccessory>
                    <Chip.Content>{value.name}</Chip.Content>
                  </Chip>
                </Split.Item>
              );
            });
          }
          return type === 'search'
            ? null
            : (values as string[]).map((value) => {
                return (
                  <Split.Item key={`chip-${type}-${value}`}>
                    <Chip
                      isSelected
                      size="small"
                      onDelete={() => {
                        const toUpdate = filter[type as keyof GoalsTableFilter] as string[];
                        const index = toUpdate.indexOf(value);
                        toUpdate.splice(index, 1);

                        setFilter({...filter, [type]: toUpdate});
                      }}
                    >
                      {type === 'status' && (
                        <Chip.IconAccessory>
                          <StatusButtonIcon status={value} />
                        </Chip.IconAccessory>
                      )}
                      {type === 'teams' && (
                        <Chip.IconAccessory>
                          <UIIcon src={TeamLine} />
                        </Chip.IconAccessory>
                      )}
                      <Chip.Content>{getLabel(value)}</Chip.Content>
                    </Chip>
                  </Split.Item>
                );
              });
        })}
      </Split>
    </Split>
  );
};

const getFilterTypes = (
  teams: Team[] | undefined,
  handleSetTeams: (teams: Team[]) => void,
  employees: string[] | undefined,
  handleSetEmployees: (employees: string[]) => void
): {[key: string]: {label?: string | ReactNode; option: Filter}[]} => ({
  teams: [
    {
      label: <GoalTeamSearch teams={teams} handleTeamSelected={handleSetTeams} />,
      option: {type: 'teams', value: []},
    },
  ],
  people: [
    {
      label: (
        <GoalEmployeeSearch employees={employees} handleEmployeeSelected={handleSetEmployees} />
      ),
      option: {
        type: 'people',
        value: '',
      },
    },
  ],
  status: statusOptions
    .flatMap((option) => option.statusOptions)
    .map(({value, color, icon}) => ({
      label: (
        <Box display="flex" alignItems="center">
          <UIIcon src={icon} color={color} />
          <Box paddingX="8">{t(value)}</Box>
        </Box>
      ),
      option: {type: 'status', value: value},
    })),
  updated: [
    {
      option: {type: 'updated', value: 'filter_less_than_45_days'},
    },
    {
      option: {type: 'updated', value: 'filter_more_than_45_days'},
    },
  ],
});

export const TableFilter = ({
  filter,
  setFilter,
  handleQuickFilterSelected,
  filterButtonRef,
}: {
  filter: GoalsTableFilter;
  setFilter: (filter: GoalsTableFilter, shouldNotUpdateUrl?: boolean) => void;
  handleQuickFilterSelected: (
    selectedItem: SelectedKey,
    newFilter?: GoalsTableFilter,
    openFilter?: boolean
  ) => void;
  filterButtonRef?: LegacyRef<WrapperRefObject>;
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  const handleSelection = ({type, value}: Filter) => {
    const toUpdate = filter[type] as string[];
    const index = toUpdate.indexOf(value as string);
    if (index === -1) {
      toUpdate.push(value as string);
    } else {
      toUpdate.splice(index, 1);
    }
    setFilter({...filter, [type]: toUpdate});
  };

  const handleSetTeams = (teams: Team[]) => {
    const newFilter = {...filter, teams};
    setFilter(newFilter);
    if (!hasPrimaryFiltersSelected(newFilter)) {
      handleQuickFilterSelected('others', newFilter);
    }
  };

  const handleSetEmployees = (people: string[]) => {
    const newFilter = {...filter, people};
    setFilter(newFilter);
    if (!hasPrimaryFiltersSelected(newFilter)) {
      handleQuickFilterSelected('others', newFilter);
    }
  };

  const filterTypes = getFilterTypes(
    filter.teams,
    handleSetTeams,
    filter.people,
    handleSetEmployees
  );

  return (
    <Menu.Wrapper
      closeOnSelection={false}
      isPortaled={false}
      onSelection={handleSelection}
      ref={filterButtonRef}
    >
      {({getContentProps, getTriggerProps}) => (
        <>
          <Box as="div" display="flex">
            <Box
              as={IconButton}
              {...getTriggerProps()}
              shape="circular"
              variant="transparent"
              marginLeft="8"
              marginTop="0"
              style={{minWidth: '32px', minHeight: '32px'}}
            >
              <UIIcon src={FilterLine} />
            </Box>
          </Box>
          <Menu.Content {...getContentProps()} placement="bottom-end">
            <Box
              as="div"
              paddingLeft="4"
              paddingRight="12"
              paddingBottom="16"
              paddingTop="8"
              style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? 'repeat(1fr, 3)' : 'repeat(3, 1fr)',
                gridGap: '8px',
              }}
            >
              <Box as={Stack} gap="12" style={{flex: 1, minWidth: 276}}>
                <FilterSection section="Teams" options={filterTypes.teams} filter={filter} />
                <FilterSection section="People" options={filterTypes.people} filter={filter} />
              </Box>
              <Box
                borderLeft="Solid"
                borderColor="Border Subtle"
                borderWidth={isMobile ? '0' : '1'}
                paddingLeft={isMobile ? '0' : '8'}
                style={{minWidth: 276}}
              >
                <FilterSection section="Status" options={filterTypes.status} filter={filter} />
              </Box>
              <Box
                as={Stack}
                gap="12"
                borderLeft="Solid"
                borderColor="Border Subtle"
                borderWidth={isMobile ? '0' : '1'}
                paddingLeft={isMobile ? '0' : '8'}
                style={{minWidth: 267}}
              >
                <FilterSection
                  section={t('filter_last_updated')}
                  options={filterTypes.updated}
                  filter={filter}
                />
                <Box
                  as={Button}
                  variant="outline"
                  size="small"
                  color="Text Subtle"
                  position={isMobile ? undefined : 'absolute'}
                  marginLeft="16"
                  style={{bottom: isMobile ? 0 : 20}}
                  onClick={() => {
                    const newFilter = getEmptyFilter();
                    setFilter(newFilter);
                    if (handleQuickFilterSelected && !hasPrimaryFiltersSelected(newFilter)) {
                      handleQuickFilterSelected('others', newFilter, true);
                    }
                  }}
                >
                  <Text size="small" color="subtle" isBold>
                    {t('clear_all')}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
