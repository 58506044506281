import '@dropbox/dig-components/dist/index.web.css';
import '@dropbox/dig-fonts/dist/index.min.css';
import '@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css';
import '@dropbox/dig-foundations/dist/index.css';
import './App.css';
import './dig-overrides/index.module.css';
import './translations/i18n';

import {Dropbox} from '@dropbox/api-v2-client';
import {Modal} from '@dropbox/dig-components/modal';
import {GrowthBookProvider} from '@growthbook/growthbook-react';
import {ApiError} from 'client';
import {getAPIForLoggedInUser} from 'helpers/api';
import {reportAndLogError} from 'helpers/logging';
import {useSetAtom} from 'jotai';
import {createContext, useEffect, useState} from 'react';
import {RouterProvider} from 'react-router-dom';
import {emailToLdap, getAuthService, getProfileImageUrl} from 'utilities';

import {isPrivatePageAtom, pulseTokenAtom, pulseUserAtom} from './atoms/auth';
import {growthbook} from './helpers/growthbook';
import {hashEmail} from './helpers/utils';
import {router} from './routes';

// 100vh and 100vw goes beyond the actual window.innerHeight and
// window.innerWidth values. Instead of 100vh and 100vw, we should
// use var(--full-inner-height) and var(--full-inner-width).
const getFullWindowDimensionsCss = () => {
  return `:root {--full-inner-height:${window.innerHeight}px;--full-inner-width:${window.innerWidth}px}`;
};
const windowDimensionsVariables = document.createElement('style');
windowDimensionsVariables.innerHTML = getFullWindowDimensionsCss();
window.addEventListener('resize', () => {
  windowDimensionsVariables.innerHTML = getFullWindowDimensionsCss();
});
document.body.appendChild(windowDimensionsVariables);

export const DropboxClientContext = createContext<Dropbox | null>(null);

const root = document.getElementById('root');
if (root) Modal.setAppElement(root);

const App = () => {
  const [dropboxClient, setDropboxClient] = useState<Dropbox | null>(null);
  const setIsAuthRequired = useSetAtom(isPrivatePageAtom);
  const setPulseToken = useSetAtom(pulseTokenAtom);
  const setPulseUser = useSetAtom(pulseUserAtom);

  useEffect(() => {
    const loadCurUser = async () => {
      const [loggedInUser, pulseToken] = await Promise.all([
        getAuthService().readUserMeApiV1AuthMeGet(),
        getAuthService().getTokenApiV1AuthTokenGet(),
      ]);
      setDropboxClient(await getAPIForLoggedInUser(loggedInUser.token));
      setPulseToken(pulseToken);
      const ldap = emailToLdap(loggedInUser.email);
      setPulseUser({
        ...loggedInUser,
        profile_photo_url: getProfileImageUrl(ldap),
        ldap,
      });
      // lazily import the amplitude client to not impact ttvc
      const {analyticsLogger} = await import('./analytics/analyticsLogger');

      const hashedEmail = await hashEmail(loggedInUser.email);
      analyticsLogger().enable(hashedEmail);
      analyticsLogger().logEvent('LOGIN_SUCCESS', {email: hashedEmail});
    };

    loadCurUser().catch(async (e) => {
      if (e instanceof ApiError) {
        if (e.status === 403) {
          // auth error, kick them out
          setIsAuthRequired(true);
          setPulseToken('');
          const {analyticsLogger} = await import('./analytics/analyticsLogger');
          analyticsLogger().logEvent('LOGIN_FAILED', {email: 'unknown'});
          return;
        }
        reportAndLogError(e, 'Error loading current user');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <DropboxClientContext.Provider value={dropboxClient}>
        <RouterProvider router={router} />
      </DropboxClientContext.Provider>
    </GrowthBookProvider>
  );
};

export default App;
