import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CelebrateLine,
  CommentLine,
  GratitudeLine,
  InfoLine,
  PulseLine,
  TargetLine,
} from '@dropbox/dig-icons/assets';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {TimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {Trans} from 'react-i18next';

const iconMap = {
  gratitude: GratitudeLine,
  birthday: CelebrateLine,
  okr: TargetLine,
  generic: InfoLine,
  pulse: PulseLine,
};

export const GenericNotification = () => {
  const {
    archiveable,
    upcoming,
    notification: {data: notification, archived, updated_at},
  } = useNotificationContext();
  const {content, to, icon, cta, data} = notification;
  return (
    <NotificationCard
      accessory={
        <Box
          as={UIIcon}
          src={
            Object.keys(iconMap).includes(icon)
              ? iconMap[icon as keyof typeof iconMap]
              : CommentLine
          }
          color="Text Subtle"
        />
      }
      header={<Trans i18nKey={content} t={t} values={{data}} components={{b: <Text isBold />}} />}
      subtext={
        upcoming ? (
          Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            timeZone: 'UTC',
          }).format(new Date(updated_at))
        ) : (
          <TimeAgo timestamp={updated_at} />
        )
      }
      to={to}
      archived={archived}
      buttonLink={!archived && !archiveable && !upcoming ? {to, cta} : undefined}
    />
  );
};
