import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {Project, TeamWithHierarchy} from 'client';
import {LinkStyledButton} from 'components/DSYS/Link';

export const EmptyTable = ({
  // team,
  project,
  onFilterReset,
}: {
  team?: TeamWithHierarchy;
  project?: Project;
  onFilterReset: () => void;
}) => (
  <Box
    as="div"
    paddingX="24"
    paddingY="20"
    borderRadius="Medium"
    borderColor="Border Subtle"
    borderStyle="Solid"
    borderWidth="1"
    maxWidth="100%"
    marginX="auto"
    style={{width: 1200}}
  >
    <Box
      width="100%"
      display="block"
      borderLeft="Solid"
      borderColor="Border Subtle"
      borderWidth="1"
      margin="2"
      paddingY="2"
      paddingLeft="16"
      style={{maxWidth: 1151}}
    >
      <Text tagName="div" color="faint" isBold>
        Nothing found!
      </Text>
      {project?.id ? //     to={ROUTE_PATHS.PROJECT_EDIT.replace(/:identifier/, project.id.toString())} //   <Link //   Add an epic by{' '} // <Text color="faint" size="small">
      //     state={{autofocus: 'epics'}}
      //   >
      //     <Text color="faint" isBold size="small">
      //       editing this project
      //     </Text>
      //   </Link>
      // </Text>
      null : (
        <Text color="faint" size="small">
          Broaden your filters <LinkStyledButton onClick={onFilterReset}>reset</LinkStyledButton>
          {/* or{' '}
          <Link
            monochromatic
            isBold
            to={ROUTE_PATHS.PROJECT_NEW}
            state={{team_id: team?.team_id ?? project?.team?.team_id}}
          >
            add a project
          </Link> */}
        </Text>
      )}
    </Box>
  </Box>
);
