import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {LockLine} from '@dropbox/dig-icons/assets';
import {GoalData} from 'client';
import {t} from 'i18next';
import {ComponentWithLeftBullet} from 'views/goals_v2/icons';
import {formatTimeframe, shouldShowConfidentialDetails} from 'views/goals_v2/utils';

export const GoalHeaderDetails = ({
  isGoalRow,
  goal,
  currentRowTimeframe,
  numKeyResults,
  numSubGoals,
  setShowConfidentialDetails,
}: {
  isGoalRow: boolean;
  goal: GoalData;
  currentRowTimeframe?: string;
  numKeyResults: number;
  numSubGoals: number;
  setShowConfidentialDetails?: (show: boolean) => void;
}) => {
  return (
    <Box as="div" display="flex" alignItems="center">
      {currentRowTimeframe && (
        <Text color="subtle" size="small">
          {formatTimeframe(currentRowTimeframe)}
        </Text>
      )}
      {!!numKeyResults && (
        <ComponentWithLeftBullet>
          <Box>
            <Text color="subtle" size="small">
              {t('key_result', {count: numKeyResults})}
            </Text>
          </Box>
        </ComponentWithLeftBullet>
      )}
      {!!numSubGoals && (
        <ComponentWithLeftBullet>
          <Box>
            <Text color="subtle" size="small">
              {t('subgoal_count', {count: numSubGoals})}
            </Text>
          </Box>
        </ComponentWithLeftBullet>
      )}
      {isGoalRow && shouldShowConfidentialDetails(goal) && (
        <ComponentWithLeftBullet>
          <Button
            variant="transparent"
            size="small"
            color="faint"
            onClick={() => setShowConfidentialDetails?.(true)}
            hasNoUnderline={!setShowConfidentialDetails}
          >
            <Box display="flex" alignItems="center">
              <Box
                as={UIIcon}
                src={LockLine}
                marginRight="2"
                style={{color: 'var(--dig-color__text__subtle)'}}
                size="small"
              />
              <Text size="small" color="faint" variant="label">
                {t('private')}
              </Text>
            </Box>
          </Button>
        </ComponentWithLeftBullet>
      )}
    </Box>
  );
};
