import {Stack} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {ProfilePage} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {ProfileBadges} from 'components/profile/ProfileBadges';
import {ProfileBody} from 'components/profile/ProfileBody';
import {ProfileEmployees} from 'components/profile/ProfileEmployees';
import {ProfileReportingTree} from 'components/profile/ProfileReportingTree';
import {ProfileWorkingWithMe} from 'components/profile/ProfileWorkingWithMe';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {NotFound} from 'pages/NotFound';
import {useEffect} from 'react';
import {useLoaderData, useLocation} from 'react-router-dom';

interface ProfileViewProps {
  user?: string;
}

export const ProfileView = ({user}: ProfileViewProps) => {
  const props = useLoaderData() as ProfilePage;
  const location = useLocation();
  useDocumentTitle(props?.employee.name ?? '');

  useEffect(() => {
    if (props.employee) {
      analyticsLogger().logEvent('PROFILE_OPENED', {
        level: props.employee.level ?? undefined,
        source: location.state?.source,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.employee]);

  if (!user || !props || !props.employee) {
    return <NotFound />;
  }

  if (props.employee.is_deactivated) {
    return <NotFound />;
  }

  const directReportLdaps = props.directReports.map((employee) => employee.ldap);
  const peers = (props.teamMembers || []).filter((teamMember) => {
    const isManager = teamMember.user_id === props.employee.manager_id;
    const isDirectReport = directReportLdaps.indexOf(teamMember.ldap) !== -1;
    // Peer cannot be user's direct report or manager
    return !isManager && !isDirectReport;
  });

  // TODO: Add loading state
  return (
    <Layout.Container size="large">
      <Stack gap="24">
        <ProfileBody
          employee={props.employee}
          employeeTeams={props.employeeTeams}
          admin={props.admin}
        />
        {props.reportingLine && props.reportingLine.length > 1 && (
          <ProfileReportingTree reportingLine={props.reportingLine} />
        )}
        {props.directReports && props.directReports.length > 0 && (
          <ProfileEmployees
            sectionTitle={t('reports')}
            employees={props.directReports}
            employee={props.employee}
            source="reports"
          />
        )}
        {peers.length > 0 && (
          <ProfileEmployees
            sectionTitle={t('peers')}
            employees={peers}
            employee={props.employee}
            source="peers"
          />
        )}
        <ProfileWorkingWithMe employee={props.employee} workingWithMe={props.workingWithMe} />
        <ProfileBadges ldap={user} />
      </Stack>
    </Layout.Container>
  );
};
