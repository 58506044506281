import {TrafficRoadBlockedSpot} from '@dropbox/dig-illustrations';
import {ApiError} from 'client';
import {NonIdealState} from 'components/shared/NonIdealState';
import {ROUTE_PATHS} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {t} from 'i18next';
import {useCallback} from 'react';
import {isRouteErrorResponse, useNavigate, useRouteError} from 'react-router-dom';
import {getLoginUrl} from 'utilities';

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const redirectToLogin = useCallback(() => {
    const loginUrl = getLoginUrl();
    const currentPath = window.location.pathname.startsWith(ROUTE_PATHS.LOGOUT)
      ? ''
      : window.location.pathname.substring(1) + window.location.search + window.location.hash;

    window.location.href = loginUrl + currentPath;
  }, []);

  const handleApiError = useCallback(
    (apiError: ApiError) => {
      if (apiError.status === 403) {
        redirectToLogin();
      }
      // Handle other API error statuses if needed
    },
    [redirectToLogin]
  );

  if (isRouteErrorResponse(error)) {
    if (error.status === 403) {
      redirectToLogin();
    } else if (error.status === 404) {
      navigate('/404');
    } else {
      reportAndLogError(error);
    }
    return <></>;
  }

  if (error instanceof ApiError && error.status === 403) {
    handleApiError(error);
    return <></>;
  }

  return (
    <NonIdealState
      image={<TrafficRoadBlockedSpot />}
      title={t('error_page_title')}
      description={t('check_the_url_and_try_again')}
    />
  );
};

export {ErrorPage};
