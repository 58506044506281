import './reset.css';

import {ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import * as Sentry from '@sentry/react';
import {CurrentEnvironment, getCurrentEnvironment} from 'helpers/environment';
import {useAtomValue} from 'jotai';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import {loadTTVCTracker} from './analytics/ttvc';
import App from './App';
import {derivedThemeAtom} from './atoms/layout';
import {useSetThemeWatcher} from './hooks/useSetThemeWatcher';
import reportWebVitals from './reportWebVitals';

const environment =
  getCurrentEnvironment() === CurrentEnvironment.Production ? 'production' : 'development';
const sampleRate = environment === 'production' ? 0.1 : 1.0;

Sentry.init({
  dsn: 'https://a5cfabea39d9d36e045f0ca6198e16be@d.dropbox.com/4507113446506496',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: environment,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://*.dropbox.com', 'https://*.dropboxpulse.com'],
  // Session Replay
  replaysSessionSampleRate: sampleRate,
  replaysOnErrorSampleRate: 1.0, // Capture 100% of the errors
});

loadTTVCTracker();

const AppWrapper = ({children}: {children: React.ReactNode}) => {
  useSetThemeWatcher();

  return (
    <ThemeProvider mode={useAtomValue(derivedThemeAtom)} theme="vis2023">
      <ThemeContainer rootElement={document.documentElement}>{children}</ThemeContainer>
    </ThemeProvider>
  );
};

const Applicaton = () => {
  return (
    <StrictMode>
      <AppWrapper>
        <App />
      </AppWrapper>
    </StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Applicaton />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
