import {Button} from '@dropbox/dig-components/dist/buttons';
import {List} from '@dropbox/dig-components/dist/list';
import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {NewsPost} from 'client';
import {NewsRow} from 'components/news/NewsRow';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {useDraftsSuspense, useFeedSuspense} from 'views/news/hooks';

interface NewsListProps {
  isEditorView?: boolean;
  viewCategory?: string;
  selected?: number;
}

export const NewsList = (props: NewsListProps) => {
  const [startingPosts] = useLoaderData() as [NewsPost[]];
  const [selectedNewsPostId, setSelectedNewsPostId] = useState<number | undefined>();
  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const drafts = useDraftsSuspense();
  const navigate = useNavigate();

  const [posts, setPosts] = useState<NewsPost[]>([]);
  const [skip, setSkip] = useState(25);
  const limit = 25;
  const data = useFeedSuspense(skip, limit, props.viewCategory);

  const handleViewMore = () => {
    analyticsLogger().logEvent('NEWS_VIEW_MORE_CLICKED');
    if (data?.length) {
      setPosts([...posts, ...data]);
      setSkip(skip + limit);
    } else {
      setShowViewMore(false);
    }
  };

  useEffect(() => {
    setSelectedNewsPostId(props.selected);
  }, [props.selected]);

  const feedPosts = props.isEditorView ? [...drafts, ...startingPosts] : startingPosts;

  return (
    <Box as={List} isSelectable borderRadius="XLarge" height="0">
      {[...feedPosts, ...posts].map((newspost) => (
        <Box
          as={List.Item}
          borderRadius="Medium"
          key={newspost.is_draft ? `draft_${newspost.id}` : `listitem_${newspost.id}`}
          isSelected={selectedNewsPostId === newspost.id}
          onClick={() => {
            setSelectedNewsPostId(newspost.id);
            navigate('/news/' + newspost.id);
          }}
          padding="12"
        >
          <NewsRow
            id={newspost.id}
            title={newspost.title}
            category={newspost.category}
            creationDate={newspost.created_at}
            is_draft={newspost.is_draft ?? false}
            isCategoryView={!!props.viewCategory}
          />
        </Box>
      ))}
      {showViewMore && (
        <Button
          style={{
            marginTop: '8px',
            marginBottom: '16px',
            marginLeft: '12px',
          }}
          variant="transparent"
          size="medium"
          onClick={handleViewMore}
        >
          {t('view_more')}
        </Button>
      )}
    </Box>
  );
};
