import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine, TeamLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {Employee, Team} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {isDev} from 'helpers/environment';
import {isEmployee, isTeam, useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {FunctionComponent, useState} from 'react';

interface Props {
  selectedEmployeesOrTeams: (Employee | Team)[];
  onSelectEmployeeOrTeam: (employeeOrTeam: Employee | Team) => void;
  className?: string;
  autoFocus?: boolean;
  placeholderText?: string;
  disable?: boolean;
  allowSelf?: boolean;
  showReportingLine?: boolean;
  reportingLine: Employee[];
  delegates: Employee[];
  iconMarginTop?: string;
}

export const PrivacySearchMenu: FunctionComponent<Props> = ({
  autoFocus = false,
  selectedEmployeesOrTeams,
  onSelectEmployeeOrTeam,
  placeholderText = t('search_individuals_or_groups'),
  disable = false,
  allowSelf = false,
  showReportingLine = false,
  reportingLine,
  delegates,
  iconMarginTop,
}) => {
  const [userInputValue, setUserInputValue] = useState<string>('');
  const searchResult = useGlobalSearch({input: userInputValue, filter: 'people', showMore: true});
  const user = useAtomValue(pulseUserAtom);

  const searchedEmployeeResult = searchResult.map((result) => result as Employee);
  const employeeHints = (
    searchedEmployeeResult.length
      ? searchedEmployeeResult
      : !userInputValue.length && showReportingLine
      ? reportingLine
      : []
  ).filter((e) => {
    if (
      isEmployee(e) &&
      selectedEmployeesOrTeams.find(
        (selected) => isEmployee(selected) && selected.user_id == e.user_id
      )
    )
      return false;
    if (
      isEmployee(e) &&
      reportingLine.find((selected) => isEmployee(selected) && selected.user_id == e.user_id)
    )
      return false;
    if (
      isEmployee(e) &&
      delegates &&
      delegates.find((selected) => isEmployee(selected) && selected.user_id == e.user_id)
    )
      return false;
    if (isDev) return true;
    if (allowSelf) return true;
    return e.ldap != user?.email.split('@')[0];
  });

  const searchedTeamResults = useGlobalSearch({
    input: userInputValue,
    filter: 'teams',
    showMore: false,
  });
  // convert searchedTeamResults to Team[]
  const searchedTeams = searchedTeamResults.map((result) => result as Team);
  const searchedTeamsFiltered = searchedTeams.filter((team) => {
    if (
      selectedEmployeesOrTeams.find(
        (selected) => isTeam(selected) && selected.team_id == team.team_id
      )
    )
      return false;
    return true;
  });

  const combinedResults = [...employeeHints, ...searchedTeamsFiltered];

  const handleSelection = ({type, id}: {type: string; id: string}) => {
    if (type === 'employee') {
      const found = employeeHints.find((e) => e.ldap === id);
      if (found) {
        onSelectEmployeeOrTeam(found);
      }
    } else if (type === 'team') {
      const found = searchedTeams.find((e) => e.team_id === id);
      if (found) {
        onSelectEmployeeOrTeam(found);
      }
    }
    setUserInputValue('');
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const renderSubtitle = (result?: string | null) => {
    return (
      <>
        <Text size="small" color="faint">
          {result}
        </Text>
      </>
    );
  };

  const renderTitle = (result?: string | null) => {
    return (
      <Box marginY={'8'}>
        <Text isBold>
          <Truncate lines={1}>{result}</Truncate>
        </Text>
      </Box>
    );
  };

  const renderTypeaheadRow = (item: Employee | Team) => {
    if (isTeam(item)) {
      return (
        <Typeahead.Row
          key={item.team_id}
          value={{type: 'team', id: item.team_id}}
          withTitle={renderTitle(item.name)}
          withLeftAccessory={
            <UIIcon
              style={{marginLeft: '5px', marginRight: '5px'}}
              color="var(--dig-color__text__subtle)"
              src={TeamLine}
              size="large"
            />
          }
        />
      );
    } else if (isEmployee(item)) {
      return (
        <Typeahead.Row
          key={item.user_id}
          value={{type: 'employee', id: item.ldap}}
          withTitle={<Text isBold>{item.name}</Text>}
          withSubtitle={renderSubtitle(item.role)}
          disabled={disable}
          withLeftAccessory={<Avatar user={item} />}
        />
      );
    }
  };
  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <>
          <TextInput
            {...getTriggerProps({
              onChange: onInputChange,
            })}
            placeholder={placeholderText}
            size="large"
            value={userInputValue}
            withRightAccessory={<UIIcon src={SearchLine} style={{marginTop: iconMarginTop}} />}
            autoFocus={autoFocus}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
          />
          <Typeahead.Container
            {...getContentProps()}
            style={{overflow: 'auto', maxHeight: '450px'}}
            isEmptyQuery={searchedEmployeeResult.length === 0 && searchedTeamResults.length === 0}
            emptyPrompt={
              <Typeahead.Prompt>
                <Text color="faint">{t('no_dropboxer')}</Text>
              </Typeahead.Prompt>
            }
          >
            <Typeahead.Results
              size="medium"
              results={combinedResults}
              renderRow={renderTypeaheadRow}
              initialResults={6}
              maxResults={20}
            />
          </Typeahead.Container>
        </>
      )}
    </Typeahead.Wrapper>
  );
};
