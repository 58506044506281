import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CalendarLine, LockOpenLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {GoalCreate, GoalUpdate, ProfilePage} from 'client';
import {Title} from 'components/DSYS/Title';
import {CustomPrivacySubtext} from 'components/goals/privacy/GoalPrivacyMenu';
import {countUniqueEmployee} from 'components/goals/privacy/utils';
import {useAtomValue} from 'jotai';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const formatTimeframe = (timeframe: string) => {
  return timeframe.replace('FY', '20');
};

const mappingFromQuarterToMonthAndDate: {[key: string]: string} = {
  Q1: 'Jan 1 - Mar 31',
  Q2: 'Apr 1 - Jun 30',
  Q3: 'Jul 1 - Sep 30',
  Q4: 'Oct 1 - Dec 31',
};

export const GoalDetailsSection = ({
  onEditClick,
  isEditClicked,
  goalCreateValues,
  delegatesProfilePage,
}: {
  onEditClick: () => void;
  isEditClicked: boolean;
  goalCreateValues?: GoalCreate | GoalUpdate;
  delegatesProfilePage?: ProfilePage;
}) => {
  const {t} = useTranslation();
  const {reportingLine, delegates} = useAtomValue(loggedInEmployeeAtom);
  const delegatesReportingLine = delegatesProfilePage?.reportingLine;
  const delegatesDelegates = delegatesProfilePage?.delegates;

  const teamCount = useMemo(
    () => goalCreateValues?.team_privacies?.length ?? 0,
    [goalCreateValues?.team_privacies?.length]
  );

  const employeeCount = useMemo(
    () =>
      countUniqueEmployee(
        goalCreateValues?.individual_privacies || [],
        delegatesReportingLine ? delegatesReportingLine : reportingLine,
        delegatesDelegates ? delegatesDelegates : delegates || []
      ),
    [
      delegates,
      delegatesDelegates,
      delegatesReportingLine,
      goalCreateValues?.individual_privacies,
      reportingLine,
    ]
  );

  return (
    <Stack paddingBottom="16" gap="8">
      <Cluster alignX="between">
        <Box as={Title} size="small">
          {t('details')}
        </Box>
        <Button onClick={onEditClick} variant="transparent" size="small">
          {t('edit_details')}
        </Button>
      </Cluster>
      <Stack
        gap="8"
        paddingTop="8"
        paddingBottom="8"
        paddingLeft="12"
        paddingRight="12"
        style={{
          borderLeft: isEditClicked
            ? '1px solid transparent'
            : '1px solid var(--dig-color__border__subtle)',
          backgroundColor: isEditClicked
            ? 'var(--dig-color__opacity__surface--state-1)'
            : 'transparent',
          borderRadius: isEditClicked ? '8px' : '0px',
        }}
      >
        <Box as="div" display="flex" alignItems="center" style={{gap: '4px'}}>
          <Box as={UIIcon} src={CalendarLine} color="Text Subtle" />
          <Box as={Text} isBold color="Text Subtle" variant="label">
            {formatTimeframe(goalCreateValues?.timeframe ?? '')}
          </Box>
          <Box as={Text} color="Text Subtle" variant="label">
            {goalCreateValues?.timeframe.split(' ')[0]
              ? mappingFromQuarterToMonthAndDate[goalCreateValues.timeframe.split(' ')[0]]
              : ''}
          </Box>
        </Box>
        <Box as="div" display="flex" alignItems="center" style={{gap: '4px'}}>
          <Box as={UIIcon} src={LockOpenLine} color="Text Subtle" />
          <Box as={Text} isBold color="Text Subtle" variant="label">
            {goalCreateValues?.is_custom_privacy_included
              ? t('custom')
              : goalCreateValues?.private
              ? t('reporting_line')
              : t('all_dropboxers')}
          </Box>
          {goalCreateValues?.is_custom_privacy_included && (
            <CustomPrivacySubtext employeeCount={employeeCount} teamCount={teamCount} />
          )}
          {!goalCreateValues?.is_custom_privacy_included && (
            <Box as={Text} color="Text Subtle" variant="label">
              {goalCreateValues?.private ? t('restricted_subtitle') : t('all_dropboxers_subtitle')}
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
