import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import noThumbnailImage from 'assets/images/default_thumbnail.png';
import {noThumbnailAtom} from 'atoms/news';
import classNames from 'classnames';
import {useAtom} from 'jotai';
import {useRef, useState} from 'react';
import {getBackendUrl} from 'utilities';

import styles from './NewsThumbnail.module.css';

export const NewsThumbnail = ({
  id,
  isNewsFeed = false,
  src,
}: {
  id: number;
  isNewsFeed?: boolean;
  src?: string;
}) => {
  const time = useRef(new Date().getTime());
  const [noThumbnail, setNoThumbnail] = useAtom(noThumbnailAtom);
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const skeletonSize = {height: '64px', width: '98px'};

  const url = getBackendUrl() + `/api/v1/news/thumbnail/${id}?t=${time.current}`;

  return (
    <div
      className={classNames(
        styles.image,
        styles.landscape,
        isNewsFeed ? styles.feed : styles.modify
      )}
    >
      <img
        style={{
          visibility: loaded ? 'visible' : 'hidden',
          opacity: loaded ? '1' : '0',
        }}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={() => {
          setHasError(true);
          setNoThumbnail([...noThumbnail, id]);
          setLoaded(true);
        }}
        src={src ? src : hasError ? noThumbnailImage : url}
        loading="lazy"
      />
      <Skeleton.Box
        className={styles.skeleton}
        style={{
          ...skeletonSize,
          visibility: !loaded ? 'visible' : 'hidden',
          opacity: !loaded ? '1' : '0',
        }}
      />
    </div>
  );
};
