import {Button} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Toggletip} from '@dropbox/dig-components/tooltips';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {BriefcaseLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {StatusButtonIcon, statusOptions} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {reportAndLogError} from 'helpers/logging';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {useCallback, useEffect, useRef, useState} from 'react';

import styles from './../shared/StatusModal.module.css';
import {useCreateProjectUpdate, useProject, useProjectDetails} from './hooks';

export const ProjectStatusModal = ({
  projectId,
  onClose,
}: {
  projectId: number;
  onClose: () => void;
}) => {
  const {data} = useProject({id: projectId});
  const {data: details} = useProjectDetails({id: projectId});
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const latestUpdate = data?.project?.latest_update;
  const statusText = latestUpdate?.status ?? 'no_status';
  const {addUpdate} = useCreateProjectUpdate();
  const [hasContent, setHasContent] = useState(false);

  const [saveState, setSaveState] = useState('idle');
  const [selectedStatus, setSelectedStatus] = useState(statusText);
  const editorState = useRef<EditorState>();

  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const canSave = selectedStatus !== 'no_status' && saveState === 'idle' && hasContent;

  const confirm = useCallback(async () => {
    if (canSave && data) {
      try {
        setSaveState('pending');
        await addUpdate({
          userId: employee.user_id,
          data: {
            project_id: projectId,
            status: selectedStatus,
            comment: editorState.current?.toString() ?? '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        });

        const contributor = details?.contributors.find(
          (c) => c.employee.user_id === employee.user_id
        );

        analyticsLogger().logEvent('PROJECT_STATUS_UPDATE', {
          contributor: contributor?.role,
          status: selectedStatus,
        });
        setSaveState('idle');
        setSnackbarMessage({text: t('status_updated')});
      } catch (error) {
        setSaveState('error');
        reportAndLogError(error, 'Could not update status');
        setSnackbarMessage({text: t('couldnt_update_status')});
      }
    }
    onClose();
  }, [
    addUpdate,
    canSave,
    data,
    details?.contributors,
    employee.user_id,
    onClose,
    projectId,
    selectedStatus,
    setSnackbarMessage,
  ]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'k')) {
        onClose();
      } else if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (canSave) {
          confirm();
          e.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [canSave, confirm, onClose]);

  if (!data) {
    return null;
  }

  return (
    <Modal
      open
      onRequestClose={onClose}
      isCentered
      withCloseButton="Close"
      className={styles.statusModalContainer}
    >
      <Modal.Header
        hasBottomSpacing="title-standard"
        style={{borderBottom: '1px solid var(--dig-color__border__subtle)'}}
      >
        <Modal.Title className={styles.header}>
          <UIIcon src={BriefcaseLine} />
          <Title>{data.project?.title}</Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormRow>
          <Box display="flex">
            <Box marginTop="20" width="100%">
              <FormLabel
                htmlFor="status"
                subtext={<Toggletip title={t('project_status_tooltip')} />}
              >
                {t('status')}
              </FormLabel>
              <Select
                id="status"
                defaultValue={statusText}
                onChange={(val) => setSelectedStatus(val)}
                value={selectedStatus}
              >
                {statusOptions
                  .filter((statusOption) => statusOption.label !== 'draft')
                  .map((statusOption) => (
                    <Select.OptGroup key={statusOption.label} withLabel={t(statusOption.label)}>
                      {statusOption.statusOptions.map((status) => (
                        <Select.Option
                          className={styles.statusOptionOverride}
                          key={status.value}
                          value={status.value}
                          withAccessory={
                            <UIIcon src={status.icon} color={status.color} size="medium" />
                          }
                        >
                          {t(status.value).toString()}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                <Select.Option
                  value={statusText}
                  withAccessory={<StatusButtonIcon status={latestUpdate?.status} />}
                  hidden
                >
                  {t(statusText).toString()}
                </Select.Option>
              </Select>
            </Box>
          </Box>
        </FormRow>
        <FormRow>
          <FormLabel
            htmlFor="statusUpdate"
            subtext={<Toggletip title={t('status_update_tooltip')} />}
          >
            {t('status_update')}
          </FormLabel>

          <RichTextArea
            editable
            placeholder={t('status_update_placeholder').toString()}
            onChange={(editor) => {
              setHasContent(!!editorState.current?.toString().length);
              editorState.current = editor;
            }}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={confirm}
          disabled={!canSave}
          isLoading={saveState === 'pending'}
        >
          {t('update')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
