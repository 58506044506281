import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  EditLine,
  EmailLine,
  LinkLine,
  PersonMultipleLine,
  SlackExternalLogo,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import cx from 'classnames';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import ProfileCard from 'components/shared/ProfileCard';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router-dom';
import {getProfileImageUrl} from 'utilities';

import {Team} from './hooks';
import styles from './TeamBody.module.css';

const EDIT_URL = 'https://wd5.myworkday.com/dropbox/d/task/23748$33.htmld#TABINDEX=0';

function truncateURL(urlString: string) {
  const url = toURL(urlString);
  if (typeof url === 'string') {
    return urlString;
  }
  const pathArray = url.pathname.split('/').filter(Boolean);
  const truncatedPath =
    pathArray.length > 1 ? `/.../${pathArray[pathArray.length - 1]}` : url.pathname;

  return `${url.hostname.replace(/^www./, '')}${truncatedPath}`;
}

const toURL = (url: string) => {
  try {
    return url.startsWith('http') ? new URL(url) : new URL(`http://${url}`);
  } catch (e) {
    return url;
  }
};

const formatSlack = (slacks: string) =>
  slacks.split(' ').map((channel) => (channel.startsWith('#') ? channel : '#' + channel));

const formatEmail = (emails: string) =>
  emails
    .split(' ')
    .map((address) => ({url: `mailto:${address}`, text: <Truncate>{address}</Truncate>}));

const formatURL = (urls: string) =>
  urls.split(' ').map((url) => ({
    url: toURL(url).toString(),
    text: <Truncate location={0.5}>{truncateURL(url)}</Truncate>,
  }));

const getSlackURL = (slackChannel: string) => {
  const channel = slackChannel.replace('#', '');
  if (channel.startsWith('https://')) {
    return channel;
  } else {
    return 'https://dropbox.slack.com/archives/' + channel;
  }
};

function getMemberKey(teamCount?: number) {
  if (!teamCount) {
    return 'member';
  }
  return teamCount === 1 ? 'member_with_team' : 'member_with_teams';
}

export const TeamBody = ({team}: {team: Team}) => {
  const loggedInEmployee = useAtomValue(loggedInEmployeeAtom);

  const isManager = loggedInEmployee.directReports?.length > 0;
  const primaryContact = team.members.find((member) => member.ldap === team.primaryContact?.ldap);

  return (
    <div className={styles.teamBodyContainer}>
      <div>
        <div className={styles.teamNameButtonContainer}>
          <Title>{team.name}</Title>

          <Tooltip
            title={t(
              isManager ? 'edit_on_workday_tooltip_manager' : 'edit_on_workday_tooltip_non_manager'
            )}
          >
            <span>
              <IconButton
                variant="outline"
                size="medium"
                shape="circular"
                disabled={!isManager}
                onClick={() => window.open(EDIT_URL, '_blank')}
              >
                <Box
                  as={UIIcon}
                  src={EditLine}
                  size="medium"
                  color={isManager ? 'Text Subtle' : 'Disabled Base'}
                />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        {team.description ? (
          <Text variant="paragraph" size="large" tagName="p" color="faint">
            {team.description}
          </Text>
        ) : (
          <Text variant="paragraph" size="large" tagName="p" color="faint">
            {t('no_description')}
          </Text>
        )}
      </div>
      {team.slack || team.countMembers ? (
        <div>
          <div className={styles.teamInfosContainer}>
            {team.countMembers && (
              <TeamInfo icon={PersonMultipleLine} title={t('people')}>
                {t(getMemberKey(team.subTeams?.length), {
                  count: team.countMembers,
                  teams: team.subTeams?.length,
                })}
              </TeamInfo>
            )}
            {team.email && (
              <TeamInfo icon={EmailLine} title={t('email')}>
                {formatEmail(team.email).map(({url, text}) => (
                  <Link to={url} key={url}>
                    {text}
                  </Link>
                ))}
              </TeamInfo>
            )}
            {team.slack && (
              <TeamInfo icon={SlackExternalLogo} title="Slack">
                {formatSlack(team.slack).map((channel) => (
                  <Link to={getSlackURL(channel)} key={channel} target="_blank">
                    {channel.startsWith('#https://') ? t('open_channel') : `${channel}`}
                  </Link>
                ))}
              </TeamInfo>
            )}
            {team.url && (
              <TeamInfo icon={LinkLine} title={t('resources')}>
                {formatURL(team.url).map(({url, text}) => (
                  <Link to={url} key={url} target="_blank">
                    {text}
                  </Link>
                ))}
              </TeamInfo>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      <div>
        <Title>{t('members_title')}</Title>
        {team.members?.length > 0 ? (
          <Box marginTop="24">
            <>
              {primaryContact && (
                <Box
                  paddingBottom="8"
                  marginBottom="8"
                  display="flex"
                  justifyContent="space-between"
                  borderBottom="Solid"
                  borderColor="Border Subtle"
                  borderWidth="1"
                  alignItems="center"
                >
                  <ProfileCard
                    profile_username={primaryContact.ldap}
                    source="teams"
                    profile_photo_url={getProfileImageUrl(primaryContact.ldap)}
                    display_name={primaryContact.name}
                    profile_info={primaryContact.role}
                  />
                  <Split alignY="center" gap="10">
                    <Split.Item paddingRight="6">
                      <Chip size="small">{t('primary_contact')}</Chip>
                    </Split.Item>
                    <Split.Item>
                      <Link
                        to={`https://dropbox.slack.com/team/${primaryContact.ldap}`}
                        target="_blank"
                      >
                        <IconButton variant="outline" size="medium" shape="circular">
                          <Box
                            as={UIIcon}
                            color="Text Subtle"
                            src={SlackExternalLogo}
                            size="medium"
                          />
                        </IconButton>
                      </Link>
                    </Split.Item>
                    <Split.Item>
                      <Link to={`mailto:${primaryContact.ldap}@dropbox.com`}>
                        <IconButton variant="outline" size="medium" shape="circular">
                          <Box as={UIIcon} color="Text Subtle" src={EmailLine} size="medium" />
                        </IconButton>
                      </Link>
                    </Split.Item>
                  </Split>
                </Box>
              )}
            </>
            <div className={styles.teamMembersContainer}>
              {team.members
                .filter((member) => member.ldap !== team.primaryContact?.ldap)
                .map((person, index) => (
                  <ProfileCard
                    key={person.ldap + index}
                    source="teams"
                    profile_username={person.ldap}
                    profile_photo_url={getProfileImageUrl(person.ldap)}
                    display_name={person.name}
                    profile_info={person.role}
                  />
                ))}
            </div>
          </Box>
        ) : (
          <Text variant="paragraph" size="large" tagName="p" color="faint">
            {t('no_members')}
          </Text>
        )}
      </div>
      {!team.subTeams?.length ? null : (
        <div>
          <Title>{t('supporting_teams')}</Title>
          <Box marginTop="24" className={styles.teamMembersContainer}>
            {team.subTeams.map((subTeam, index) => (
              <TeamInfo
                key={subTeam.teamId || index}
                icon={TeamLine}
                title={subTeam.name}
                isSupportingTeam={true}
                subTeamSlug={subTeam.slug}
              >
                {t('member', {count: subTeam.totalMembers ?? 0})}
              </TeamInfo>
            ))}
          </Box>
        </div>
      )}
    </div>
  );
};

interface TeamInfoProps {
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  title: string;
  subtext?: string;
  isSupportingTeam?: boolean;
  subTeamDbxTeamId?: number;
  subTeamSlug?: string;
  link?: string;
  children?: React.ReactNode;
}

const TeamInfo = ({
  icon,
  title,
  subtext,
  isSupportingTeam,
  subTeamSlug,
  link,
  children,
}: TeamInfoProps) => {
  const navigate = useNavigate();
  const handleSupportingTeamClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    navigate('/teams/' + subTeamSlug);
    event.stopPropagation();
  };

  return (
    <div
      onClick={(e) => (isSupportingTeam ? handleSupportingTeamClick(e) : undefined)}
      {...(isSupportingTeam
        ? {
            ...withShade({
              className: styles.teamInfoContainer,
              style: {cursor: 'pointer'},
            }),
          }
        : {className: styles.teamInfoContainer})}
    >
      <div
        className={cx(styles.teamInfoIconContainer, {
          [styles.supportingTeamContainer]: isSupportingTeam,
        })}
      >
        <UIIcon
          src={icon}
          className={cx(styles.teamInfoIcon, {
            [styles.supportingTeamInfoIcon]: isSupportingTeam,
          })}
        />
      </div>
      <div className={styles.teamInfoTextContainer}>
        {link ? (
          <Link to={link}>
            <Text isBold>{title}</Text>
          </Link>
        ) : (
          <Text isBold>{title}</Text>
        )}
        <Text size="small">
          <Stack display="flex">{children}</Stack>
        </Text>
        <Text size="small" color="faint">
          {subtext}
        </Text>
      </div>
    </div>
  );
};
